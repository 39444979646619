<template>
  <div id="app" :style="appStyle" class="web-style">
    <div v-if="webSiteLoaded">
      <!-- <navigation :brand="logoInfo" :toggle="false" variant="light" type="light"></navigation> -->
      <hander v-if="!isPurePage"></hander>
      <navbar  v-if="!isPurePage"   @toLink="toLink"></navbar>
      
      <div style="min-height:calc(100vh - 400px) ;">
        <router-view v-if="reloadRoute"></router-view>
      </div>
      
      
      <footerLayout  v-if="!isPurePage"></footerLayout>
      <!-- 网站其他内容 -->
      <!-- <div class="floating-banner-a bg-light rounded-lg shadow d-none d-sm-block" id="hotLineLayout" @mouseover="mouseover" @mouseout="mouseout" :style="{'top':top + 'px','left':left + 'px'}" v-if="showHotLine">
        <b-row cols="1">
            <b-col class="bg-primary text-white p-0 text-center rounded-lg">
                <svg class="bi bi-headphones" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M8 3a5 5 0 0 0-5 5v4.5H2V8a6 6 0 1 1 12 0v4.5h-1V8a5 5 0 0 0-5-5z"/>
                  <path d="M11 10a1 1 0 0 1 1-1h2v4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-3zm-6 0a1 1 0 0 0-1-1H2v4a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-3z"/>
                </svg>
                咨询热线
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16" @click="onBanneraClose">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
           </b-col>
            <b-col class="text-dark p-3 text-center">
              <b-row cols="1">
                  <b-col class="text-dark p-0 text-center">029-81168482</b-col>
                  <b-col class="text-dark p-0 text-center">13379220232</b-col>
              </b-row>
            </b-col>
        </b-row>
      </div> -->
      <div class="floating-banner-b bg-light rounded-lg shadow" style="z-index:99999;">
        <b-row cols="1" @click="onBannerB(item)" v-for="(item,index) in bottomActions" v-show="(item.key == 'toTop' && scrollTopRun) || item.key !== 'toTop'" class="border">
            <b-col class="text-web-primary p-2 pb-1 text-center rounded-lg" >
              <svg v-if="item.icon == 'bi-chevron-bar-up'" xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-chevron-bar-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.646 11.854a.5.5 0 0 0 .708 0L8 8.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708zM2.4 5.2c0 .22.18.4.4.4h10.4a.4.4 0 0 0 0-.8H2.8a.4.4 0 0 0-.4.4z"/>
              </svg>
              <svg  v-if="item.icon == 'bi-telephone-fill'" xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
              </svg>
              <svg  v-if="item.icon == 'bi-pencil-square'" xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
              </svg>
          </b-col>
            <b-col class=" p-2 pt-1 text-center">
              <b-row cols="1">
                  <b-col class="text-web-primary p-0 text-center">{{item.text}}</b-col>
              </b-row>
            </b-col>
        </b-row>
      </div>
    </div>
    <div v-if="!webSiteLoaded" style="height: 100vh;width:100vw;" class="d-flex justify-content-center flex-column m-5 align-items-center">
       <b-spinner variant="primary" style="width: 8rem; height: 8rem;" type="grow" label="加载中"></b-spinner>
       <strong>Loading...</strong>
    </div>
  </div>
</template>

<script>

import store from '@/store'
import logo from '@/assets/logo.png'
import hander from '@/components/hander.vue'

  import navbar from '@/components/navbar.vue'
import { RouterView } from 'vue-router';

import footerLayout from '@/components/footer.vue'
import { getWebSiteConfig,getWebSiteCategoryConfig,getWebSitePcCarousel } from '@/api/api.js'


// import _baseInfo from '@/utils/info.js'
export default {
  name: 'App',
  components: {
    navbar,RouterView,hander,footerLayout
  }, 
  // mixins:[_baseInfo],
  computed:{
    isPurePage(){
       let is = false
       let router = this.$route.path 
       if(['/login','/register','/resetpwd'].includes(router)){
        is = true
       }
       

       return is
    },
    activeRouter(){
      let router = this.$route.path
      return router
    },
    scrollTopRun(){
        let scrollTop = this.scrollY
        // let val = this.$refs.tableRef.bodyWrapper.scrollTop
        // console.log(scrollTop)
        if(scrollTop > 100){
          return true
        }else{
          return false
        }
    },
    appStyle(){
      return {
        width:'100vw',
        margin:"0 auto",
        
        'min-height':"100vh"
      }
    },
    
    logoInfo(){
       let config = sessionStorage.getItem('basisInfo') ? JSON.parse(sessionStorage.getItem('basisInfo')) : null
       let logo = {
         img:'',
         text:config && config.ws_name ? `${config.ws_name}` : ''
       }
       if(config){
        logo.img = `https://file.100xsys.cn/file/download?fileNo=${config.logo}`
       }
       
       return logo
    },
    rootScrollTop(){
       let top = this.rootScrollY
       return top
    }
  },
  
  mounted(){
    this.init()
  },
  beforeDestroy () {
    // dom 销毁前清除定时器
    clearInterval(this.timer)
  },
  created(){
    console.log('app',sessionStorage.getItem('isLogin'))
    if(sessionStorage.getItem('isLogin') !== 'true'){
        sessionStorage.setItem('bx_auth_ticket','')
        sessionStorage.setItem('isLogin',false)
    }
    this.setWebConfig()
    this.getWebSiteConfigs()
    window.addEventListener('scroll',this.handleScroll,true)
    this.initLoginState()
  },
  data(){
        return {
          reloadRoute:true,
          showHotLine:true,
          bottomActions:[{
            text:"回到顶部",
            key:'toTop',
            icon:"bi-chevron-bar-up",
          },
          // {
          //   text:"咨询热线",
          //   key:'hotLine',
          //   icon:"bi-telephone-fill",
          // },
          // {
          //   text:"企业留言",
          //   key:'sendMsg',
          //   icon:"bi-pencil-square",
          // }
        ],
          
          show: true, // 是否展现飘窗
      stepX: 1, // 水平方向的步长
      stepY: 1, // 垂直方向的步长
      timer: null, // 定时器
      maxTop: 0, // 最大的 top 值
      maxLeft: 0, // 最大的 left 值
      top: 0,
      left: 0,
          scrollY:0,
            webSiteLoaded:false,
            isload:[],
            routerVal:null,
            navs:[{
                    text:"首页",
                    href:'/'
                },{
                    text:"关于学会",
                    href:'/homepage'
                },{
                    text:"学会资讯",
                    href:''
                },{
                    text:"继续教育",
                    href:''
                },{
                    text:"健康中国行动",
                    href:''
                },{
                    text:"全民营养周",
                    href:''
                },{
                    text:"科普宣传",
                    href:''
                },{
                    text:"教育培训",
                    href:''
                },{
                    text:"会员注册",
                    href:''
                },{
                    text:"理事单位",
                    href:''
                },{
                    text:"资料下载",
                    href:''
                }]
        }
    },
    methods:{
      setWebConfig(){
         let config = this.webConfig
         console.log('APP web SIDE LOADED',config)
         if(config.port){
          this.$store.commit('setHostPath',`${config.protocol}://${config.host}:${config.port}`)
         }else{
          this.$store.commit('setHostPath',`${config.protocol}://${config.host}`)
         }
           
         if(config.hasOwnProperty('title') && config.title){
            window.document.title = config.title
         }
      },
      onBanneraClose(){
         this.showHotLine = !this.showHotLine
      },
      onBannerB(item){
        let self = this
        if(item.key == 'toTop'){
          this.scrollToTop()
        }else{

         
          if(item.key == 'hotLine'){
            // console.log(item)
            this.showHotLine = !this.showHotLine
            
            if(this.showHotLine){
              let width = window.innerWidth
              let height  = window.innerHeight
              self.top = height - 460
              self.left = width - 320
              console.log(width,height)
              // this.init()
            }
          }else if(item.key == 'sendMsg'){
            console.log(item)
            this.$router.push(`/form?serviceName=srvgive_leave_word_add&type=add&app=fyzhmd`,(sce => {
              console.log('跳转：',sce)
              }),(err => {
              console.log('跳转异常：',err)
              }))
          }
        }
      },
      scrollToTop(){
        window.scrollTo(0,0);
      },
      handleScroll(e){
        //  console.log('handleScroll',e)
        // this.rootScrollY =  window.scrollY
        this.scrollY =  window.scrollY
      },
      toLink(e){
          this.routerVal = e
      },
      getWebSiteConfigs(){
        this.isload = [] 
        let config = this.webConfig
        getWebSiteConfig().then(res =>{
          //  console.log('网站基本设置信息',res)
          if(res.state == "SUCCESS"){
              let data = res.data[0]
              
              if(data.logo){
                    let logos = []
                    this.getFileUrls(data.logo).then(res=>{
                        // console.log('get logos ',res)
                        if(Array.isArray(res)){
                            logos = res.map(item => {
                                let obj ={
                                    url:`${store.getters.hostPath}/file/download?filePath=${item.fileurl}`,
                                    '_data':item
                                }
                                return obj

                            })
                            data['_logos'] = logos.map(item => item)
                        }
                        
                    })
                    // console.log('logo',logos)
              }
              this.$store.commit('setWebConfig',data)

              let option = store.getters.getWebConfig.cfg_option ? store.getters.getWebConfig.cfg_option.split(',') : []
              let isSearch = option.indexOf('支持搜索') !== -1
              let isLogin = option.indexOf('包含注册') !== -1
              this.$store.commit('setOnLogin',isLogin)
              this.$store.commit('setOnSearch',isSearch)
              this.isload.push('getWebSiteConfig')
              // console.log('网站信息',store.getters.getWebConfig)
            this.webSiteLoaded = true
          }else{
            console.error('网站信息加载失败')
            this.webSiteLoaded = false
          }
        })
        getWebSiteCategoryConfig().then(res =>{
          //  console.log('栏目信息',res)
          if(res.state == "SUCCESS"){
              let data = res.data
              this.isload.push('getWebSiteCategoryConfig')
              data = data.filter(item => item.website_no == config.webNo)
              this.buildNavs(data)
          }else{
            console.error('网站栏目信息加载失败')
          }
        })
        getWebSitePcCarousel().then(res =>{
          //  console.log('网站信息',res)
          if(res.state == "SUCCESS"){
              let data = res.data
              // sessionStorage.setItem('webSitePcCarousel',JSON.stringify(data))
              
              this.$store.commit('setCarousel',data)
              this.isload.push('getWebSitePcCarousel')
          }else{
            console.error('网站信息加载失败')
            this.webSiteLoaded = false
          }
        })
      },
      
     buildNavs(list){
        
        let navs = []
        if(list && list.length > 0){
            let index = null
            list = list.map(item => {
              let obj = {
                text: item.name,
                key:item.no,
                href: item.temp_page,
                parent_no: item.parent_no, 
                en:item.en_name,
                path:item.path,
                is_show_cmt:item.is_show_cmt == '是',
                level: item.path.split('/').filter(item => item).length,
                banner:item.col_image ? `${store.getters.hostPath}/file/download?fileNo=${item.col_image}` : null,
                
                icon:item.icon ? `${store.getters.hostPath}/file/download?fileNo=${item.icon}` : null
              }
              
              if(obj.href == 'index'){
                
                obj.href = '/'
                index = obj
              }
              obj['temp'] = obj.href
              if(obj.href && obj.href.indexOf('?') !== -1){
                obj['params'] = this.urlParams(obj.href)
                obj['temp'] = obj.href.split('?')[0]
              }
              obj["_data"] = item
              return obj
            })
            
            this.$store.commit('setNavs',list)
            if(index){
              if(this.$route.params && this.$route.params.no){
              
                  let navs = store.getters.getNavs
                  let activeNav = navs.filter(item => item.key == this.$route.params.no)
                  if(activeNav.length > 0){
                      store.commit('setActiveNav',activeNav[0]) 
                  }
                
              }else{
                store.commit('setActiveNav',index)  
              }
               
            }
            // this.navs = list.map(item => item)
            // this.navs[0]['avtiveChildText'] = this.navs[0].name
            // sessionStorage.setItem('avtiveNav',JSON.stringify(this.navs[0]))
        }
        
      },
      init (left,top) {
        // 设置最大的top和left值：根元素可视区域宽高 - 飘窗的宽高 - 边距
        this.maxTop = document.documentElement.clientHeight - 150 - 20
        this.maxLeft = document.documentElement.clientWidth - 200 - 20

        // 设置 top 和 left 的初始值
        this.top = 0
        this.left = 0

        // 创建定时器前清除定时器，避免类似在 onresize 中调用 init() 时，产生多个定时器
        clearInterval(this.timer)
        this.timer = setInterval(() => {
          this.move()
        }, 20)

        this.onresize()
      },
      // 移动函数
      move () {
        if (this.top >= this.maxTop || this.top < 0) {
          this.stepY = -this.stepY
        }
        if (this.left >= this.maxLeft || this.left < 0) {
          this.stepX = -this.stepX
        }

        this.top += this.stepY
        this.left += this.stepX
      },
      // 鼠标悬浮在飘窗时停止移动
      mouseover () {
        clearInterval(this.timer)
      },
      // 鼠标离开飘窗时恢复移动
      mouseout () {
        clearInterval(this.timer)
        this.timer = setInterval(() => {
          this.move()
        }, 20)
      },
      // 关闭飘窗
      close () {
        clearInterval(this.timer)
        this.show = false
      },
      // 窗口大小调整时重置飘窗规则
      onresize () {
        const that = this
        window.onresize = function () {
          that.init()
        }
      }
    },
    watch:{
        "isload":{
            deep:true,
            handler:function(nval,oval){
                // console.log(nval,oval)
                if(nval.length == 3){
                    this.webSiteLoaded = true
                }
            }
        },
    　　'$route': function (to, from) {
            // 我这里还是用了Vuex，不过应该不影响理解
            // console.log(to.path)
            if(to.name == from.name){
              this.reloadRoute = false
              setTimeout(()=>{
                this.reloadRoute = true
              },10)
            }
    　　}
    }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.floating-banner-a{
  overflow: hidden;
  display: block;
    position: fixed;
    width: 12rem;
    /* height: 12rem; */
    top:0;
    left:0;
    z-index:9999;
    /* bottom: 7rem; */
    /* background: blue; */
    border-radius: 2rem;
    line-height: 3rem;
    font-size: 1.5rem;
    font-weight: bold;

}
.floating-banner-b{
  overflow: hidden;
  display: block;
    position: fixed;
    width: 8rem;
    /* height: 12rem; */
    right: 0;
    bottom: 5rem;
    /* background: blue; */
    border-radius: 2rem;
    line-height: 1;
    font-size:1rem;
    font-weight: bold;

}
</style>
