import Vue from 'vue'
import Vuex from 'vuex' // 引入vuex
Vue.use(Vuex) //vue注册vuex
 
export default new Vuex.Store({ // 在vuex上挂载store实例配置项
  state: {
    token:sessionStorage.getItem('bx_auth_ticket'),
    isLogin:sessionStorage.getItem('isLogin') == 'true' ? true : false,
    loginInfo:'',
    // token:'',
    webConfig:null,
    navs:[],
    activeNav:null,
    carousels:[],
    activeCarousels:[],
    onLogin:false,  // 开启用户登录/注册
    onSearch:false,  // 开启搜索功能
    hostPath:'',  // 服务完整地址
    qrcodes:[],
    memberInfo:null  // 会员基本信息
  },
  mutations: {
    setToken(state, value) {
      state.token = value;
    },
    setWebConfig(state,value){
      // console.log(value)
      state['webConfig'] = value;
    },
    setNavs(state,value){
      state['navs'] = value;
    },
    setActiveNav(state,value){
      state['activeNav'] = value;
    },
    setCarousel(state,value){
      state['carousels'] = value;
    },
    setActiveCarousel(state,value){
      state['activeCarousels'] = value;
    },
    setOnLogin(state,value){
      state['onLogin'] = value;
    },
    setOnSearch(state,value){
      state['onSearch'] = value;
    },
    setHostPath(state,value){
      state['hostPath'] = value;
    },
    setQrcodes(state,value){
      state['qrcodes'] = value;
    },
    setLoginInfo(state,value){
      console.log(value)
      if(value == false){
        state['isLogin'] = false;
        state['loginValidTo'] = '';
        state['token'] = '';
        state['loginInfo'] = ''
        state['memberInfo'] = ''
        
      }else{
        state['isLogin'] = true;
        state['loginValidTo'] = (new Date().getTime() + Number(value.expire_time));
        state['token'] = value['bx_auth_ticket'];
        state['loginInfo'] = JSON.stringify(value)
      }
      
    },
    setMemberInfo(state,value){
      if(value == false){
        state['memberInfo'] = null;
      }else{
        state['memberInfo'] = value;
      }
      
    },
  },
  actions: {
    getActiveNavAction({commit,state}, value){
       let carousels = []
       if(value){
        carousels = state.carousels.filter(item => item.no == value || !item.no)
      }else{
        carousels = state.carousels.map(item => item)
      }
      console.log('----->:',carousels)
      commit('setActiveCarousel',carousels)
      return carousels
       
    },
    closelogin(context, value){
      // 清楚登录信息
        sessionStorage.setItem('isLogin',false)
        sessionStorage.setItem('bx_auth_ticket','')
        sessionStorage.setItem('loginInfo','')
        
        context.commit('setLoginInfo',false)
    }
  },
  modules: {
  },
  getters:{
    token(state){
      return state.token
    },
    getNavs(state){
      return state.navs
    },
    getWebConfig(state){
      return state.webConfig
    },
    getActiveNav(state){
      let nav = state.activeNav
      if(!nav && Array.isArray(state.navs) && state.navs.length > 0){
        nav = state.navs[0]
      }
      return nav
    },
    
    getCarousel(state,value){
      let carousels = state.carousels
      return carousels
    },
    getActiveCarousel(state,value){
      return state.activeCarousels
    },
    getOnLogin(state,value){
      return state.onLogin
    },
    getOnSearch(state,value){
      return state.onSearch
    },
    hostPath(state,value){
      return state.hostPath;
    },
    getQrcodes(state,value){
      return state.qrcodes;
    },
    getLoginInfo(state,value){
      let res = {}
      if(state.loginInfo){
        res = JSON.parse(state.loginInfo)
      }
      return res['login_user_info'];
    },
    getToken(state,value){
      let obj = state.loginInfo ? JSON.parse(state.loginInfo) : {}
      return obj['bx_auth_ticket'];
    },
    getLoginState(state,value){
      return state.isLogin;
    },
    getLoginValidTo(state,value){
      return state.loginValidTo;
    },
    getMemberInfo(state,value){
      return state.memberInfo;
      
    },
  }
})