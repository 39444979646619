<template>
    <div class="d-flex flex-column bg-gray  w-100  justify-content-center h5 text-secondary" style="min-height:100px">
         {{title}}
    </div>
    
</template>
  
  <script>
  
import store from '@/store'

  import { getXianZhuang,getJianBao } from '@/api/api.js'
  export default {
    name: 'none',
    props: {
        
        title:{
            type:String,
            default(){
                return ''
            }
        }
    },
    computed:{
    },
    mounted(){
    },
    data() {
      return {
        text:''
      }
    },
    methods: {
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.card-img{
  min-height: 8rem;
}
  </style>
  