<template>
    <div>
        
        <b-nav small pills class="mb-1 web-layout-tabs d-flex">
            <b-nav-item active v-for="(item,index) in items" :key="index" :active="active[0] == index" @click="onItem(item)">
                {{item.text}}
            </b-nav-item>
        </b-nav>
        <childTabs class="" v-if="activeItem && Array.isArray(activeItem.children) && activeItem.children.length > 0" :initActive="active.length == 2 ? active[1] : 0" :paths="activeItem.children" @change="onchildren"></childTabs>
    </div>
    
</template>
  
  <script>
  
import store from '@/store'

  import { getXianZhuang,getJianBao } from '@/api/api.js'
  export default {
    name: 'tabs',
    components:{
        "childTabs":() => import('@/components/tabs.vue')
    },
    props: {
        paths:{
            type:Array,
            default(){
                return [
                    {
                        text: '首页',
                        href: '#',
                        router:'index'
                    },
                    {
                        text: '栏目',
                        href: '#',
                        router:'homepage'
                    },
                    {
                        text: '子栏目1',
                        active: true
                    }
                ] // url
            }
        },
        width:{
            type:[String,Number],
            default(){
                return 200
            }
        },
        initActive:{
            type:[String,Number,Array],
            default(){
                return null
            }
        },
        lineHeight:{
            type:[String,Number],
            default(){
                return 50
            }
        },
        title:{
            type:String,
            default(){
                return ''
            }
        }
    },
    computed:{
        items(){
            let items = []
            let paths = this.paths
            if(Array.isArray(paths)){
                items = paths.map(item => item)
            }
            return items
        },
        style(){
            let style = {'width':'220px'}
            let width = this.width
            if(width){
                style['width'] = `${width}px`
            }
            return style
        },
        activeItem(){
            let active = this.active
            let item = null
            item = this.items.filter((item,index) => index == active[0]) 
            if(Array.isArray(item)&&item.length == 1){
                item = item[0]
            }
            return item
        },
    },
    mounted(){
        if(this.initActive){
            let item = null
            let initActive = this.initActive
            this.active = this.active.concat(initActive)
        }
    },
    data() {
      return {
        active:[]
      }
    },
    methods: {
        onItem(e){
            // let level = e.path ? e.path.split('/').filter(item => item) : 0
            // level = level.length 
            // if(level.length == 2){

            // }else if(level.length == 1){

            // }
                
            this.$emit('change',e)
            // let nval = this.activeItem
            // if(Array.isArray(nval)){
            //         this.$emit('change',nval[0])
            //     }else{
            //         this.$emit('change',nval)
            //     }
            // this.$set(this.active,level-1,e)
            // console.log(e)
            
        },
        onchildren(e){
            this.$emit('change',e)
            
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.card-img{
  min-height: 8rem;
}
  </style>
  