<template>
    <div class="list-layout"  :style="`background-image: url(${initList.bgimg});background-repeat: no-repeat;background-size: 100%;`">
        
        <div class="p-5 m-0">
            <div class="list-layout-content-banner d-flex  justify-content-between" >
                <div class="text-left pr-4 w-50">
                    <div class="h4 font-weight-bold mb-3">{{initList.title}}</div>
                    <div class="h6 mt-3">{{initList.subTitle}}</div>
                </div>
                <div class="pl-5 " style="width:30%;">
                    <b-img left :src="initList.thimg" width="auto" height="100" fluid alt="" ></b-img>
                </div>
            </div>
            <div class="list-layout-content-list">

                <b-row class="p-0 m-0"  v-if="initList.list && initList.list.length > 0">
                    <b-col md="2" v-for="(item,index) in initList.list" :key="index" style="min-height:8rem;" class=" hover-card-layout p-2 border-top border-right border-bottom" :class="index == 0 ? 'border-left' : ''">
                        <div class="hover-card-title text-center  pt-3 pb-3">
                            <b-img left :src="initList.icon" style="position:absolute;left:20px;top:20px;" width="20" height="20" fluid alt="" ></b-img>

                            <b-img left :src="item.icon" width="60" height="60" fluid alt="" ></b-img>
                            <div class="hover-card-title-text h6 mt-2 font-weight-bold">{{item.title}}</div>
                        </div>
                        
                        
                        <div class="hover-card-msg text-left font-weight-bold pt-1 pb-1 ">
                            <b-img left :src="initList.icon" class="float-left" width="20" height="20" fluid alt="" ></b-img>
                            
                            {{item.msg}}
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
        
    
  </template>
  
  <script>
  
import store from '@/store'

import list from '@/components/list.vue'
import carousel from '@/components/carousel.vue'
  import banner from '@/components/banner.vue'
  import vipcard from '@/components/vipcard.vue'
import { getContent } from '@/api/api.js'
import _baseInfo from '@/utils/info.js'
  export default {
    name: 'hover-layout',
    mixins:[_baseInfo],
    components: {
    },
    props: {
        initList:{
            type:[Array,Object],
            default(){
                return []
            }
        }
        
        
        
    },
    computed:{
        

    },
    mounted(){
        
    },
    data() {
      return {
          bannerList:[]
      }
    },
      methods: {
        onPathItem(e){
            console.log(e)
        },
        getBanner(){
            if(this.layoutNo){
                // store.dispatch('getActiveNavAction',this.activeLayout.key)
                let url = store.getters.getCarousel
                url = url.filter(item => item.no == this.layoutNo)
                if(Array.isArray(url) && url.length > 0){
                    let img = url[0]
                    url=`${store.getters.hostPath}/file/download?fileNo=${img.carousel_img}`
                }

                this.bannerList.push({url:url})
            }
        },
        onFooter(e){
            if(e && e.href){
                this.$router.push({path:`/${e.href}`})
            }
        },
        toPage(){
            let navs = store.getters.getNavs
            navs = this.getTree(navs,'key','parent_no',this.layoutNo)
            if(navs.length == 1){
                navs = navs[0]
            }
            store.commit('setActiveNav',navs) 
            let active = navs.children.length > 0 ? navs.children[0] : navs
            
            this.toHomePage(active,navs.key)
            // let path = `/homepage/${this.layoutNo}?timer=${new Date().getTime()}`
            // path = `/${navs.href}/${navs.key}/${active.key}/list/${active.key}`
            // this.$router.push(path,(sce => {
            //         console.log('跳转异常：',sce)
            //     }),(err => {
            //         console.log('跳转异常：',err)
            //     }))
        },
        getListData(){
            if(this.layoutNo){
                let cols = this.cols.concat([this.banner.img])
                let req = {
                    "serviceName": "srvdaq_pc_website_content_select",
                    "colNames": cols,
                    "condition": [{
                        colName:'category_no',
                        ruleType:'like]',
                        value:this.layoutNo
                    }],
                    "relation_condition": {},
                    "page": {
                        "pageNo": 1,
                        "rownumber":1
                    },
                    "order": [],
                    "draft": false,
                    "query_source": "list_page"
                }
                getContent(req).then((res) =>{
                     if(Array.isArray(res.data) && res.data.length > 0){
                        // console.log(res.data)
                        // this.bannerList = res.data.map(item => item)
                        this.getFileUrls(res.data[0][this.banner.img]).then((urlres) =>{
                            let config = this.webConfig
                            for(let img of urlres){
                                let obj = {
                                    url:`${config.protocol}://${config.host}:${config.port}/file/download?filePath=${img.fileurl}`,
                                    '_data':res.data[0]
                                }
                                this.bannerList.push(obj)
                            }
                            
                        })
                        // console.log(urls)
                     }
                })
                
            }else if(this.srvApp && this.serviceName){
                console.log('非栏目内容，使用定制service查询')
            }
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
.card-img{
  min-height: 8rem;
}
.hover-card-layout{
    background:rgb(255 255 255 / 41%);
    .hover-card-msg{
        display:none;
    }
    .hover-card-title{
            display:flex;
            flex-direction: column;
            align-items: center;
        }
}
.hover-card-layout:hover{
    background:#fff;
    .hover-card-msg{
            display:block;
            
        } 
        .hover-card-title{
            display:none;
        } 
}

.hover-none{

}
  </style>
  