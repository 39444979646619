import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.config.productionTip = false
import { BootstrapVue, BootstrapVueIcons,ModalPlugin} from 'bootstrap-vue'
import scroll from 'vue-seamless-scroll'  // 滚动组件
Vue.use(scroll)
 // 引入echarts
 import *as echarts from 'echarts'
Vue.prototype.$echarts = echarts
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './style/custom.scss'
import VueUtil from '@/utils/utils'
import config from '../web-config'
Vue.prototype.webConfig = config  // 站点配置
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
VueUtil();
NProgress.configure({     
  easing: 'ease',  // 动画方式    
  speed: 500,  // 递增进度条的速度    
  showSpinner: false, // 是否显示加载ico    
  trickleSpeed: 200, // 自动递增间隔    
  minimum: 0.3 // 初始化时的最小百分比
})
//当路由进入前
router.beforeEach((to, from , next) => {
  // console.log('beforeEach router:',to.name,from.name,to.path)
  if (to.name === from.name) {
    // router.replace(to.path)
    // router.replace(to.path)
      // Vue.$router.replace(to.path)
    // to.path = `/${to.name}`
  }
  // 每次切换页面时，调用进度条
 NProgress.start();
 // 这个一定要加，没有next()页面不会跳转的
  next();
});
//当路由进入后：关闭进度条
router.afterEach(() => {  
 // 在即将进入新的页面组件前，关闭掉进度条
 NProgress.done()
  window.scrollTo(0,0);
})
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
