<template>
    <div class="web-layout bg-light">
        <!-- <carousel :activeNavbar="activeNav" :height="'auto'" :action="{
                  text:'立即注册/登录',
                  type:'login',
                  params:null}"></carousel> -->
        <!-- <banner :layout="banner"></banner> -->
        <b-container fluid="sm" class="web-layout-content bg-white p-1 h-100 rounded rounded-lg " :style="`top: -${0}px;min-height:360px;z-index:19;`">
            <!-- <scrollLayout :height="40" class="mb-4 pl-3 pr-3" :title="'公告'"></scrollLayout> -->
            <!-- < -->
            <!-- <navpath></navpath> -->
            <b-container fluid >
                <b-container fluid class=" p-0">
                    <!-- <banner :layout="'text'" :text="{cn:'公司介绍',en:'Enterprise Dynamics',subTitle:'我们是一家致力于人力资源深耕，为企业提供一站式咨询服务的管理咨询机构，为中小企业提供专业的人力资源风险防控，业务流程再造、项目评估、企业运营管理等咨询服务。'}" :height="172"></banner> -->
                    
                    <b-row class="mb-6 mt-3">
                        <b-col md="6" class="mb-2">
                            <carousel v-if="activeNav"
                            @on-button="onCarousel"
                             class="rounded" :imageStyle="``" :showTitle="true" :activeNavbar="activeNav" :height="'330'" :action="{
                            text:'立即注册/登录',
                            type:'login',
                            params:null}">
                            </carousel>
                        </b-col>
                        <b-col md="6"  class="mb-2">
                             <listLayout 
                             class="rounded"
                            :layoutNo="`LM20231023140003`" 
                            :showTitle="true"
                            :rowspan="6" 
                            :initList="initList"
                            :ruleType="{colName:'category_no_path',ruleType:'like'}"
                            :listLayout="'textNoWarpAndPoint'"
                            :more="false"
                            :colsMaps="{title:'title',date:'create_time'}"
                            ></listLayout>
                        </b-col>
                    </b-row>

                    <b-row class="mb-6 pb-3 pt-3">
                        <b-col md="6" class="mb-2 pr-2">
                            <banner :layout="'img'" class="rounded" :url="bannerImg.left" :height="80"></banner>
                        </b-col>
                        <b-col md="6"  class="mb-2  pl-2">
                            <banner :layout="'img'" class="rounded" :url="bannerImg.right" :height="80"></banner>
                        </b-col>
                    </b-row>
                    <b-row class="mb-12 pb-3 pt-3">
                        <b-col>
                             <tabListLayout 
                             class="rounded"
                            :layoutNo="`LM20231023140002`" 
                            :showTitle="true"
                            :rowspan="6" 
                            :initList="initList"
                            :listLayout="'textCard'"
                            :ruleType="{colName:'category_no_path',ruleType:'like'}"
                            :tabsMode="'all'"
                            :more="false"
                            :layoutColumn="6"
                            :colsMaps="{title:'title',subTitle:'summary',date:'create_time'}"
                            ></tabListLayout>
                        </b-col>
                    </b-row>

                    <b-row class="mb-12 pt-3">
                        <b-col>
                             <listLayout 
                             class="rounded"
                            :layoutNo="`LM20231023140004`" 
                            :showTitle="true"
                            :listLayout="'imgCard'"
                            :ruleType="{colName:'category_no_path',ruleType:'like'}"
                            :rowspan="6" 
                            :initList="initList"
                            :layoutColumn="4"
                            :more="false"
                            :colsMaps="{title:'title',img:'thn_img',tags:'tags'}"
                            ></listLayout>
                        </b-col>
                    </b-row>


                   

                    
                    <!-- <b-row class="mb-4">
                        <b-col md="12" class="mb-2">
                            <banner :layout="'text'" :text="{cn:'服务流程',en:'Enterprise Dynamics',subTitle:''}" :height="122"></banner>
                        </b-col>
                        <b-col md="12" class="p-0 m-0">
                            <hoverLayout 
                            :initList="hoverLayoutList"
                            
                            ></hoverLayout>
                        </b-col>
                    </b-row> -->
                    
                   
                    
                </b-container>
                
            </b-container>
        </b-container>
        <!-- <b-container fluid class=" bg-lightprimary">
            <b-container>
                <div class="mb-0 p-2 d-flex justify-content-md-around flex-column flex-md-row">
                    <div class="p-1" v-for="(link,index) in links">
                        <b-link :href="link.link_url" target="_block" class="text-white w-md-25" >{{link.link_name}}</b-link>
                    </div>
                </div>
            </b-container>
        </b-container> -->
        
    </div>
    
</template>
  
  <script>
  
import store from '@/store'
  import banner from '@/components/banner.vue'
  import navpath from '@/components/navpath.vue'
  import actionList from '@/components/actionList.vue'
  import tabs from '@/components/tabs.vue'
  import list from '@/components/list.vue'
  import detail from '@/components/detail.vue'
  import carousel from '@/components/carousel.vue'
  import scrollLayout from '@/components/scrollLayout.vue'
  import listLayout from '@/components/listLayout.vue'
  import tabListLayout from '@/components/tabListLayout.vue'
  import hoverLayout from '@/components/hoverLayout.vue'
  import sendMsgForm from '@/components/sendMsgForm.vue'
  
import { getWebSitePcCarousel,friendLinks,getListData } from '@/api/api.js'


import login from '@/assets/index/login.png'
// import b1 from '@/assets/index/4.jpg'
// import b2 from '@/assets/index/5.jpg'
// import b3 from '@/assets/index/3.png'
import b1 from '@/assets/index/01.png'
import b2 from '@/assets/index/02.png'
import b3 from '@/assets/index/03.png'
// 服务流程

import f1 from '@/assets/fwlc/1.png'
import f2 from '@/assets/fwlc/2.png'
import f3 from '@/assets/fwlc/3.png'
import f4 from '@/assets/fwlc/4.png'
import f5 from '@/assets/fwlc/5.png'
import f6 from '@/assets/fwlc/6.png'
import fr from '@/assets/fwlc/r.png'
import fbg from '@/assets/fwlc/bg.jpg'
import fi from '@/assets/fwlc/icon.png'

// banner 
import bannerleft from '@/assets/index/left.png'
import bannerright from '@/assets/index/right.png'


  export default {
    name: 'index',
    components: {
        banner ,navpath,actionList,list,tabs,detail,carousel,scrollLayout,listLayout,hoverLayout,sendMsgForm,tabListLayout
    },
    props: {
      msg: String
    },
    computed:{
        activeNav(){
            let key = store.getters.getActiveNav 
            key = key ? key.key : ''
            return key
        }
    },
    data(){
        return {
            bannerImg:{
                left:bannerleft,
                right:bannerright
            },
            hoverLayoutList:{
                title:"瓦特:企业一站式咨询",
                bgimg:fbg,
                thimg:fr,
                icon:fi,
                subTitle:"秉承为企业提供行业一站式咨询服务”战略宗旨，参与及定制业务需求，凭借先行优势和经验积累，目前获得的了企业客户的一致认可。",
                list:[{
                    icon:f1,
                    title:"需求确认",
                    msg:"与客户沟通，了解具体需求和期望，包括所在行业、面临的问题、目标等。"
                },{
                    icon:f2,
                    title:"信息收集",
                    msg:"通过各种渠道收集相关数据和信息，并进行分析和评估。"
                },{
                    icon:f3,
                    title:"解决方案制定",
                    msg:"根据客户需求和信息收集结果，制定合适的解决方案，提供专业建议并给出实施计划。"
                },{
                    icon:f4,
                    title:"实施方案",
                    msg:"协助客户实施解决方案，并跟踪进展情况。如果需要，还会向客户提供培训和指导服务。"
                },{
                    icon:f5,
                    title:"评估效果",
                    msg:"对实施效果进行评估，分析成功因素及改进空间，并与客户总结经验，为企业前景提供更好的指导。"
                },{
                    icon:f6,
                    title:"后续服务",
                    msg:"为客户提供持续性的支持和服务，如更新政策变化、调整解决方案等。"
                }]
            },
            initList:[{
                img:'',
                title:''

            }],
            banner:{
                height:"300",
            },
            active:'',
            footerImg:login,
            banners:[
                {url:b1,layoutNo:'LM20230831100002'},
                {url:b2,layoutNo:'LM20230713180008'},
                {url:b3,layoutNo:'LM20230713180006'}],
            links:[]
        }
    },
    created(){
    },
    mounted(){
        if(store){
            let navs = store.getters.getNavs
            navs = navs.filter(item => item.href == '/' || item.href == '#' || item.href == '/index')
            if(navs.length == 1){
                store.commit('setActiveNav',navs[0]) 
            }
        }
         
        this.getLinks()
    },
    methods:{
        onClick(e){
            this.active = e
        },
        onCarousel(e){
            // 获取点击反馈的轮播图中设置的跳转文章编号
            console.log(e)
            if(e._data && e._data.link_content){
                this.getDetail(e._data.link_content)
            }
        },
        getDetail(no){
            // 查询文章详情
            let serviceName = 'srvdaq_pc_website_content_select'
            let req = {
                "serviceName": serviceName,
                "colNames": [
                    "*"
                ],
                "condition": [
                    {
                        "colName": "content_no",
                        "ruleType": "eq",
                        "value": no
                    }
                ],
                "page": {
                    "pageNo": 1,
                    "rownumber": 999
                },
                "order": []
            }
            getListData (req,null,serviceName).then(res=>{
                // console.log(res)
                // 查询数据
                if(res.state == "SUCCESS"){
                    let content = res.data[0]
                    console.log(content)
                    // this.$set(this,'loadData',content)
                    if(content && content.category_no_path){
                        // 存在文章数据和栏目path时 处理跳转逻辑
                        let noPath = content.category_no_path
                        let root = noPath.split('/').filter(item => item)[0]
                        let active = content.category_no

                        let rootNavbar = store.getters.getNavs
                        rootNavbar = rootNavbar.filter(item => item.key == root)[0]
                        let path = rootNavbar.href 
                        let layoutType = 'none'
                        
                        
                        
                        
                        if(rootNavbar && rootNavbar.level !== 1){
                            root = rootNavbar.path.split('/').filter(item => item)[0]
                            if(root){
                                let loadRootNavBar = this.getNavBarData(root)
                                path = loadRootNavBar.href
                                store.commit('setActiveNav',loadRootNavBar) 
                            }
                            
                        }else{
                            store.commit('setActiveNav',rootNavbar) 
                        }
                    
                        if(path == '/'){
                            let to =  this.getNavBarData(this.no)
                            path = to.href
                            root = to.key 
                            store.commit('setActiveNav',to) 
                        }
                        if(path && path.indexOf('?') !== -1){
                            // 处理模板参数
                            let pathParams = path.split('?')[1]
                            path = path.split('?')[0]
                            layoutType = pathParams.split('=')[1]
                        }
                        if(path && path.indexOf(':') !== -1){
                            path = path.split(':')[0]
                        }
                        if(!path){
                            let onItem = e
                            path = onItem.category_no_path
                            if(path){
                                path = path.split('/')
                                path = path.filter(item => item)
                                if(Array.isArray(path) && path.length > 1){
                                    root = path[0]
                                    path = 'homePage'

                                    let navs = store.getters.getNavs
                                    navs = this.getTree(navs,'key','parent_no',root)
                                    if(navs.length == 1){
                                        navs = navs[0]
                                        store.commit('setActiveNav',navs)   
                                    }
                                }
                            }
                        }
                        this.$router.push(`/${path}/${root}/${content.category_no}/detail/${content.id}/${layoutType}`)
                    }
                    
                }
            })
        },
        toPage(url){
            let no = url.layoutNo
            if(no){
                let navs = store.getters.getNavs
                let active = this.getTree(navs,'key','parent_no',no)
                let root = null
                if(active.length == 1){
                    active = active[0]
                    if(active.parent_no){
                        root = this.getTree(navs,'key','parent_no',active.parent_no)
                        if(root.length == 1){
                            root = root[0]
                        }
                    }else{
                        root = active
                    }
                }
                if(active.children && active.children.length > 0){
                    active = active.children[0]
                }
                
                store.commit('setActiveNav',root) 

                
                this.toHomePage(active,root.key)
            }
        },
        getLinks(){
            let no = this.webConfig.webNo
            let req = {
            "serviceName": "srvcms_friend_links_select",
            "colNames": [
                "*"
            ],
            "condition": [
                {
                "colName": "website_no",
                "ruleType": "eq",
                "value": no
                },
                {
                "colName": "display",
                "ruleType": "eq",
                "value": '是'
                }
            ],
            "relation_condition": {},
            "page": {
                "pageNo": 1,
                "rownumber": 999
            },
            "order": [],
            "draft": false,
            "query_source": "list_page"
            }
            friendLinks(req).then((res) => {
                // console.log('友情链接',res)
                this.links = res.data.map(item => item)
            })
        }
    }

  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  .web-layout{
    .web-layout-content{
        position: relative;
    }
  }
  </style>
  