<template>
    <b-container fluid class="bg-primary web-login p-0">
        <b-navbar toggleable="lg" type="white" variant="white" class="web-login-hander p-1">
                <b-navbar-brand href="/" class="d-none d-sm-block p-0" v-if="Array.isArray(logos) && logos.length > 0">
                    <b-img left :src="logos[0].url" fluid class="logo" alt="logo"></b-img>
                </b-navbar-brand>
                
                <b-navbar-nav class="ml-auto" v-if="login.toHome">
                    <b-nav-item center >
                        <b-nav-form class="pl-0" >
                            <b-button size="sm"  pill variant="outline-primary" to="/">回到首页</b-button>
                        </b-nav-form>
                    </b-nav-item>
                </b-navbar-nav>
        </b-navbar>
        <div class="login-layout d-flex justify-content-center align-items-center" :style="`background: url(${bgurl}) no-repeat center center fixed;
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;`">
            <div class="container-md bg-white rounded rounded-lg" style="max-width:680px;height:auto;">
                <b-row class="m-0 h-100  p-4">
                    <b-col class="p-0  h-100 text-left d-md-flex flex-column justify-content-between overflow-hidden  d-none d-md-block">
                        <div>
                            <p class="text-left h4  p-1 text-blod text-primary" >{{login.layoutTitle}}</p>
                            <p class="text-left h6  p-1 " >{{login.layoutMsg}}</p>
                        </div>
                        <div>
                            <b-img left :src="bannerImg" fluid-grow  alt=""></b-img>
                        </div>
                        
                    </b-col>
                    
                    <b-col class=" p-0 pl-4  h-100 text-left d-flex flex-column justify-content-between">
                        

                        <b-form @submit="onSubmit" @reset="onReset" novalidate>

                            <b-form-group :id="`${field.column}-${field.type}`" :label="field.label" :label-for="`${field.column}-${field.type}`" v-for="(field,index) in formFields">
                                <b-form-input
                                :id="`${field.column}-${field.type}`"
                                v-model="field.value"
                                :type="field.type"
                                required
                                autocomplete="off"
                                :placeholder="field.placeholder"
                                :readonly="readonlyInput"
                                @change="change(field)"
                                
                                :state="field.state"
                                ></b-form-input>
                                <b-form-invalid-feedback :state="field.state">
                                    {{field.msg}}
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <!-- 
                                
                                :invalid-feedback="field.invalidFeedback(field.value)"
                                :valid-feedback="field.validFeedback(field.value)"
                                <b-form-group id="input-group-2" label="密码:" label-for="input-2">
                                <b-form-input
                                id="input-2"
                                v-model="form.password"
                                :state="null"
                                required
                                placeholder="请输入密码"
                                ></b-form-input>
                            </b-form-group> -->
                            <b-form-group id="input-group-4">
                                <b-form-checkbox-group v-model="form.reuse" id="reuse">
                                    <b-form-checkbox value="1">记住密码</b-form-checkbox>
                                </b-form-checkbox-group>

                               
                            </b-form-group>

                                <b-button block type="submit" variant="primary" size="lg">登录</b-button>
                                <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
                                
                                
                        </b-form>
                        <div class="p-2 d-flex flex-row mt-2 justify-content-between">
                                    <p class="text-center m-0  text-center">{{`没有账号？`}}
                                         <b-link href="/register" class="font-weight-bold ">   立即注册</b-link>
                                    </p>
                                    <b-link href="/resetpwd" class="font-weight-bold justify-content-end">忘记密码</b-link>
                        </div>
                    </b-col>
                </b-row>
            </div>

        </div>
        <b-navbar toggleable="lg" fixed="bottom" type="dark" variant="dark" class="web-login-hander p-0 text-center">
            
            <b-navbar-nav class="copyright text-center">
                <b-nav-text>
                    <copyright></copyright>

                </b-nav-text>
            </b-navbar-nav>
        </b-navbar>
    </b-container>
  </template>
  
  <script>
  
import store from '@/store'

import copyright from '@/components/copyright.vue'
  import bg from '@/assets/bg.jpg'
  import loginbanner from '@/assets/loginbanner.png'
  
  import { login,getMemberInfo } from '@/api/api.js'
  export default {
    components: {
        copyright 
    },
    props: {
      msg: String
    },
    computed:{
        login(){
            let config  = this.webConfig.hander.login || {
                text:'注册登录',
                show:true,
                loginPath:"/login",
                toHome:true
            }
            return config
        },
        logos(){
            let info = store.getters.getWebConfig
            let logos = []
            if(Array.isArray(info['_logos']) &&  info['_logos'].length > 0){
                logos = info['_logos'].map(item => item)
                // console.log('logo',logos)
            }
            return logos
        },
        info:function(){
            let info = null
            info = this.$store.getters.getWebConfig
            return info

        },
    },
    data(){
        return {
            readonlyInput:true,
            form:{
                "password": "",
                "name": "",
                "reuse": []
            },
            formFields:[{
                label:'账号',
                column:'name',
                type:'text',
                value:null,
                placeholder:'请输入账号信息...',
                rules:[{
                    type:'required',
                    trigger:'change',
                    msg:'账号或密码不能为空'
                }],
                state:null,
                invalidFeedback:function(value){
                    if(value){
                        return '账号已填写'
                    }
                },
                validFeedback:(value)=>{
                    if(!value){
                        return '请输入账号'
                    }
                }
            },{
                label:'密码',
                column:'password', 
                type:'password',
                 value:null,

                placeholder:'请输入密码信息...',

                rules:[{
                    type:'required',
                    trigger:'change',
                    msg:'账号或密码不能为空'
                }],
                state:null,
                invalidFeedback:function(value){
                    if(value){
                        return '账号已填写'
                    }
                },
                validFeedback:(value)=>{
                    if(!value){
                        return '请输入账号'
                    }
                }
            }],
            bgurl:bg,
            bannerImg:loginbanner
        }
    },
    created(){
        // console.log(this.webConfig)
    },
    mounted(){
        if(window.sessionStorage.getItem('isLogin') == 'true' && window.sessionStorage.getItem('bx_auth_ticket')){
            this.$router.replace({path:'/' })
        }
        console.log(localStorage.getItem('reuse'))
        if(localStorage.getItem('reuse') == 'true'){
            this.form.reuse = this.form.reuse.concat(['1'])
            // window.sessionStorage.setItem('reuseData',`${values.name} # ${values.password}`)
            let data = localStorage.getItem('reuseData')
            if(data){
                setTimeout(() => {
                    this.$set(this,'readonlyInput',false)
                    data = data.split(` # `)
                    this.$set(this.formFields[0],'value',data[0])
                    this.$set(this.formFields[1],'value',data[1])

                }, 300);
            }else{
                setTimeout(() => {
                    this.$set(this,'readonlyInput',false)

                }, 300);
            }
        }else{
            setTimeout(() => {
                    this.$set(this,'readonlyInput',false)

                }, 300);
        }
    },
    methods:{
        change(e){
            let self = this
            console.log(e.value)
            for(let field of this.formFields){
                if(e.column == field.column){
                    let rules = field.rules
                    if(Array.isArray(rules)){
                        for(let rule of rules){
                            let type = rule.type
                            switch (type) {
                                case 'required':
                                    if(e.value == '' || e.value == null || e.value == undefined){
                                        self.$set(e,'state',false)
                                        self.$set(e,'msg',rule.msg)
                                    }else{
                                        self.$set(e,'state',null)
                                        self.$set(e,'msg','')
                                    }
                                    break;
                            
                                default:
                                    break;
                            }
                        }
                    }
                }
            }
        },
        validFeedback(){
            let self = this
            for(let field of this.formFields){
                let rules = field.rules
                    if(Array.isArray(rules)){
                        for(let rule of rules){
                            let type = rule.type
                            switch (type) {
                                case 'required':
                                    if(field.value == '' || field.value == null || field.value == undefined){
                                        self.$set(field,'state',false)
                                        self.$set(field,'msg',rule.msg)
                                    }else{
                                        self.$set(field,'state',null)
                                        self.$set(field,'msg','')
                                    }
                                    break;
                            
                                default:
                                    break;
                            }
                        }
                    }
            }
        },
        onSubmit(evt) {
            console.log(evt)
            evt.preventDefault()
            let values = {}
            for(let field of this.formFields){
                values[field.column] = field.value
                // field.state(field.value)
            }
            this.validFeedback()
            let valids = []
            if(Object.keys(values).length == Object.values(values).filter(item => item).length){
                let req =  [
                    {
                        "serviceName": "srvuser_login",
                        "data": [
                        {
                            "user_no": values.name,
                            "pwd": values.password,
                            "terminal_type": "PC"
                        }
                        ]
                    }
                ]
                login(req).then(res => {
                    if(res.state == "SUCCESS"){
                        let loginInfo = res.response[0].response
                        console.log(loginInfo)
                        this.$store.commit('setLoginInfo',loginInfo)
                        let validTime = store.getters.getLoginValidTo
                        let token = store.getters.getToken
                        window.sessionStorage.setItem('isLogin',true)
                        window.sessionStorage.setItem('loginInfo',JSON.stringify(loginInfo) )

                        window.sessionStorage.setItem('bx_auth_ticket',token)
                        window.sessionStorage.setItem('validTimeTo',validTime)
                        if(this.form.reuse.length > 0){
                            localStorage.setItem('reuse',true)
                            localStorage.setItem('reuseData',`${values.name} # ${values.password}`)

                        }else{
                            localStorage.setItem('reuse',false)

                            localStorage.setItem('reuseData',``)
                        }
                        let info = store.getters.getLoginInfo
                        console.log(info)
                        if(info.mobile){
                            getMemberInfo({
                            "serviceName": "srvws_member_info_select",
                            "colNames": [
                                "*"
                            ],
                            "condition": [
                                {
                                "colName": "website_no",
                                "ruleType": "eq",
                                "value": "PCWS202307110001"
                                },
                                {
                                "colName": "phone_no",
                                "ruleType": "eq",
                                "value": info.mobile
                                }
                                
                            ],
                            "relation_condition": {},
                            "page": {
                                "pageNo": 1,
                                "rownumber": 5
                            },
                            "order": [],
                            "draft": false,
                            "query_source": "list_page"
                            }).then(memberres =>{
                                if(memberres.state == "SUCCESS"){
                                    if(Array.isArray(memberres.data) && memberres.data.length > 0){
                                        let memberInfo = memberres.data[0]
                                        store.commit('setMemberInfo',memberInfo)
                                    }
                                }
                            })
                        }
                        
                        this.$router.replace({path:'/' })
                        // this.$set(this,'loadData',content)
                    }else{

                        this.$bvModal.msgBoxOk(res.resultMessage, {
                                title: '请确认',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'primary',
                                okTitle: '确认',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true
                            })
                            .then(value => {
                            })
                            .catch(err => {
                            })
                        
                    }
                })
            }
        },
        onReset(evt) {
            evt.preventDefault()
            // Reset our form values
            this.form.email = ''
            this.form.name = ''
            this.form.password = ''
            this.form.reuse = []
            // Trick to reset/clear native browser form validation state
            // this.show = false
            // this.$nextTick(() => {
            //     // this.show = true
            // })
        },
        goBack(){
            this.$router.go(-1)
        }
    }

  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  .web-login{
    width: 100vw;
    height: 100vh;
    .web-login-hander{
        .copyright{
            width: 100vw;
            justify-content: center;
        }
        .logo{
             height: 40px;
        }
    } 
    .login-layout{
        height: calc(100vh - 90px);
        width:100%;
    }
  }
  </style>
  