<template>
    <div class="d-flex justify-content-center align-items-center" style="height: 60vh;">
        <template>
            <div class="d-flex flex-column">
                <div class="h3">暂未开放敬请期待</div>
                <div>
                    <!-- <b-button size="sm"  pill variant="outline-primary" to="/">回到首页</b-button> -->
                </div>
            </div>
            
        </template>
        
    </div>
  </template>
  
  <script>
  
//   import mainUi from '@/components/main.vue'
  
  export default {
    components: {
        // mainUi
    },
    props: {
      msg: String
    },
    computed:{
        

    },
    data(){
        return {
            
        }
    },
    methods:{
        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
 .search-bar{
    justify-content: center;
    position: relative;
    top: -40px;
    border-radius: 8px;
    height: 80px;
    list-style: none;
    & > li{
        width: calc(100% - 40px);
        form{
            display: flex;
            justify-content: space-between;
            button{
                width: 140px;
                margin-left: 10px;
            }
        }
    }
 }
  </style>
  