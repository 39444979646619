
import Vue from 'vue'
import VueRouter from 'vue-router'
// 公共页面
import login from '@/pages/main/login.vue'
import register from '@/pages/main/register.vue'
import none from '@/pages/main/none.vue'
//
import index from '@/pages/index.vue'
import homepage from '@/pages/c-homepage.vue'

Vue.use(VueRouter) 
const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/pages/main/search.vue') 
  },
  {
    path: '/resetpwd',
    name: 'resetpwd',
    component: () => import('@/pages/main/resetpwd.vue') 
  },
  {
    path: '/about-society',
    name: 'about-society',
    component: homepage
  },
  
  {
    path: '/homepage/:no/:active',
    name: 'homepage',
    component: () => import('@/pages/c-homepage.vue'),
    //子路由
    children:[
      {
        path:'list/:activeNo/:layout',
        //也不使用懒加载，先在文件头部提前引入
        component:()=> import('@/components/list.vue')
      },
      {
        path:'detail/:value/:layout',
        //@是定位 src 文件夹
        component:()=> import('@/components/detail.vue')
      },
      {
        path:'register/:type',
        //@是定位 src 文件夹
        component:()=> import('@/components/register.vue')
      },
      {
        path:'user/:type',
        //@是定位 src 文件夹
        component:()=> import('@/components/memberCenter.vue')
      },
      {
        path:'no/:text',
        //@是定位 src 文件夹
        component:()=> import('@/components/none.vue')
      }
    ]
  },
  
  {
    path: '/download/:no/:active',
    name: 'download',
    component: () => import('@/pages/c-homepage.vue'),
    //子路由
    children:[
      {
        path:'list/:activeNo',
        //也不使用懒加载，先在文件头部提前引入
        component:()=> import('@/components/list.vue')
      },
      {
        path:'detail/:value',
        //@是定位 src 文件夹
        component:()=> import('@/components/detail.vue')
      },
      {
        path:'no/:text',
        //@是定位 src 文件夹
        component:()=> import('@/components/none.vue')
      }
    ]
  },
  // {
  //   path: '/briefing',
  //   name: 'briefing',
  //   component: () => import('@/pages/jianBao.vue')
  // },
  // {
  //   path: '/activities',
  //   name: 'activities',
  //   component: () => import('@/pages/activities.vue')
  // },
  // {
  //   path: '/briefingDetail/:body/',
  //   name: 'briefingDetail',
  //   component: () => import('@/pages/jianBaoDetail.vue')
  // },
  // {
  //   path: '/policyDetail/:body/',
  //   name: 'policyDetail',
  //   component: () => import('@/pages/zhengCeDetail.vue')
  // },
  // {
  //   path: '/mediaDetails/:body/',
  //   name: 'mediaDetails',
  //   component: () => import('@/pages/mediaDetails.vue')
  // },
  // {
  //   path: '/buTie',
  //   name: 'buTie',
  //   component: () => import('@/pages/buTie.vue')
  // },
  // {
  //   path: '/gaoXin',
  //   name: 'gaoXin',
  //   component: () => import('@/pages/gaoXin.vue')
  // },
  // {
  //   path: '/zhuanJing',
  //   name: 'zhuanJing',
  //   component: () => import('@/pages/zhuanJing.vue')
  // },
  // {
  //   path: '/zhengCeMap',
  //   name: 'zhengCeMap',
  //   component: () => import('@/pages/zhengCeMap.vue')
  // },
  // {
  //   path: '/zhengCeHot',
  //   name: 'zhengCeHot',
  //   component: () => import('@/pages/zhengCeHot.vue')
  // },
  // {
  //   path: '/zhengCePiPei',
  //   name: 'zhengCePiPei',
  //   component: () => import('@/pages/zhengCePiPei.vue')
  // },
  // {
  //   path: '/youQingLink',
  //   name: 'youQingLink',
  //   component: () => import('@/pages/youQingLink.vue')
  // },
  {
    path: '/none',
    name: 'none',
    component: () => import('@/pages/main/none.vue')
  },
  // {
  //   path: '/form',
  //   name: 'form',
  //   component: () => import('@/pages/form.vue')
  // }
  
]
 
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router