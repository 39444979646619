<template>
    <div class="bg-white rounded border border-cBorder w-100" style="max-width:100%">
        <template v-if="layout !== 'imgCardTopic'">
            <div class="cprimary p-3 " v-if="type == `text` && mediaDetails && !['detaiCardTopic','imgCardTopic'].includes(layout)">
           
                <b-row class="p-0 m-0">
                    <b-col class="text-left p-2">
                        <h6 class="text-dark font-weight-bold">{{ mediaDetails[colsMaps.title] }}</h6>
                    </b-col>
                    
                </b-row>
                <div class="p-2 d-flex justify-content-md-start border-bottom text-secondary">
                    <div  class="text-center pr-3">内容来源：{{ mediaDetails[colsMaps.source] }}</div>
                    <div  class="text-center pl-3">发布时间：{{ new Date(mediaDetails[colsMaps.date]).toLocaleDateString()  }}</div>
                </div>
                <b-row class="p-0 m-0  rounded-lg border-cBorder">
                    <b-col cols="12" class="p-2">
                        <div class="block" style="text-align: initial;" v-html="`${mediaDetails[colsMaps.content]}`.replace(/\n/g,'<br/>')">
                        </div>
                    </b-col>
                </b-row>
                <b-row class="p-1 p-md-3  rounded-lg border-cBorder" v-if="mediaDetails['document'] && downloadFiles">
                    <b-col cols="12" class="" v-for="(file,i) in downloadFiles">
                        <div class="block" style="text-align: initial;">
                            <b-icon-download></b-icon-download>[附件] <b-link  @click="download(file)">{{ file.src_name }}  [点击下载]</b-link>
                             
                        </div>
                    </b-col>
                </b-row>
            </div>
        </template>
        <template  v-if="layout == 'imgCardTopic'">
            <div class="cprimary p-3" v-if="type == `text` && mediaDetails">
                <div class="row">
                    <div class="col-md-8  col-sm-12">
                        <b-row class="">
                            <b-col class="text-left p-2">
                                <h6 class="text-dark font-weight-bold">{{ mediaDetails[colsMaps.source] }}</h6>
                            </b-col>
                            
                        </b-row>
                        <b-row class="">
                            <b-col class="text-left p-2">
                                <h6 class="text-dark font-weight-bold bg-clight p-1">{{ mediaDetails[colsMaps.title] }}</h6>
                            </b-col>
                            
                        </b-row>
                        
                        <b-row class="p-0  rounded-lg border-cBorder">
                            <b-col cols="12" class="p-2">
                                <div class="block" style="text-align: initial;" v-html="`${mediaDetails[colsMaps.content]}`.replace(/\n/g,'<br/>')">
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="p-1 p-md-3  rounded-lg border-cBorder" v-if="mediaDetails['document'] && downloadFiles">
                            <b-col cols="12" class="" v-for="(file,i) in downloadFiles">
                                <div class="block" style="text-align: initial;">
                                    <b-icon-download></b-icon-download>[附件] <b-link  @click="download(file)">{{ file.src_name }}  [点击下载]</b-link>
                                    
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <b-card-img :src="`${webCfg.protocol}://${webCfg.host}:${webCfg.port}/file/download?fileNo=${mediaDetails['thn_img']}`" class="rounded bg-clight" alt="暂无图片" ></b-card-img>
                        <!-- <b-card img-bottom img-top :body-class="'none'" :img-src="`${webCfg.protocol}://${webCfg.host}:${webCfg.port}/file/download?fileNo=${mediaDetails['thn_img']}`" img-alt="暂无图片" class="border-0 w-100" img-width="100%"></b-card> -->
                    </div>
                </div>
                
            </div>
        </template>
        <template  v-if="layout == 'detaiCardTopic'">
            <div class="cprimary p-3" v-if="type == `text` && mediaDetails">
                <div class="row">
                    <div class="col-md-4 col-sm-12" v-if="mediaDetails['thn_img']">
                        <b-card-img :src="`${webCfg.protocol}://${webCfg.host}:${webCfg.port}/file/download?fileNo=${mediaDetails['thn_img']}`" class="rounded bg-clight" alt="暂无图片" ></b-card-img>
                        
                    </div>
                    <div class=" col-sm-12 d-flex align-self-center" :class="mediaDetails['thn_img'] ? 'col-md-8 ' : ''">
                        
                        <b-row class="p-0  rounded-lg border-cBorder">
                            <b-col cols="12" class="p-2 d-flex align-items-center">
                                <div class="block" style="text-align: initial;" v-html="`${mediaDetails[colsMaps.content]}`.replace(/\n/g,'<br/>')">
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="p-1 p-md-3  rounded-lg border-cBorder" v-if="mediaDetails['document'] && downloadFiles">
                            <b-col cols="12" class="" v-for="(file,i) in downloadFiles">
                                <div class="block" style="text-align: initial;">
                                    <b-icon-download></b-icon-download>[附件] <b-link  @click="download(file)">{{ file.src_name }}  [点击下载]</b-link>
                                    
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    
                </div>
                
            </div>
        </template>
        
        <template v-if="!no && !mediaDetails">
            <none :title="'暂无内容，敬请关注'"></none>
        </template>
        <template v-if="showUserInterMsg && mediaDetails">
            <userInterAction :initContentNo="mediaDetails.content_no" :initServiceName="'srvdaq_pc_ws_content_cmt_select'"></userInterAction>
        </template>
    </div>
  </template>
  
  <script>
//   import qrCode from '@/assets/qrCode.png'
import store from '@/store'
import userInterAction from '@/components/userInterAction.vue'
import none from '@/components/none.vue'
  import { getListData } from '@/api/api.js'
  export default {
    name: 'detail-layout',
    components:{userInterAction,none},
    props: {
        type:{
            type:String,
            default(){
                return 'text'  // text   map   list
            }
        },
        config:{
            type:[Object,Array],
            default(){
                return null
             
            }
        },
        initData:{
            type:[Object,Array],
            default(){
                return null
             
            }
        },
        no:{
            type:String,
            default(){
                return ''  // text   map   list
            }
        },
        colsMaps:{
            type:Object,
            default(){
                return {title:'title',subTitle:'summary',img:'thn_img', date:'create_time',tags:'tags',source:'source',content:'content'}
            }
        }
        

    },
    mounted(){
        if(this.no){
            this.$set(this,'keyVal',this.no)
        }else if(this.$route.params.value){
            this.$set(this,'keyVal',this.$route.params.value)
        }
        
        if(!this.mediaDetails){
            this.getDetail()
        }else{
           
        }

    },
    computed:{
        showUserInterMsg(){
            let show = false
            let no = this.mediaDetails ? this.mediaDetails.category_no :''
            let navs = store.getters.getNavs
            if(no){
                navs = navs.filter(item => item.key == no)
                if(Array.isArray(navs) && navs.length == 1){
                    if(navs[0].is_show_cmt === true){
                        show = true
                    }
                }
            }
            
            return show
        },
        webCfg(){
            let config = this.webConfig
            return config
        },
        layout(){
            let layout = 'none'
            if(this.$route.params && this.$route.params.layout){
                layout = this.$route.params.layout
            }

            return layout
        },
        mediaDetails(){
            let data = this.initData
            if(!data && this.loadData){
                data = this.loadData
            }
            return data
        }
    },
    data() {
      return {
        loadData:null,
        keyVal:null,
        key:'',
        downloadFiles:null
      }
    },
      methods: {
        getDetail(){
            let serviceName = 'srvdaq_pc_website_content_select'
            let req = {
                "serviceName": serviceName,
                "colNames": [
                    "*"
                ],
                "condition": [
                    {
                        "colName": "id",
                        "ruleType": "eq",
                        "value": this.keyVal
                    }
                ],
                "page": {
                    "pageNo": 1,
                    "rownumber": 999
                },
                "order": []
            }
            getListData (req,null,serviceName).then(res=>{
                // console.log(res)
                if(res.state == "SUCCESS"){
                    let content = res.data[0]
                            // 如果有人工设置时间 优先使用
                    content.create_time = content.release_time ? content.release_time : content.create_time
                    this.$set(this,'loadData',content)
                }
            })
        },
        getDownloadInfo(){
            let no = this.mediaDetails['document']
            if(no){
                this.getFileUrls(no).then(res => {
                    console.log(res)
                    if(Array.isArray(res) && res.length > 0){
                        let content = res
                        this.$set(this,'downloadFiles',content)
                    }
                })
            }
            
        },
        download(file){
            let config = this.webConfig
           let url = `${config.protocol}://${config.host}:${config.port}/file/download?filePath=${file.fileurl}` 
           console.log(url) 
           window.open(url, '_blank')
        }
    },
    watch:{
        "mediaDetails":{
            deep:true,
            handler:function(nval,oval){
                if(nval){
                    this.getDownloadInfo()
                }
            }
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  </style>
  