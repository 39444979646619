<template>
    <div class="web-layout bg-light">
        <!-- <carousel></carousel> -->
        <banner :layout="'img'" :height="'auto'" :url="bannerUrl" style="min-height:100px;"></banner>
        <b-container fluid="sm" class="web-layout-content bg-white p-1 h-100 rounded rounded-lg " >
            <!-- :style="`top: -${60}px;min-height:360px`" -->
            <navpath :paths="paths"></navpath>
            <b-container fluid >
                <b-container fluid class="d-flex p-0">
                    <actionList class="d-none d-sm-block" v-if="activeLayout"  :paths="navs" :initActive="initActive" :title="`${activeLayout.text}`" :lineHeight="50" @change="onClick"></actionList>
                    <div  class="flex-fill ml-sm-3" v-if="activeNavbar">
                        <div class="h5 text-left align-middle font-weight-bold m-0 text-listTitleColor" :style="`line-height:${50}px;`">
                            {{activeNavbar.text}}
                            <div v-if="activeNavbar.en" class="d-inline-flex h6 align-middle pt-2 pl-3 text-info font-weight-bold">{{ `▎` }} {{ `${activeNavbar.en}` }}</div>
                        </div>
                        <tabs class="mb-3 d-block d-sm-none" v-if="Array.isArray(navs) && navs.length > 0" :paths="navs" :initActive="initActive"  @change="onClick"></tabs>
                        

                        <router-view ></router-view>
                        <!-- :initRequest="{
                            'serviceName': activeNavbar.params.serviceName,
                            'colNames': ['*'],
                            'condition':activeNavbar.params.condition
                        }" -->
                        
                    </div>
                </b-container>
            </b-container>
        </b-container>
    </div>
    
</template>
  
  <script>
  
import store from '@/store'
  import banner from '@/components/banner.vue'
  import navpath from '@/components/navpath.vue'
  import actionList from '@/components/actionList.vue'
  import tabs from '@/components/tabs.vue'
  import list from '@/components/list.vue'
  import detail from '@/components/detail.vue'
  import carousel from '@/components/carousel.vue'
  import none from '@/components/none.vue'
  
  export default {
    components: {
        banner ,navpath,actionList,list,tabs,detail,carousel,none
    },
    props: {
      msg: String
    },
    computed:{
        activeLayout(){
            // 当前一级栏目
            let navs = store.getters.getNavs
            let item = null
            navs = navs.filter(item => item.key == this.rootNo)
            if(navs.length > 0){
                item = navs[0]
            }
            return item
        },
        bannerUrl(){
            let url = ''
            if(this.activeLayout){
                // store.dispatch('getActiveNavAction',this.activeLayout.key)
                // url = store.getters.getActiveCarousel

                // let config = this.webConfig
                // if(Array.isArray(url) && url.length > 0){
                //     let img = url[0]
                //     url=`${config.protocol}://${config.host}:${config.port}/file/download?fileNo=${img.carousel_img}`
                // }
                if(this.activeLayout.banner){
                    url = this.activeLayout.banner
                }
            }
            
            

            return url
        },
        navs(){
            // 当前栏目下子栏目，树形
            let navs = store.getters.getNavs
            navs = this.getTree(navs,'key','parent_no',this.rootNo)
            if(navs.length == 1 && navs[0]['children'].length > 0){
                navs = navs[0]['children'].map(item => item)
            }
             
            return navs
        },
        paths(){
            let list = [
                    {
                        text: '首页',
                        href: '#',
                        router:'index'
                    }
                    
                    
                ]
                if(this.activeLayout){
                    list.push({
                        text: this.activeLayout.text,
                        href: '#',
                        router:'homepage'
                    })
                }
                if(this.activeNavbar && this.activeLayout &&  this.activeNavbar.key && this.activeLayout.key && this.activeNavbar.key !== this.activeLayout.key){
                    list.push({
                        text: this.activeNavbar ? this.activeNavbar.text : this.navs[0].text,
                        active: true
                    })
                }  
                return list
        },
        activeNavbar(){
            let bar = null
            let rootActiveBar = store.getters.getActiveNav
            if(!this.active && this.navs.length > 0 && !this.pageLayoutNo){
                bar = this.navs[0]
            }else if(!this.active && this.navs.length > 0 && this.pageLayoutNo){
                for(let nav of this.navs){
                    if(nav.key == this.pageLayoutNo && !bar){
                        bar = nav
                    }
                }
                for(let nav of this.navs){
                        if(nav.children && nav.children.length > 0 ){
                            for(let child of nav.children){
                                if(child.key == this.pageLayoutNo &&  !bar){
                                    bar = child
                                }
                            }
                        }
                }
                // bar = this.navs.filter(item => item.key == this.pageLayoutNo)
                // if(bar.length == 1){
                //     bar = bar[0]
                // }
            }else{
                bar = this.active
            }
            if(this.$route.params && rootActiveBar?.key){
                if(this.$route.params.no && this.$route.params.activeNo && this.$route.params.no == this.$route.params.activeNo && this.$route.params.activeNo == rootActiveBar.key){
                    bar = rootActiveBar
                }
                
            }
            if(!bar && this.activeLayout){
                bar = this.activeLayout

            }
            return bar
        },
        menuMode(){
            let mode = 'route'
            if(this.$route.query && this.$route.query.menuMode && this.$route.query.menuMode == 'groupTabs'){
                mode = 'group'
            }

            return mode
        },
        initActive(){
            let active = 'root' // 0
            let isInit = false
            let key = this.activeNavbar ? this.activeNavbar.key : ''
            // for(let nav of this.navs){
            //     if(nav.key == key){
            //         bar = nav
            //     }
            // }
            if(key){
                for(let inav in this.navs){
                    let nav = this.navs[inav]
                    if(nav.key == key && this.activeNavbar.text == nav.text){
                        active = `${inav}`
                        isInit = true
                    }
                    let navChildren = nav.children
                    if(navChildren && navChildren.length > 0  && !isInit){
                        for(let ichild in navChildren){
                            let child = navChildren[ichild]
                            if(child.key == key && this.activeNavbar.text == child.text){
                                active = `${inav}-${ichild}`
                                isInit = true
                            }
                        }
                        

                    }else{
                        

                    }
                }
            }
            
            if(this.navs && this.pageLayoutNo){
                for(let i in this.navs){
                    if(this.pageLayoutNo && this.pageLayoutNo == this.navs[i].key && this.activeNavbar.text == this.navs[i].text){
                        active = `${i}`
                    }
                }
            }
            let arr = []
            arr = active.split('-')
            return arr
        }
        
    },
    data(){
        return {
            rootNo:'',
            pageLayoutNo:'',
            banner:{
                height:"300",
            },
            active:null
        }
    },
    mounted(){
         if(this.$route.params && this.$route.params.no){
            this.$set(this,'rootNo', this.$route.params.no)
            this.$set(this,'pageLayoutNo', this.$route.params.active)
            // this.pageLayoutNo = this.$route.params.no
         }
        
    },
    methods:{
        onClick(e){
            this.active = e 
            console.log(e)
            if(this.menuMode == 'group'){
                store.commit('setActiveNav',this.activeLayout) 
                let path = this.$route.path
                let query = e.params
                if(query){
                    let keys = Object.keys(query)
                    let str = ''
                    keys = keys.filter(item => item)
                    for(let key of keys){
                        str += `${key}=${query[key]}&`
                    }
                    str = str.slice(0,str.length-1)
                    path = `${path}?${str}`
                }
                this.$router.push(path,(sce => {
                    // self.$router.push({name:path,params:{ "no":nav.key }},(sce => {
                    console.log('跳转异常：',sce)
                }),(err => {
                    console.log('跳转异常：',err)
                }))

            }else {
                if(e.children && e.children.length == 0){
                    store.commit('setActiveNav',this.activeLayout) 

                    this.toHomePage(e,this.activeLayout.key)
                    // if(href && href.indexOf('[register]') !== -1){
                    //     let type = href.split(':').length == 1 ? 'person' : href.split(':')[1]
                    //     console.log(type)
                    //     href = 'register'
                    //     this.$router.push(`/${this.activeLayout.href}/${this.activeLayout.key}/${e.key}/${href}/${type}`)
                    // }else if(href && href.indexOf('[user]') !== -1){
                    //     href = 'user'
                    //     this.$router.push(`/${this.activeLayout.href}/${this.activeLayout.key}/${e.key}/${href}`)
                    // }else{
                    //     href = 'list'
                    //         this.$router.push(`/${this.activeLayout.href}/${this.activeLayout.key}/${e.key}/${href}/${e.key}`)
                    // }


                    
                }else{
                    store.commit('setActiveNav',e.children[0]) 
                    let href = this.activeNavbar.href

                    this.toHomePage(e.children[0],this.activeLayout.key) 
                }
            }
            
        }
    }

  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  .web-layout{
    .web-layout-content{
        position: relative;
    }
  }
  </style>
  