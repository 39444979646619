<template>
    <div>
        <b-row class="m-0 h-auto w-100 justify-content-center" v-if="initMode == 'complete'">
            <b-col  cols="12" md="6" class="p-3  h-100 text-left">
                <div class=" position-relative">
                    <b-img :src="vipImg1" fluid alt="Fluid image" style="top:0;left: 0;width: 100%;"></b-img>
                    <div v-if="Array.isArray(logos) && logos.length > 0" class="block position-absolute" style="top:5%;left: 5%;width: 60%;">
                        <b-img :src="logos[0].url" fluid alt="Fluid image"></b-img>
                    </div>
                    <div class="block position-absolute" style="bottom:5%;left: 5%;width: 90%;">
                        <div class="d-flex pb-2">
                            <div class="pr-2 pl-2" style="width:30%;max-width: 6rem;">
                                
                                <div v-if="info.memberInfo && info.memberInfo['_hand_url']" >
                                    <b-img :src="info.memberInfo['_hand_url']" fluid alt="Fluid image" ></b-img>
                                </div>
                            </div>
                            <div class="d-flex flex-column text-left pl-3">
                                <div class="pb-1"><span class="font-weight-bold h5">{{info.memberInfo.name}}</span></div>
                                <div class="" style="font-size: 0.8rem;">单位：<span  class="text-primary">{{info.memberInfo.work_unit}}</span></div>
                                <div style="font-size: 0.8rem;">有效期：<span  class="text-primary">{{info.memberInfo.end_date ? info.memberInfo.end_date : '未生效'}}</span></div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="text-primary font-weight-bold">{{ `${info.memberInfo.member_type}会员` }}</div>
                            <div>{{info.memberInfo.vip_no}}</div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col   cols="12" md="6" class="p-3  h-100 text-left">
                <div  class=" position-relative">
                    <b-img :src="vipImg0" fluid alt="Fluid image"></b-img>
                    <div class="block position-absolute d-flex flex-column justify-content-between" style="top:5%;left: 5%;width: 90%;height:90%">
                        <div class="d-flex">
                            <div class="d-flex flex-column text-left">
                                <div class="pb-1"><span class="font-weight-bold h6">使用说明</span></div>
                                <div style="font-size: 0.8rem;">1：<span  class="">.</span></div>
                                <div style="font-size: 0.8rem;">2：<span  class="text-primary">.</span></div>
                                <div style="font-size: 0.8rem;">3：<span  class="text-primary">.</span></div>
                            </div>
                        </div>
                        <div class="d-flex ">
                            <div class="d-flex flex-column text-left">
                                <div style="font-size: 0.8rem;">首页：<span  class="">{{info.baseInfo.domain ? info.baseInfo.domain : '-'}}</span></div>
                                <div style="font-size: 0.8rem;">电话：<span  class="">{{info.baseInfo.telephone ? info.baseInfo.telephone : '-'}}</span></div>
                                <div style="font-size: 0.8rem;">地址：<span  class="">{{info.baseInfo.address ? info.baseInfo.address : '-'}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row class="m-0 h-auto w-100 justify-content-center bg-lightestprimary" v-if="initMode == 'simple'">
             <!-- 简易的 -->
            <b-col cols="12" class="p-3  h-100 text-left" v-if="info.memberInfo ">
                <div class="d-flex pb-2 ">
                    <div class="pr-2 pl-2" style="min-width: 90;">
                        
                        <div v-if="info.memberInfo && info.memberInfo['_hand_url']" >
                            <b-img v-bind="{ width: 75, height: 75, class: 'm1' }" :src="info.memberInfo['_hand_url']" rounded fluid alt="图片加载失败" ></b-img>
                        </div>
                    </div>
                    <div class="d-flex flex-column text-left">
                        <div class="d-flex justify-content-between">
                            <div class="pb-1"><span class="font-weight-bold h5">{{info.memberInfo.name}}</span> <span>SANS20230001</span></div>
                            <div  class="text-orange" @click="toMemberCenter()">[会员中心]</div>
                        </div>
                        
                        <div class="" style="font-size: 0.8rem;">单位：<span  class="text-primary">{{info.memberInfo.work_unit}}</span></div>
                        <div style="font-size: 0.8rem;">有效期：<span  class="text-primary">{{info.memberInfo.end_date ? info.memberInfo.end_date : '未生效'}}</span></div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
    
  </template>
  
  <script>
  
import store from '@/store'
 
//   import webForm from '@/components/form.vue'

import vip0 from '@/assets/vip0.png'
import vip1 from '@/assets/vip1.png'
import { getV2,setRequest,getMemberInfo } from '@/api/api.js'
  export default {
    components: {
    },
    props: {
       initMode:{
        type:String,
        default:'complete'    //  simple  complete
       }
    },
    computed:{
        authState(){
            let type = '未注册'
            if(this.info.memberInfo && this.info.memberInfo.auth_state){
                type = this.info.memberInfo.auth_state
            }

            return type
        },
        info(){
            let state = {
                isLogin:store.getters.getLoginState,
                info:store.getters.getLoginInfo,
                memberInfo:store.getters.getMemberInfo,
                baseInfo:store.getters.getWebConfig,
             }
             if(state.memberInfo && state.memberInfo.head_image){
                state.memberInfo['_hand_url'] = `${store.getters.hostPath}/file/download?fileNo=${state.memberInfo.head_image}`
             }

             return state
        },
        memberType(){
            let type = ''
            if(this.loadType){
                type = this.loadType
            }

            return type
        },
        
        logos(){
            let info = store.getters.getWebConfig['_logos']
            let logos = []
            if(Array.isArray(info) &&  info.length > 0){
                logos = info.map(item => item)
                console.log('logo register',logos)
            }
            return logos
        },
        
    },
    data(){
        return {
            vipImg0:vip0,
            vipImg1:vip1,
            form:{
                "password": "",
                "passwordb": "",
                "checked": [],
                "phone":'',
                "code":""
            },
            loadType:'',
            
        }
    },
    created(){
        // console.log(this.webConfig)
    },
    mounted(){
        if(this.$route.params.type){
            this.loadType = this.$route.params.type
        }
        if(!this.info.memberInfo && this.info.isLogin){
            this.initMemberInfo()
        }
        // this.buildFormFields()
    },
    methods:{
        initMemberInfo(){
            let info = store.getters.getLoginInfo
            console.log(info)
            if(info && info.mobile){
                getMemberInfo({
                "serviceName": "srvws_member_info_select",
                "colNames": [
                    "*"
                ],
                "condition": [
                    {
                    "colName": "website_no",
                    "ruleType": "eq",
                    "value": this.info.baseInfo.website_no
                    },
                    {
                    "colName": "phone_no",
                    "ruleType": "eq",
                    "value": info.mobile
                    }
                    
                ],
                "relation_condition": {},
                "page": {
                    "pageNo": 1,
                    "rownumber": 5
                },
                "order": [],
                "draft": false,
                "query_source": "list_page"
                }).then(memberres =>{
                    if(memberres.state == "SUCCESS"){
                        if(Array.isArray(memberres.data) && memberres.data.length > 0){
                            let memberInfo = memberres.data[0]
                            // if(memberInfo.head_image){
                            //     memberInfo['_headUrl'] = `${store.getters.hostPath}/file/download?fileNo=${memberInfo.head_image}`
                            //             store.commit('setMemberInfo',memberInfo)
                            // }else{
                            //     store.commit('setMemberInfo',memberInfo)
                            // }
                            this.$store.commit('setMemberInfo',memberInfo)
                        }else{
                            console.log('member info init ?',memberres)
                        }
                    }
                })
            }
        },
        
    }

  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  </style>
  