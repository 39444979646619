
 
import axios from "axios"
import store from '@/store'
import router from '@/router'
let IsCheckTimeOut = function(){
   return false
}
const instance = axios.create({
    // 反向代理是解决不同环境下跨域的问题，设置基础值地址是统一设置url地址。基础地址的设置会触            
    // 发反向代理的跨域配置，所以要先设置好反向代理
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 1000 * 60,
    headers: { 'content-type': 'application/json;charset=UTF-8',withCredentials: true }
});
 
// 添加请求拦截器
// instance 以...为例
// interceptors英文翻译拦截机
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // 在这个位置需要统一的去注入token
    console.log('--',config)
   
    if (store.getters.token && config.url.indexOf('srvsso_send_node') === -1) {
      // 只有在有token的情况下 才有必要去检查时间戳是否超时
      // if (IsCheckTimeOut()) {
      //   // 跳转到登录页
      //   router.push('/login')
      //   return Promise.reject(new Error('token超时了'))
      // }
      // 如果token存在 注入token
      
      if(store.getters.getLoginState == false){
        config.headers['bx_auth_ticket'] = sessionStorage.getItem('bx_auth_ticket')
      }else{
        config.headers['bx_auth_ticket'] = `${store.getters.token}`
      }
    }else if(sessionStorage.getItem('bx_auth_ticket') && !store.getters.token && store.getters.getLoginState == false){
      config.headers['bx_auth_ticket'] = sessionStorage.getItem('bx_auth_ticket')
    }
    return config // 必须返回配置
    
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});
 
// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    console.log(sessionStorage.getItem('isLogin'),'hander',response.headers['bx_auth_ticket'],response)
    if(response.headers && response.headers['bx_auth_ticket'] && sessionStorage.getItem('isLogin') == 'false' && response.config.url.indexOf('srvsso_send_node') !== -1){
      console.log('--app->',response,response.headers['bx_auth_ticket'])
      sessionStorage.setItem('bx_auth_ticket',response.headers['bx_auth_ticket'])
    }
    const { state, message, data,resultCode,resultMessage } = response.data
    if (state == 'SUCCESS' || resultCode == 'SUCCESS' || resultCode == '4444') {
      return response.data 

    } else if(state == 'FAILURE'){
      switch (resultCode) {
        case '0011':
            store.dispatch('closelogin') // 登出操作
            return response.data 
          break;
      
        default:
            // return Promise.reject(new Error(resultMessage))
            
            
            return response.data 
          break;
      }
      console.error(resultMessage)
    }else{
      return response.data
      // return Promise.reject(new Error( JSON.stringify(response.data) ))
    }
}, function (error) {
    // 对响应错误做点什么
    error = JSON.parse(error)
    console.log('----------请求失败',error)
    if (response.resultCode == '0011') {
      console.error('res error:',response.resultMessage)
      // 后端告诉我token超时了,被动处理token
      // store.dispatch('user/logout') // 登出action 删除token
      router.push('/',{query:{timestr:new Date().getTime()}})
    } else {
      // 提示错误信息
      console.error('res error:',error)
    }
    // 返回执行错误，让当前的执行链跳出来
    return Promise.reject(error)
});
 
 
export default instance