<template>
    <p class="text-muted text-center mb-0 pt-3" >
        <!-- <a  class="" :class="textColor">{{`Copyright  ©  2023  ${info.corporate_name}  版权所有`}}</a><a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank" :class="textColor" class="pl-3 d-block d-md-inline">{{info.filing_info}}</a> -->
        <a  class="" :class="textColor">{{`版权所有 @ 2008-2024 1nc.`}}</a><a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank" :class="textColor" class="pl-3 d-block d-md-inline">{{info.filing_info}}</a>
        <!-- 版权所有 @ 2008-2023 1nc.  -->
    </p>
  </template>
  
  <script>
//   import qrCode from '@/assets/qrCode.png'
 
import store from '@/store'
  export default {
    name: 'copyright',
    props: {
       textColor(){
          let textColor="text-reset" 
          return textColor
       }
    },
    mounted(){
        // this.getFriendLinks()  
    },
    computed:{
        info:function(){
            let info = null
            info = this.$store.getters.getWebConfig
            return info

        },
       
    },
    data() {
      return {
      }
    },
      methods: {
        
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  </style>
  