<template>
    <b-container fluid class="p-0  web-header-bar shadow-sm">
        <b-container class="d-flex justify-content-center">
            <!-- pl-3 pr-3  -->
            <b-navbar toggleable="md" type="light" class="w-100" > 
                <!-- -->
                <!-- <b-navbar-brand href="/" class="d-none d-sm-block" v-if="Array.isArray(logos) && logos.length > 0 && logos[0]">
                    <b-img left  v-if="logos.length > 0" :src="logos[0].url" fluid class="logo" alt="logo" height="40"></b-img>
                </b-navbar-brand> -->
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav class="justify-content-center w-100" >
                    <b-navbar-nav fill class="w-100">
                        <b-nav-item   v-for="(nav,index) in navsDatas" :active="activeNav.key == nav.key" :key="index" @click="onClickNavItem(nav)">{{nav.text}}</b-nav-item>
                        
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </b-container>
    </b-container>
    
    
  </template>
  
  <script>
  
import store from '@/store'
  export default {
    name: 'navbar',
    props: {
        navs: {
            type:Array,
            default(){
                return [
                    // {
                    //     text:"首页",
                    //     href:'/'
                    // },{
                    //     text:"关于学会",
                    //     href:'/'
                    // },{
                    //     text:"学会资讯",
                    //     href:''
                    // },{
                    //     text:"继续教育",
                    //     href:''
                    // },{
                    //     text:"健康中国行动",
                    //     href:''
                    // },{
                    //     text:"全民营养周",
                    //     href:''
                    // },{
                    //     text:"科普宣传",
                    //     href:''
                    // },{
                    //     text:"教育培训",
                    //     href:''
                    // },{
                    //     text:"会员注册",
                    //     href:''
                    // },{
                    //     text:"理事单位",
                    //     href:''
                    // },{
                    //     text:"资料下载",
                    //     href:''
                    // }
                ]
            }
        }
        
    },

    data() {
      return {
        active:null
        
      }
    },
    mounted(){
        
    },
    computed:{
        navsConfig(){
            let config  = this.webConfig.navs || {
                navbarShowChildren:false
            }
            return config
        },
        navsDatas(){
            let datas = []
            datas = store.getters.getNavs
            if(!this.navsConfig.navbarShowChildren){
                datas = datas.filter(item => !item.parent_no)
            }
            return datas
        },
        activeNav(){
            let active = null
            active = store.getters.getActiveNav
            return active
        },
        webConfigStore(){
            return store.getters.getWebConfig || ''
        },
        logos(){
            let logos = []
            if(this.webConfigStore && this.webConfigStore['_logos'] &&  this.webConfigStore['_logos'].length > 0){
                logos = this.webConfigStore['_logos'].map(item => item)
                // console.log('logo',logos)
            }
            return logos
        },
    },
    methods:{
        onClickNavItem(e,parent){
            let self = this
            console.log(e)
            let path = e.href
            let nav = e
             
            store.commit('setActiveNav',nav)   
            let navs = store.getters.getNavs
            navs = this.getTree(navs,'key','parent_no',e.key)
            if(navs.length == 1){
                navs = navs[0]
            }
            console.log(navs)
            if(path !== '#' && path){
                let active = navs.children.length > 0 ? navs.children[0] : e

                // this.toHomePage(active,e.key)
                this.toHomePage(e,e.key)

                // path = path == '/' ? path : `/${path}/${nav.key}/${active.key}/list/${active.key}`
                // path = path == 'index' ? path : `${path}`
                
                // console.log(path)
                // self.$router.push(path,(sce => {
                //     console.log('跳转异常：',sce)
                // }),(err => {
                //     console.log('跳转异常：',err)
                // }))
            }else{
                let nonePath = `/none?timer=${new Date().getTime()}`
                self.$router.push(nonePath,(sce => {
                     console.log('跳转异常：',sce)
                }),(err => {
                    console.log('跳转异常：',err)
                }))
            }
            
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style >

  </style>
  