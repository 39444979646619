<template>
    <b-container fluid class="p-0 web-header d-flex flex-column" :class="showHanderBgImg ? '' : 'bg-white'" :style=" showHanderBgImg ? `background-image:url(${handerBgImg});background-size: 100% 100%;background-repeat: no-repeat;height:${handerHeight};justify-content:center;` : `height:${handerHeight};justify-content:center;`">
        <b-container class="" style="">
            <b-navbar toggleable="lg" >
                <!-- type="white" variant="white" -->
                <b-navbar-brand href="/" class="d-none d-sm-block">
                    <b-img left  v-if="logos.length > 0" :src="logos[0].url" fluid class="logo" alt="logo"></b-img>
                </b-navbar-brand>
                
                <b-navbar-brand class="d-none d-sm-block" v-if="logos.length > 1 || (loadConfig && loadConfig.ws_name)" style="width:8rem;">
                    <b-img left v-if="logos.length > 1" :src="logos[1].url" fluid-grow class="logo" alt="logo" style="height: auto;"></b-img>
                    <div v-if="loadConfig && loadConfig.ws_name" class="logo" style="height: auto;">
                        <div class="web-name">{{loadConfig.ws_name}}
                        </div>
                        <div class="text-listTitleColor" v-if="loadConfig && loadConfig.en_name">{{loadConfig.en_name}}
                        </div>
                    </div>
                </b-navbar-brand>
                
                <b-navbar-nav class="ml-md-auto d-sm-flex flex-md-row flex-sm-column justify-content-between justify-content-md-end" v-if="login.show || search.show || !login.show">
                    <b-nav-item right v-if="search.show">
                        <b-nav-form>
                            <b-form-input size="sm" class="" style="width: auto;" placeholder="搜索" v-model="searchVal"></b-form-input>
                            <b-button size="sm" class="my-2 my-sm-0" variant="primary" icon="bi bi-search" :to="`/search?str=${searchVal}`">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg></b-button>
                        </b-nav-form>
                    </b-nav-item>
                    <b-nav-item right v-if="login.show">
                        <b-nav-form>
                            
                            <b-button size="sm" class="my-2 my-sm-0"  type="submit" variant="orange" to="/">跳转</b-button>
                            <template  >
                                <span @click="toMember()" class="mr-1" v-if="loginInfo.isLogin && loginInfo.info">
                                    欢迎：[{{ loginInfo.isLogin && loginInfo.info ? loginInfo.info.real_name  : '未登录'}}]
                                </span>
                                <b-dropdown v-if="loginInfo.isLogin && loginInfo.info" right   id="user-set" text="会员中心" class="p-0" size="sm" variant="outline-orange">
                                    <b-dropdown-item @click="toMember()">会员中心</b-dropdown-item>
                                    <b-dropdown-item to="/resetpwd">修改密码</b-dropdown-item>
                                    <b-dropdown-item @click="showModal">退出登录</b-dropdown-item>
                                </b-dropdown>
                                <!-- <b-button size="sm" class="my-2 my-sm-0" v-if="loginInfo.isLogin && loginInfo.info"  variant="outline-orange" @click="showModal">退出</b-button> -->
                            </template>
                        </b-nav-form>
                    </b-nav-item>
                    <b-nav-item right v-if="links && Array.isArray(links) && links.length > 0" class="web-hander-link">
                        <b-nav-form>
                            
                            <b-button  class="my-2 my-sm-0 mr-3 "  type="submit" variant="web-style-lighten" @click="onLink(link)" v-for="(link,index) in links">
                                
                                <b-img v-if="index == 0" v-bind="{ width: 22, height: 22 }" :src="handerLinkIcon.left" rounded alt="Rounded image"></b-img>
                                <b-img v-if="index == 1" v-bind="{ width: 22, height: 22 }" :src="handerLinkIcon.right" rounded alt="Rounded image"></b-img>
                                <span class="text-listTitleColor pl-2 font-weight-bold">{{link.text}}</span>
                            </b-button>
                            
                        </b-nav-form>
                    </b-nav-item>
                </b-navbar-nav>
                
              
            </b-navbar>
            <!-- <b-modal ref="logout" v-model="modalShow " title="退出登录" id="logout" centered button-size="sm">
                <div class="d-block text-center">
                    <h3>您确定退出当前账号登录!</h3>
                </div>
                <template v-slot:modal-footer="{ ok, cancel, hide }">
                    <b-button size="sm"  @click="hideModal()">
                        取消
                    </b-button>
                    <b-button size="sm" variant="primary" @click="goLogout()">
                        确定
                    </b-button>
                </template>
            </b-modal> -->
        </b-container>
        
    </b-container>
  </template>
  
  <script>
//   import zhuanxian from '@/assets/zhuanxian.png'
  import zhuanxian from '@/assets/handerbg.jpg'
  import llink from '@/assets/index/llink.png'
  import rlink from '@/assets/index/rlink.png'
import store from '@/store'
  import { loginOut } from '@/api/api.js'
  export default {
    name: 'hander',
    components: {
        
    },
    props: {
        
    },
    mounted(){
        this.getLogos()
    },
    computed:{
        showHanderBgImg(){
            let show = this.webConfig.hander.showBgImg || false

            return show
        },
        handerHeight(){
            let height = this.webConfig.hander.height || '80px'

            return height
        },
        loginInfo(){
             let state = {
                isLogin:false,
                info:null
             }
             if(store.getters.getLoginInfo){
                state = {
                    isLogin:store.getters.getLoginState,
                    info:store.getters.getLoginInfo,
                }
             }
             

             return state
        },
        login(){
            let config  = this.webConfig.hander.login || {show:false,text:'登录'}
            config.show = store.getters.getOnLogin
            return config
        },
        links(){
            let handerLinks  =  null
            handerLinks  =  this.webConfig.hander.links
            
            return handerLinks
        },
        search(){
            let config  = this.webConfig.hander.search || {
                text:'搜索',
                icon:"",
                show:true
            }
            config.show = store.getters.getOnSearch
            return config
        },
        loadConfig(){
            let config  = store.getters.getWebConfig 
            
            return config
        },
        
        
    },
    data() {
      return {
        handerBgImg:zhuanxian,
        searchVal:'',
        logos:[],
        modalShow:false,
        handerLinkIcon:{
            left:llink,
            right:rlink
        }
      }
    },
      methods: {
        onLink(link){
            if(link.href?.indexOf('http') > -1){
                window.open(link.href,link.target)
            }else {
                this.$router.push({path:link.href})
            }
        },
        toLogin(path){
            this.$router.push({path:path})
        },
        toMember(){
            this.toMemberCenter()
        },
        getLogos(){
            let info = store.getters.getWebConfig
            let logos = []
            if(info.logo){
                // logos = 
                this.getFileUrls(info.logo).then(res=>{
                    // console.log('get logos ',res)
                    if(Array.isArray(res)){
                        logos = res.map(item => {
                            let obj ={
                                url:`${store.getters.hostPath}/file/download?filePath=${item.fileurl}`,
                                '_data':item
                            }
                            return obj

                        })
                        this.logos = logos.map(item => item)
                    }
                    
                })
                // console.log('logo',logos)
            }
        },
        showModal(){
            // this.$bvModal.show('logout')
            let self = this
            console.log(this)
            this.$bvModal.msgBoxConfirm('确认退出当前登录.', {
                title: '请确认',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: '退出',
                cancelTitle: '取消',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
          .then(value => {
            console.log(value)
            if(value == true){
                loginOut().then(res => {
                    console.log(res)
                    if(res.state =='SUCCESS'){

                        store.dispatch('closelogin')
                        if(self.$route.fullPath !== '/'){
                            
                            self.$router.replace({path:'/' })
                        }
                    }
                })
            }
          })
          .catch(err => {
            // An error occurred
          })
        },
        hideModal(){
            this.$bvModal.hide('logout')
            // this.$refs['logout'].hide()
        },
        goLogout(){
            store.dispatch('closelogin')
        }
    },
    watch:{
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>

  </style>
  