<template>
    <div class="block web-list" style="">
        <template  v-if="activeMode == 'list'">
            <b-list-group class=" rounded-0 row p-0 w-100 m-0" flush :style="    `flex-direction: ${loadLayoutColumn !== 12 ? 'row' : 'column'};`">
                <!-- <b-row class="mb-12"> -->
                        <!-- <b-col>
                             <listLayout 
                             class="rounded"
                            :layoutNo="`LM20231020090002`" 
                            :showTitle="true"
                            :rowspan="4" 
                            :initList="initList"
                            :listLayout="'textCard'"
                            :more="false"
                            :layoutColumn="6"
                            :colsMaps="{title:'title',subTitle:'summary',date:'create_time'}"
                            ></listLayout>
                        </b-col> -->
                <b-list-group-item  class="d-flex align-items-start rounded-0 p-0 pt-1 pb-1"  :class="`col-${loadLayoutColumn} border${['textNoWarp','textNoWarpAndPoint','textCard','imgCard','imgMixText','imgCardTopic'].includes(layout) ? '-0' : ''}`" v-for="(item,index) in listData"  v-if="listData.length > 0" @click="onListItem(item,index)">
                    <template v-if="['imgMixText','detaiCardTopic'].includes(layout)">
                        <div class="w-25 pr-2" v-if="showImg && item[colsMaps.img]">
                            <b-img left class="bg-gray rounded"  :src="`${config.protocol}://${config.host}:${config.port}/file/download?fileNo=${item[colsMaps.img]}`" fluid-grow alt=""></b-img>
                        </div>
                        <div class="flex-fill w-75 p-2">
                            <div class="d-flex w-100 justify-content-between mb-2">
                                <h5 class="mb-1 text-left text-dark font-weight-bold">
                                    <b-badge variant="warning" v-if="item.attribute && item.attribute.indexOf('置顶') !== -1"><b-icon-capslock-fill></b-icon-capslock-fill>Top</b-badge>
                                    <b-badge variant="success" class="ml-1" v-if="item.attribute && item.attribute.indexOf('推荐') !== -1"><b-icon-hand-thumbs-up></b-icon-hand-thumbs-up>推荐</b-badge>
                                    
                                {{item[colsMaps.title]}}</h5>
                            </div>
                            <div class="d-flex w-100 text-secondary justify-content-start mb-2">
                               <span>来源:{{item.source ? item.source : '--'}}</span> <span class="ml-3"> 发布日期:{{ new Date(item[colsMaps.date]).toLocaleDateString() }}  </span>  
                            </div>
                            <p class="mb-1 text-left text-wrap text-secondary  text-truncate" style="display: block;line-height:1.2rem;max-height:2.4rem;overflow: hidden;">
                                {{item[colsMaps.subTitle]}}
                            </p>
                            <div class="d-flex w-100 justify-content-between">
                                <div class="text-dark font-weight-bold">
                                    <!-- {{ new Date(item[colsMaps.date]).toLocaleDateString() }} -->
                                </div>
                                <div class="text-web-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
        <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
        </svg>
                                </div>
                            </div>
                        </div>
                    </template>
                    
                    <template v-if="layout == 'textNoWarp'">
                        <div class="flex-fill w-100">
                            <div class="d-flex d-flex-row w-100 justify-content-between pt-2 pb-2 pl-1 pr-1">
                                <div class="flex-fill text-nowrap text-truncate inline-block text-left text-dark font-weight-bold">
                                    <b-badge variant="warning" v-if="item.attribute && item.attribute.indexOf('置顶') !== -1"><b-icon-capslock-fill></b-icon-capslock-fill>Top</b-badge>
                                    <b-badge variant="success" class="ml-1" v-if="item.attribute && item.attribute.indexOf('推荐') !== -1"><b-icon-hand-thumbs-up></b-icon-hand-thumbs-up>推荐</b-badge>
                                    
                                    {{item[colsMaps.title]}}
                                </div>
                                <div class="text-right text-lightgray  font-weight-bold" style="width: 6rem;">
                                    {{ new Date(item[colsMaps.date]).toLocaleDateString() }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="layout == 'textNoWarpAndPoint'">
                        <div class="flex-fill w-100">
                            
                            <div class="d-flex d-flex-row w-100 justify-content-between pt-2 pb-2 pl-1 pr-1">
                                <div class="p-1" style="line-height:1rem;">
                                    <b-icon-dot></b-icon-dot>
                                </div>
                                
                                <div class="flex-fill text-nowrap text-truncate inline-block text-left text-dark font-weight-bold">
                                    <b-badge variant="warning" v-if="item.attribute && item.attribute.indexOf('置顶') !== -1"><b-icon-capslock-fill></b-icon-capslock-fill>Top</b-badge>
                                    <b-badge variant="success" class="ml-1" v-if="item.attribute && item.attribute.indexOf('推荐') !== -1"><b-icon-hand-thumbs-up></b-icon-hand-thumbs-up>推荐</b-badge>
                                    
                                    {{item[colsMaps.title]}}
                                </div>
                                <div class="text-right text-lightgray  font-weight-bold" style="width: 6rem;">
                                    {{ new Date(item[colsMaps.date]).toLocaleDateString() }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="layout == 'download'">
                        <div class="flex-fill w-100">
                            <div class="d-flex d-flex-row w-100 justify-content-between pt-2 pb-2 pl-1 pr-1">
                                <div class="flex-fill text-nowrap text-truncate inline-block text-left text-dark font-weight-bold">
                                   [附件] {{item[colsMaps.title]}}
                                </div>
                                <div class="text-right text-lightgray  font-weight-bold" style="width: 6rem;">
                                    {{ new Date(item[colsMaps.date]).toLocaleDateString() }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="layout == 'textAndNo'">
                        <div class="flex-fill w-100">
                            <div class="d-flex d-flex-row w-100 justify-content-between pt-2 pb-2 pl-1 pr-1">
                                <div class="flex-fill text-nowrap text-truncate inline-block text-left text-dark font-weight-bold">
                                    <b-badge  variant="primary">{{index + 1}}</b-badge>
                                    <b-badge variant="warning" v-if="item.attribute && item.attribute.indexOf('置顶') !== -1"><b-icon-capslock-fill></b-icon-capslock-fill>Top</b-badge>
                                    <b-badge variant="success" class="ml-1" v-if="item.attribute && item.attribute.indexOf('推荐') !== -1"><b-icon-hand-thumbs-up></b-icon-hand-thumbs-up>推荐</b-badge>
                                    
                                    {{item[colsMaps.title]}}
                                </div>
                                <div class="text-right text-lightgray  font-weight-bold" style="width: 6rem;">
                                    {{ new Date(item[colsMaps.date]).toLocaleDateString() }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="layout == 'textCard'">
                        <div class="mb-6 d-flex d-flex-row rounded justify-content-between pt-2 pb-2  w-100 web-text-card" :class="`${['textCard'].includes(layout) ? 'shadow ' : ''} ${((index + 1) % (12/loadLayoutColumn)) == 1  ? 'ml-0 mt-2 mb-2 mr-2' : ''} ${((index + 1) % (12/loadLayoutColumn))== 0  ? 'mr-0 mt-2 mb-2 ml-2' : ''} ${((index + 1) % (12/loadLayoutColumn))!== 0 && ((index + 1) % (12/loadLayoutColumn)) !== 1  ? 'm-2' : ''}`">
                                <div style="width: 4rem;" class="text-dark font-weight-bold d-flex flex-column justify-content-center border-right">
                                    <div class="">
                                        {{ new Date(item[colsMaps.date]).getFullYear() }}
                                    </div>
                                    <div>
                                        {{ (new Date(item[colsMaps.date]).getMonth() + 1 ) + '-' + new Date(item[colsMaps.date]).getDate() }}
                                    </div>
                                </div>
                                <div class="text-left text-dark d-flex flex-column pl-3" style="width:calc(100% - 4rem);">
                                    <div class="flex-fill text-truncate text-left  font-weight-bold text-card-title">
                                        {{item[colsMaps.title]}}
                                    </div>
                                    <div class="text-left text-secondary" style="height:3rem;min-height:3rem;word-break: break-all;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2; overflow:hidden;">
                                        {{ item[colsMaps.subTitle]}}
                                    </div>
                                </div>
                        </div>
                    </template>
                    <template v-if="layout == 'imgCard'">
                        <div class="mb-6 d-flex d-flex-row rounded justify-content-between w-100" :class="`${['imgCard'].includes(layout) ? 'shadow ' : ''} ${((index + 1) % (12/loadLayoutColumn)) == 1  ? 'ml-0 mt-2 mb-2 mr-2' : ''} ${((index + 1) % (12/loadLayoutColumn))== 0  ? 'mr-0 mt-2 mb-2 ml-2' : ''} ${((index + 1) % (12/loadLayoutColumn))!== 0 && ((index + 1) % (12/loadLayoutColumn)) !== 1  ? 'm-2' : ''}`">

                            <b-card class="web-img-card">
                                <div class="card-img-seal">
                                    <b-card-img class="border-0 w-100" img-height="220px" img-width="100%" :src="`${config.protocol}://${config.host}:${config.port}/file/download?fileNo=${item[colsMaps.img]}`" alt="Image" top></b-card-img>    
                                </div>
                                 <b-card-text class="text-left text-dark font-weight-bold d-flex align-items-center" style="">
                                    <b-badge pill  variant="web-primary" class="mr-1">{{item[colsMaps.tags]}}</b-badge>
                                    <span class="text-truncate">{{item[colsMaps.title]}}</span>
                                </b-card-text>
                            </b-card>
                        </div>
                    </template>
                    <template v-if="layout == 'imgCardTopic'">
                        <div class="mb-6 d-flex d-flex-row rounded justify-content-between w-100" :class="`${['imgCardTopic'].includes(layout) ? 'shadow ' : ''} ${((index + 1) % (12/loadLayoutColumn)) == 1  ? 'm-2' : ''} ${((index + 1) % (12/loadLayoutColumn))== 0  ? 'm-2' : ''} ${((index + 1) % (12/loadLayoutColumn))!== 0 && ((index + 1) % (12/loadLayoutColumn)) !== 1  ? 'm-2' : ''}`">

                            <b-card  class="web-img-card-photo" >
                                <div class="card-img-photo">
                                    <b-card-img class="border-0" fluid  img-height="140px" img-width="auto" :src="`${config.protocol}://${config.host}:${config.port}/file/download?fileNo=${item[colsMaps.img]}`" alt="Image" top></b-card-img>    
                                </div> 
                                <b-card-text class="text-center text-dark font-weight-bold m-0" style="">
                                    
                                    {{item[colsMaps.title]}}
                                </b-card-text>
                                <b-card-text class="web-img-card-photo-source">
                                    <small>{{item[colsMaps.source]}}</small>
                                    
                                </b-card-text>
                            </b-card>
                              
                        </div>
                    </template>
                </b-list-group-item>
                
            <!-- </b-row> -->
                <b-list-group-item    class="w-100 d-flex p-0 align-items-start rounded-0 pt-1 pb-1" :class="{'border-0': layout == 'textNoWarp'}" v-if="listData.length == 0">
                    
                        <none :title="noneText"></none>
                </b-list-group-item>
            </b-list-group>
            <div class="d-flex flex-column justify-content-center  align-items-center" v-if="pagination && listData.length > 0">
                <b-pagination
                    class="mt-3 mb-3"
                    variant="danger"
                    v-model="currentPage"
                    :total-rows="total"
                    :per-page="loadPageRow"
                    first-number
                ></b-pagination>
            </div>
        </template>
        <template  v-if="activeMode == 'detail'">
            <detail :initData="activeData" :colsMaps="{
                    title:'title',
                    date:'create_time',
                    subTitle:'subTitle',
                    hot:true,
                    img:'',
                    content:'content',
                    user:'author_nickname',
                    sign:'source'
                }"></detail>
                <!-- <div class="d-flex justify-content-between p-2 text-primary">
                    <div @click="toList">
                        <b-icon-text-indent-right></b-icon-text-indent-right>返回列表
                    </div>
                    <div v-if="(activeIndex + 1)  < listData.length" class="d-flex" @click="nextItem">
                        下一篇《<div style="max-width: 18rem;" class="text-nowrap text-truncate">
                            {{listData[activeIndex + 1][colsMaps['title']]}}
                        </div>》
                        
                    </div>
                </div> -->
        </template>
        
    </div>
    
        
    
  </template>
  
  <script>
  
import store from '@/store'

import banner from '@/assets/banner1.png'

import none from '@/components/none.vue'
import detail from '@/components/detail.vue'
import { getContent } from '@/api/api.js'
  export default {
    name: 'list',
    components:{none,detail},
    props: {
        activeNav:{
            type:Object,
            default(){
                return null
            }
        },
        showImg:{
            type:Boolean,
            default: true
        },
        pagination:{
            type:Boolean,
            default: true
        },
        layoutNo:{
            type:String,
            default: ''
        },
        cols:{
            type:Array,
            default(){
                return ['*']
            }
        },
        initLayout:{
            type:String,
            default: 'imgMixText'  // imgMixText 左图右文  textMixImg  左文右图  textNoWarp 单行文字 textLayout    纯文字 // imgCard textCard
        },
        rowspan:{
            type:[String,Number],
            default: 6  // 跨行
        },
        layoutColumn:{
            type:[String,Number],
            default(){
                let rowSpan = 12
                if(this.loadLayout == 'imgCard'){
                    rowSpan = 4
                }
                return rowSpan
            }  // 栅格
        },
        colsMaps:{
            type:Object,
            default(){
                return {title:'title',subTitle:'summary',img:'thn_img', date:'create_time',tags:'tags',source:'source'}
            }
        },
        showDetail:{
            type:Boolean,
            default: false
        },
        dataAllChildren:{
            type:Boolean,
            default: true
        },
        initRequest:{
            type:Object,
            default(){
                return null
            }
        },
        noneText:{
            type:String,
            default: '暂无内容,敬请关注'
        },
        ruleType:{
            type:Object,
            default(){
                return {
                    colName:'category_no',
                    ruleType:'eq'
                }
            }
        }
    },
    computed:{
        loadLayoutColumn(){
            let rowSpan = this.layoutColumn
            switch (this.loadLayout) {
                case 'imgCard':
                    rowSpan = 4
                    break;
                case 'imgCardTopic':
                    
                    rowSpan = 3
                    break;
            
                default:
                    break;
            }
            
            return rowSpan
        },
        layout(){
            let layout = ''
            
            if(this.initLayout){
                layout = this.initLayout
            }
            if(this.loadLayout){
                layout = this.loadLayout
            }
            return layout
        },
        config(){
            let config = this.webConfig
            return config
        },
        
        items(){
            let items = []
            let paths = this.paths
            if(Array.isArray(paths)){
                items = paths.map(item => item)
            }
            return items
        },
        style(){
            let style = {'width':'220px'}
            let width = this.width
            if(width){
                style['width'] = `${width}px`
            }
            return style
        },
        no(){
             let no = this.layoutNo
             if(!no && this.routeParams && this.routeParams.activeNo){
                no = this.routeParams.activeNo
             }

             return no

        },
        rootNavbar(){
            let bar = null

            bar = store.getters.getActiveNav
            return bar
        },
        buildApp(){
            let srvApp = this.$route.query.srvApp || null
            
            return srvApp
        },
        menuMode(){
            let mode = 'route'
            if(this.$route.query && this.$route.query.menuMode){
                mode = this.$route.query.menuMode
            }
            return mode
        },
        buildRequest(){
            let propsReq = this.initRequest

            let serviceName = propsReq && propsReq.serviceName ?  propsReq.serviceName : "srvdaq_pc_website_content_select"


            let req = {
                    "serviceName": serviceName,
                    "colNames": this.cols,
                    "condition": [{
                        colName: this.ruleType.colName || 'category_no',
                        // colName:'category_no',
                        ruleType:this.ruleType.ruleType || 'like',
                        value:`${this.no}`
                    }],
                    
                }
                

                if(this.menuMode == 'groupTabs' && propsReq.condition){
                    req.condition = propsReq.condition
                }
                // 如果选择的是一级栏目
                if(this.no && this.rootNavbar && this.no == this.rootNavbar.key){
                    req.condition[0].colName = 'category_no_path'
                    req.condition[0].ruleType = 'like'
                }
            return req
        },
        loadPageRow(){
            let row = this.rowspan
            switch (this.loadLayout) {
                case 'imgCard':
                    // this.
                    row = 6
                    break;
                case 'imgCardTopic':
                    // this.
                    row = 8
                    break;
                    
                default:
                    break;
            }

            return row
        }

    },
    mounted(){
        if(this.$route.params.layout){
            this.loadLayout = this.$route.params.layout
        }
        if(this.layoutNo){
            this.getListData()
        }else if(this.$route.params.no){
            this.$set(this,'routeParams',this.$route.params)
            this.getListData()
        }else if(this.initRequest){
            this.getListData()
        }
        
        
        
    },
    data() {
      return {
        routeParams:null,
        listData:[],
        rows: 100,
        perPage: this.loadPageRow,
        currentPage: 1,
        total:0,
        activeMode:'list',
        activeData:null,
        activeIndex:null,
        loadLayout:''
      }
    },
      methods: {
        nextItem(){
            let index = this.activeIndex + 1
            let item = this.listData[index]
            if(this.showDetail){
                this.$set(this,'activeMode','detail')
                this.$set(this,'activeData',item)
                this.$set(this,'activeIndex',index)
                this.rootScrollToTop()
            }
            
            console.log(item)
            this.$emit('on-item-change',item)
        },
        toList(){
            if(this.showDetail){
                this.$set(this,'activeMode','list')
                this.$set(this,'activeData',null)
                this.$set(this,'activeIndex',null)
                this.rootScrollToTop()
            } 
        },
        onListItem(e,index){
            // if(this.showDetail){
            //     this.$set(this,'activeMode','detail')
            //     this.$set(this,'activeData',e)
            //     this.$set(this,'activeIndex',index)
            // }
            let self = this

            console.log('route 点击行',self.$route.params.activeNo)
            
            let path = this.rootNavbar.href 
            let layoutType = 'none'
            
            let root = this.rootNavbar.key 
            let active = self.$route.params.activeNo
            
            if(this.rootNavbar && this.rootNavbar.level !== 1){
                root = this.rootNavbar.path.split('/').filter(item => item)[0]
                if(root){
                    let loadRootNavBar = this.getNavBarData(root)
                    path = loadRootNavBar.href
                    store.commit('setActiveNav',loadRootNavBar) 
                }
                
            }else{
                store.commit('setActiveNav',this.rootNavbar) 
            }
           
            if(path == '/'){
                let to =  this.getNavBarData(this.no)
                path = to.href
                root = to.key 
                store.commit('setActiveNav',to) 
            }
            if(path && path.indexOf('?') !== -1){
                // 处理模板参数
                let pathParams = path.split('?')[1]
                path = path.split('?')[0]
                layoutType = pathParams.split('=')[1]
            }
            if(path && path.indexOf(':') !== -1){
                path = path.split(':')[0]
            }
            if(!path){
                let onItem = e
                path = onItem.category_no_path
                if(path){
                    path = path.split('/')
                    path = path.filter(item => item)
                    if(Array.isArray(path) && path.length > 1){
                        root = path[0]
                        path = 'homePage'

                        let navs = store.getters.getNavs
                        navs = this.getTree(navs,'key','parent_no',root)
                        if(navs.length == 1){
                            navs = navs[0]
                            store.commit('setActiveNav',navs)   
                        }
                    }
                }
            }
            this.$router.push(`/${path}/${root}/${e.category_no}/detail/${e.id}/${layoutType}`)
            // this.$emit('on-item-change',e)
        },
        getListData(){


            if(this.no){
                let req = this.buildRequest
                let relation_Conditions={
                    "relation": "OR",
                    "data": []
                }
                req['page'] =  {
                    "pageNo": this.currentPage,
                    "rownumber": this.loadPageRow
                }
                req['order'] =   [
                {
                        "colName": "attribute",
                        "orderType": "desc"
                    },
                    
                    {
                        "colName": "release_time",
                        "orderType": "desc"
                    },
                    {
                        "colName": "create_time",
                        "orderType": "desc"
                    }
                ]
                if(this.currentPage == 1){
                    let relations = [{
                        "relation": "OR",
                        "data": [{
                            "colName":"attribute",
                            "ruleType":"in",
                            "value":"置顶"
                        }]
                    },{
                        "relation": "OR",
                        "data": [{
                            "colName":"title",
                            "ruleType":"like",
                            "value":""
                        }]
                    }]
                    relation_Conditions.data = relations
                    // req.relation_condition = relation_Conditions 
                }
                
                if(!this.layoutNo && this.initRequest && this.menuMode !== 'groupTabs'){
                    req = this.initRequest
                }
                console.log('')
                getContent(req,this.buildApp).then((res) =>{
                     if(Array.isArray(res.data)){
                        // console.log('get data',res)
                        this.$set(this,'total',res.page.total)
                        this.listData = res.data.map(item => {
                            // 如果有人工设置时间 优先使用
                            item.create_time = item.release_time ? item.release_time : item.create_time
                            return item
                        })
                        if(Array.isArray(this.listData) && this.listData.length == 1 && this.showDetail || ['detaiCardTopic'].includes(this.loadLayout)){
                            this.$set(this,'activeData',this.listData[0])
                            this.$set(this,'activeMode','detail')
                        }
                     }
                })
                
            }else{
                console.log('使用初始化 条件')
                let req = null
                if(!this.layoutNo && this.initRequest){
                    req = this.initRequest
                    getContent(req).then((res) =>{
                        if(Array.isArray(res.data)){
                            // console.log('get data',res)
                            this.$set(this,'total',res.page.total)
                            this.listData = res.data.map(item => item)
                        }
                    })
                }
                
            }
        }
    },
    watch:{
        "currentPage":{
            deep:true,
            handler:function(nval,oval){
                this.getListData()
            }
        },
        "layoutNo":{
            deep:true,
            handler:function(nval,oval){
                if(nval !== oval){
                    this.$set(this,'currentPage',1)
                    this.toList()
                    this.getListData()
                }
                
            }
        },
        "initRequest":{
            deep:true,
            handler:function(nval,oval){
                if(nval !== oval){
                    this.$set(this,'currentPage',1)
                    this.getListData()
                }
                
            }
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
//   0 1rem 3rem rgba(0,0,0,.175)!important
.web-text-card{
    transition: transform 0.5s;
    &:hover{
        // 标题悬停的样式
        transform: scale(1.03);
        box-shadow:0 1rem 3rem rgba(0,0,0,.175)!important;
        .text-card-title {
        /* 这里可以设置样式 */
            color:#1787f7 !important
        }

    }
    & .text-card-title {
    /* 这里可以设置样式 */
        transition: transform 0.5s;
        color:#343a40 !important;
        
        
    }
}
                                    
.web-img-card-photo{
    .card-body{
        padding:0rem;
    }
    .card-img-photo{
        border-radius:4px;
        // max-height:5rem;
        overflow:hidden;
        // width:auto;
        margin-bottom:0.4rem;
        img{
            width:100%!important;
            height:auto;
            // max-height:10rem;
        }
        
    }
    .web-img-card-photo-source{
            min-height:3rem;
        }
}
  .web-img-card{
    width:100%;
    .card-body{
        padding:0.2rem;
        .card-img-seal{
            border-radius:4px;
            max-height:220px;
            overflow:hidden;
            margin-bottom:0.4rem;
            img{
                height: 220px;
                transition: transform 0.5s;
                &:hover {
                    // 图片悬停的样式
                    transform: scale(1.2);
                    max-height:220px;
                    overflow:hidden !important;
                }
            }
        }
        &>.card-text{
            padding:0.5rem;
        }
    }
  }
  
.card-img{
  
}

  </style>
  