<template>
    <b-row class="m-0 h-100">
        <!-- <b-col class="p-5  h-100 text-left d-md-flex flex-column justify-content-between overflow-hidden  d-none d-md-block">
            <div>
                <p class="text-left h4  p-1 text-blod text-primary" >{{login.layoutTitle}}</p>
                <p class="text-left h6  p-1 " >{{login.layoutMsg}}</p>
            </div>
            <div>
                <b-img left src="https://file.moyublog.com/d/file/2021-11-23/lnesybrj3mm.jpg" fluid-grow  alt=""></b-img>
            </div>
            
        </b-col> -->
        
        <b-col class="p-5  h-100 text-left d-flex flex-column justify-content-between">
            

            <b-form @submit="onSubmit" @reset="onReset" novalidate v-if="show">

                <!-- <b-form-group :id="`${field.column}-${field.type}`" :label="field.label" :label-for="`${field.column}-${field.type}`" v-for="(field,index) in fields">
                    <b-form-input
                    :id="`${field.column}-${field.type}`"
                    v-model="field.value"
                    :type="field.type"
                    required
                    autocomplete="off"
                    :placeholder="field.placeholder"
                    :readonly="readonlyInput"
                    @change="change(field)"
                    
                    :state="field.state"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="field.state">
                        {{field.msg}}
                    </b-form-invalid-feedback>
                </b-form-group> -->
                <template v-for="(field,index) in formFields" >
                    <b-form-group :id="`${field.column}-${field.type}`" 
                    :label="field.label" 
                    :label-for="`${field.column}`" 
                    v-if="field.show && field.type !== 'radio'"
                    label-size="md"  
                    label-align="right" 
                    label-cols-lg="3"
                    label-cols="4">
                        <b-input-group v-if="field.type== 'code'">
                            <b-form-input
                            :id="`${field.column}`"
                            v-model="field.value"
                            required
                            autocomplete="off"
                            :placeholder="field.placeholder"
                            :readonly="field.readonly || readonlyInput"
                            @change="change(field)"
                            :state="field.state"
                            placeholder="验证码"
                            description="验证码"
                            ></b-form-input>
                                <b-button  variant="outline-primary" :disabled="codeState" @click="getCode">{{codeState ? `剩余${codeTime}s` : `获取验证码`}}</b-button>
                        </b-input-group>
                        <upload 
                        :ref="field.column" 
                        v-if="field.type== 'image'"
                        :initColumn="field.column"
                        :initTable="'bxws_member_pay_record'"
                        :initNo="field.value"
                        :initMax="field.column == 'head_image' ? 1 : field.column == 'card_no' ? 2 : '-' "
                        :mainProps="field.column == 'card_no' ? {width: 148, height: 100} : {width: 100, height: 100} "
                        @change="field.value = $event"
                        ></upload>
                        <b-form-input
                            :id="`${field.column}`"
                            v-model="field.value"
                            v-if="field.type== 'text'"
                            :type="field.type"
                            required
                            autocomplete="off"
                            :placeholder="field.placeholder"
                            :readonly="field.readonly || readonlyInput"
                            @change="change(field)"
                            :state="field.state"
                            ></b-form-input>

                            <b-form-textarea
                            v-if="field.type == 'textarea'"
                            :id="`${field.column}`"
                            v-model="field.value"
                            :type="field.type"
                            required
                            autocomplete="off"
                            :placeholder="field.placeholder"
                            :readonly="readonlyInput"
                            @change="change(field)"
                            :state="field.state"
                            rows="3"
                            max-rows="6"
                            ></b-form-textarea>
                        <b-form-invalid-feedback :state="field.state">
                            {{field.msg}}
                        </b-form-invalid-feedback>
                        
                        
                    </b-form-group>
                    <b-form-group  :id="`${field.column}`" 
                    :label="field.label" 
                    :label-for="`${field.column}`"
                    v-if="field.show && field.type == 'radio'"
                    label-size="md"  
                    label-align="right" 
                    label-cols-lg="3"
                    @change="change(field)"
                    label-cols="3">
                    <b-form-radio-group
                        size="lg"
                        v-model="field.value"
                        :options="field.options"
                        :name="`${field.column}`"
                    >
                    <b-form-invalid-feedback :state="field.state">
                        {{field.msg}}
                    </b-form-invalid-feedback>
                
                    </b-form-radio-group>
                    
                    
                    </b-form-group>
                </template>
                <div class="text-secondary text-center">留下您的联系方式和需求，我们会与您。</div>
                <div class="p-2 d-flex flex-row mt-2 justify-content-center">
                    <!-- <b-button type="reset" variant="danger">取消</b-button> -->
                    <b-button type="submit" variant="primary">立即提交</b-button>
                </div>
            </b-form>
            
        </b-col>
    </b-row>
  </template>
  
  <script>
import store from '@/store'
import upload from '@/components/upload.vue'
  import { getV2,setRequest } from '@/api/api.js'
  import formMixin from '@/utils/form.js'
  export default {
    name: 'form-layout',
    mixins:[formMixin],
    props: {
        msg: String,
        formType:{
            type:String,
            default:'add'   // add  update detail
        },
        initType:{
            type:String,
            default:''   // person  group
        },
        initTitle:{
            type:String,
            default:''
        },
        initServiceName:{
            type:String,
            default:'srvgive_leave_word_add'
        },
        initApp:{
            type:String,
            default:'fyzhmd'
        },
        defaultValue:{
            type:[Object,Array],
            default(){
                return null
             
            }
        },

    },
    components:{upload},
    mounted(){
        // this.getFriendLinks() 
        if(this.useType && this.app && this.serviceName){
            this.getV2()
        }
        setTimeout(() => {
                    this.$set(this,'readonlyInput',false)

                }, 300);
        
    },
    computed:{
        info(){
            let state = {
                isLogin:store.getters.getLoginState,
                info:store.getters.getLoginInfo,
                memberInfo:store.getters.getMemberInfo,
                userInfo:store.getters.getLoginInfo,
                baseInfo:store.getters.getWebConfig,
             }

             return state
        },
        useType(){
            let type = ''
             if(this.formType){
                type = this.formType
             }

            return type
        },
        serviceName(){
            let srv = ''
            if(this.initServiceName){
                srv = this.initServiceName
            }
            return srv
        },
        app(){
            let app = ''
            
            if(this.initApp){
                app = this.initApp
            }
            return app
        },
        
        formFields(){
            let self = this
            let fields = []
            let activeOption = null
            let list = this.bxDeepClone(this.fields)
            if(Array.isArray(list)){
                fields = this.fields.map( item => {
                    switch (item.column) {
                        case 'user_no':
                            if(this.info.userInfo && this.initServiceName == 'srvws_member_pay_record_user_add'){
                                item.value = this.info.userInfo.user_no
                            }
                            break;
                        case 'phone_no':
                            if(this.info.memberInfo && this.initServiceName == 'srvws_member_pay_record_user_add'){
                                item.value = this.info.memberInfo.phone_no
                            }
                            break;
                        case 'package_no':
                        case 'duration':
                        case 'amount':
                            let srvData = item['_data']
                            if(item.column == 'package_no' && srvData.hasOwnProperty('option_active') && srvData.option_active){
                                activeOption = srvData.option_active
                                // item.value = activeOption
                                item.show = false
                                self.$set(self.fieldModel,'package_no',activeOption.no)
                                self.$set(self.fieldModel,'duration',activeOption.duration)
                                self.$set(self.fieldModel,'amount',activeOption.cost)
                                
                            }else if(srvData.redundant && activeOption){
                                let redundant = srvData.redundant
                                let dependField = redundant.dependField
                                if(dependField == 'package_no'){
                                    item.value = activeOption[redundant.refedCol] + ''
                                }
                            }
                            
                        
                            break;
                        case 'website_no':
                            let no = this.info.baseInfo.website_no
                            this.$set(this.fieldModel,'website_no',no)
                            item.value = no
                            break;
                        case 'message_root':
                            let msg = window.navigator.appVersion || '未知'
                            this.$set(this.fieldModel,'message_root',msg)
                            item.value = msg
                            break;
                        default:
                        
                            break;
                    }
                    if(['website_no','package_no','duration','amount','website_no'].indexOf(item.column) == -1){

                    }
                    
                    return    item
                })
            }
            

            return fields
        }
    },
    data() {
      return {
        show:false,
            v2:null,
            fieldModel:{},
            submit:false,
            readonlyInput:true,
            fieldTemps:[
                {
                    "label": "头像",
                    "column": "head_image",
                    "type": "image",
                    "value": null,
                    "placeholder": "请输入头像...",
                    "rules": [
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "名称",
                    "column": "name",
                    "type": "text",
                    "value": null,
                    "placeholder": "请输入名称...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "名称不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "身份证",
                    "column": "card_no",
                    "type": "image",
                    "value": '900',
                    "placeholder": "请输入省份证...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "省份证不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                
                {
                    "label": "学历",
                    "column": "edu",
                    "type": "radio",
                    "value": null,
                    "placeholder": "请输入学历...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "学历不能为空"
                        }
                    ],
                    "options":[{"text":"小学","value":"小学"},{"text":"初中","value":"初中"},{"text":"高中","value":"高中"},{"text":"专科","value":"专科"},{"text":"本科","value":"本科"},{"text":"研究生以上","value":"研究生以上"}],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "工作单位",
                    "column": "work_unit",
                    "type": "text",
                    "value": null,
                    "placeholder": "请输入工作单位...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "工作单位不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "邮箱",
                    "column": "email",
                    "type": "text",
                    "value": null,
                    "placeholder": "请输入邮箱...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "邮箱不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "证书",
                    "column": "certificate",
                    "type": "image",
                    "value": null,
                    "placeholder": "请输入证书...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "证书不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "简历",
                    "column": "resume",
                    "type": "textarea",
                    "value": null,
                    "placeholder": "请输入简历...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "简历不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "会员类型",
                    "column": "member_type",
                    "type": "radio",
                    "value": this.memberType,
                    "placeholder": "请输入会员类型...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "会员类型不能为空"
                        }
                    ],
                    
                    "state": null,
                    "msg": "",
                    "show": false
                },
                {
                    "label": "性别",
                    "column": "sex",
                    "type": "radio",
                    "value": null,
                    "placeholder": "请输入性别...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "性别不能为空"
                        }
                    ],
                    "options":[{"text":"男","value":"男"},{"text":"女","value":"女"}],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "网站编码",
                    "column": "website_no",
                    "type": "text",
                    "value": store.getters.getWebConfig.website_no,
                    "placeholder": "请输入网站编码...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "网站编码不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": false
                },
                {
                    "label": "联系电话",
                    "column": "phone_no",
                    "type": "text",
                    "value": null,
                    "placeholder": "请输入联系电话...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "联系电话不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                }
            ],
            codeState:false,
      }
    },
      methods: {
        
        validation(field) {
            if(field && this.fieldModel[field.columns]){
                return true
            }else{
                return false
            }
        },
        getV2(){
            let req = {
                    "serviceName":'srvsys_service_columnex_v2_select',
                    "colNames":["*"],
                    "condition":[
                            {"colName":"service_name",
                            "value":this.serviceName,
                            "ruleType":"eq"
                        },{
                            "colName":"use_type",
                            "value":this.useType,
                            "ruleType":"eq"
                        }
                    ],
                    "order":[{"colName":"seq","orderType":"asc"}]
            }
            getV2(req,this.app).then((res) =>{
                // console.log('----',res)
                this.v2 = this.bxDeepClone(res.data) 
                this.buildField()
                this.show = true
            })
        },
        onReset(){
            console.log('form on reset')
        // Reset our form values
        
        
            for(let field of this.fields){
                if(field.column !== 'message_root'){
                     field.value = null
                     this.$set(this.fieldModel,field.column,'')
                     this.$set(field,'value','')
                }
            }
            this.show = false
            this.$nextTick(() => {
            this.show = true
            })
            
        },
       
        onSubmit(evt){
            let self = this
            console.log(evt.preventDefault())
            
             let values = this.fieldModel
             let models = this.formFields
             let model = {}
             if(this.initServiceName == 'srvws_member_pay_record_user_update'){
                let keys = Object.keys(values)
                for(let col of keys){
                    if(this.defaultValue && this.defaultValue.hasOwnProperty(col) &&  values[col] !== this.defaultValue[col]){
                        model[col] = values[col]
                    }
                }
             }else{
                model = values
             }
             for(let field of models){
                if((model.hasOwnProperty(field.column) && !model[field.column] && field.value) || (!model.hasOwnProperty(field.column) &&  field.value)){
                    model[field.column] = field.value
                }
             }
             let valids = this.validFeedback()
             let validsValues = Object.values(valids)
             validsValues = validsValues.filter(item => item !== true)
             if(this.initServiceName == 'srvws_member_pay_record_user_add' && 
             model.amount &&
             model.duration &&
             model.package_no &&
             model.phone_no &&
             model.proof &&
             model.source &&
             model.state &&
             model.user_no &&
             model.website_no &&
             model.code && validsValues.length == 0){
                let req = [{
                    "serviceName":this.serviceName,
                    data:[model]

                }]
                   setRequest(req,'operate',this.serviceName,this.app).then(res=>{
                        console.log(res.data)
                        if(res.state == 'SUCCESS'){
                            this.submit = true
                            self.$emit('submit',true)
                        }
                    })  
             }else if(this.initServiceName == 'srvws_member_pay_record_user_update' && validsValues.length == 0){
                let updateReq = [{
                    "serviceName":this.serviceName,
                    data:[model],
                    condition:[{
                        colName:'id',
                        ruleType:'eq',
                        value:this.defaultValue.id
                    }]
                }]
                   setRequest(updateReq,'operate',this.serviceName,this.app).then(res=>{
                        console.log(res.data)
                        if(res.state == 'SUCCESS'){
                            this.submit = true
                            self.$emit('submit',true)
                        }
                    }) 
             }else  if(this.initServiceName == 'srvgive_leave_word_add' && validsValues.length == 0){
                let req = [{
                    "serviceName":this.serviceName,
                    data:[model]

                }]
                   setRequest(req,'operate',this.serviceName,this.app).then(res=>{
                        console.log(res.data)
                        if(res.state == 'SUCCESS'){
                            this.submit = true
                            self.$emit('submit',true)

                            this.$bvModal.msgBoxOk('请勿重复提交,稍后我们会尽快联系你', {
                                title: '提交成功',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'primary',
                                okTitle: '确认',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true
                            })
                            .then(value => {
                            // this.boxOne = value
                                this.onReset()
                            })
                            .catch(err => {
                            // An error occurred
                            })
                        }
                    })  
             }else {
                console.log('信息不完整')
             }
            
             console.log('on submit',model)
        },
       
    },
    watch:{
        "contentText":{
            deep:true,
            handler:function(nval,oval){
                // console.log(nval,oval)
                if(this.type == `mediaDetails` && this.contentText){
                    this.getDetail(this.contentText)
                } 
            }
        },
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  </style>
  