<template>
    <b-breadcrumb class="bg-white m-0">
        <b-breadcrumb-item href="#" v-for="(item,index) in items" active disabled @click="onPathItem">
                <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true" v-if="item.router == 'index'"></b-icon>
            {{item.text}}
        </b-breadcrumb-item>
        
    </b-breadcrumb>
        
    
  </template>
  
  <script>
  
import store from '@/store'

import banner from '@/assets/banner1.png'

  import { getXianZhuang,getJianBao } from '@/api/api.js'
  export default {
    name: 'nvapath',
    props: {
        paths:{
            type:Array,
            default(){
                return [
                    {
                        text: '首页',
                        href: '#',
                        router:'index'
                    },
                    {
                        text: '栏目',
                        href: '#',
                        router:'homepage'
                    },
                    {
                        text: '子栏目1',
                        active: true
                    }
                ] // url
            }
        }
    },
    computed:{
        items(){
            let items = []
            let paths = this.paths
            if(Array.isArray(paths)){
                items = paths.map(item => item)
            }
            return items
        }
    },
    mounted(){
    },
    data() {
      return {
        
      }
    },
      methods: {
        onPathItem(e){
            console.log(e)
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.card-img{
  min-height: 8rem;
}
  </style>
  