<template>
    <div class="d-flex w-100">
        <div v-if="Array.isArray(files) && files.length > 0"  class=" d-flex">
            <div v-for="(file,index) in files"  class="border mr-1" :style="`background:#f2f2f2;width:${mainProps.width}px;height:${mainProps.height}px;position: relative;`">
                <b-img class="p-1" v-bind="mainProps" :src="file['_url']" alt="加载失败" >
                    
            
                </b-img>
                <div style="height:28px;width:28px;background-color:#0000006b;position: absolute;right: 0;top:0;" class="float-right d-flex justify-content-center align-items-center text-white" @click="del(file)">
                    <b-icon-x></b-icon-x>
                </div>
            </div>
        </div>
        <div v-if="!isMax" class="block d-flex justify-content-center align-items-center h2 m-0" :style="`background:#f2f2f2;width:${mainProps.width}px;height:${mainProps.height}px;`" @click="onCheck">
            <input
			:name="initColumn"
			type="file"
            multiple="multiple"
            style="display: none;" 
            accept="image/*"
			:ref="`uploadInput${initColumn}`"
			@change="getImg">
            <b-icon-plus></b-icon-plus>
            <!-- 
			style="display: none;" -->
        </div>
    </div>
    
</template>
  
  <script>
  
import store from '@/store'

  import { deleteFile } from '@/api/api.js'
  export default {
    props: {
        initNo:{
            type:String,
            default(){
                return ''
            }
        },
        initColumn:{
            type:String,
            default(){
                return ''
            }
        },
        initTable:{
            type:String,
            default(){
                return ''
            }
        },
        msg:{
            type:String,
            default(){
                return ''
            }
        },
        initMax:{
            type:[Number,String],
            default(){
                return 1
            }
        },
        mainProps:{
            type:Object,
            default(){
                return {width: 100, height: 75}
            }
        },
        appNo:String 
    },
    computed:{
        isMax(){
            let is = false
            if(this.initMax == this.files.length){
                is =  true
            }

            return is
        },
        no(){
            let no = ''
            if(this.initNo){
                no = this.initNo
            }else if(this.loadNo){
                no = this.loadNo
            }

            return no
        },
        formData(){
            let data = {
                serviceName: 'srv_bxfile_service',
                interfaceName: 'add',
                app_no: this.appNo || 'ws',
                table_name: this.initTable || 'bxws_member_info',
                thumbnailType: 'fwsu_100',
                columns: this.initColumn || ''

            }
            return data
        }
    },
    mounted(){
        if(this.no){
            this.getInfoFiles()
        }
    },
    data() {
      return {
        loadNo:'',
        onProgress:'',
        files:[]
      }
    },
    methods: {
        getInfoFiles(){
            let self = this
            if(!this.no){
                return
            }
            this.getFileUrls(this.no).then(res=>{
                // console.log('get logos ',res)
                if(Array.isArray(res) && res.length > 0){
                    this.files = res.map(item => {
                        item['_url'] = `${store.getters.hostPath}/file/download?filePath=${item.fileurl}`
                        if(store.getters.getToken){
                            item['_url']  = `${item['_url'] }&bx_auth_ticket=${store.getters.getToken}`
                        }
                        return item

                    })
                }else{
                    this.files = [].map(item => item)
                    self.$set(self,'loadNo','')
                    self.$emit('change','')
                }
                
            })
        },
        onCheck(){
            let refName = `uploadInput${this.initColumn}`
            let ref = this.$refs[refName]
            if(ref){
                ref.click()
            }
            console.log(ref)

           // this.$ref[`uploadInput${this.initColumn}`].click() 
        },
        getImg(e){
            var file = e.target.files;
            this.submit(file[0])
        },
        del(file){
            let self = this
            let delIndex = null
            for(let index in this.files){
                if(this.files[index].fileurl == file.fileurl){
                    delIndex = index

                    let req = {"fileurl":`${file.fileurl}`}
                    deleteFile(req).then(res => {
                        if(res.resultCode == 'SUCCESS'){
                            self.getInfoFiles()
                            
                        }

                    })
                }
                
            }
            
            // this.img_list.splice(index,1)
        },
        submit (file) {
            let url = ''
            url = `${store.getters.hostPath}/file/upload`
            if(store.getters.getToken){
                url = `${url}?bx_auth_ticket=${store.getters.getToken}`
            }

            const xhr = new XMLHttpRequest();// 1. 创建对象
            const form = new FormData();// 创建一个空表单数据对象
            // form.append( ...this.formData);
            for(let key in this.formData){
                form.append(key,this.formData[key]);
            }
            form.append('file',file);
            if(this.no){
                form.append('file_no',this.no);
            }
            
            xhr.open( 'POST', url, true );// 2. 与服务器建立连接
            xhr.upload.onprogress = ( e ) => { // 监听文件传输进度
                this.onProgress && this.onProgress( e );
            };
            xhr.onload = ( e ) => { // 监听文件传输进度
                console.log( '上传onload');
                if ( e.target.readyState && e.target.status ) {//4 && 200
                    this.onSuccess && this.onSuccess( JSON.parse( e.target.response ));
                }
            };
            xhr.onerror = ( error ) => {
                this.onError && this.onError( error, this.file );
            };
            xhr.send( form );// 3. 发送请求;
        },
        onSuccess(res){
            console.log(res)
            if(res){
                res['_url'] = `${store.getters.hostPath}/file/download?filePath=${res.fileurl}`
                if(store.getters.getToken){
                    res['_url']  = `${res['_url'] }&bx_auth_ticket=${store.getters.getToken}`
                }
                if(!this.no){
                    this.$set(this,'loadNo',res.file_no)
                }
                
                this.files.push(res)
            }
            
        },
        onError(err){
            console.log(err)
        }
    },
    watch:{
        "no":{
            deep:true,
            handler:function(nval,oval){
                this.$emit('change',nval)
            }
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.card-img{
  min-height: 8rem;
}
  </style>
  