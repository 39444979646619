
  
import store from '@/store'
export default {
  // name: 'form-layout',
  props: {
      
  },
  components:{},
  mounted(){
  },
  computed:{ 
    "_logos":()=>{
        let info = store.getters.getWebConfig
        let logos = []
        if(Array.isArray(info['_logos']) &&  info['_logos'].length > 0){
            logos = info['_logos'].map(item => item)
            // console.log('logo',logos)
        }
        return logos
    },
    "_baseinfo":()=>{
        let state = {
            isLogin:store.getters.getLoginState,
            info:store.getters.getLoginInfo,
            memberInfo:store.getters.getMemberInfo,
            baseInfo:store.getters.getWebConfig,
         }

         return state
    },
  },
  data() {
    return {
    }
  },
    methods: {
  },
  watch:{
  }
}
