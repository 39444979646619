
 
import config from '../../web-config'
import request from '@/utils/request'
import qs from 'qs'
 
/**
 * 登录接口封装
 */
const host = config.port ? `${config.protocol}://${config.host}:${config.port}` : `${config.protocol}://${config.host}`
const website = `${config.webNo}`  // 站点编号
// const website = 'PCWS202304230001'  // 瓦特
const no = 'yiqipao'
const app = config.app

export function getWebSiteConfig () {
  // 站点信息查询
  let req = {
		"serviceName": "srvdaq_pc_website_cfg_select",
		"colNames": [
			"*"
		],
		"condition": [
			{
				"colName": "website_no",
				"ruleType": "eq",
				// "ruleType": "like",
				"value": website
				// "value": ''
			}
		],
		"page": {
			"pageNo": 1,
			"rownumber": 999
		},
		"order": []
	}
  // console.log(host)
  return request({
    url: `${host}/${app}/select/srvdaq_pc_website_cfg_select`,
    method: 'POST',
    data: req
  })
}

export function getWebSitePcCarousel () {
  // 查询轮播
  let req = {
		"serviceName": "srvdaq_pc_carousel_select",
		"colNames": [
			"*"
		],
		"condition": [
			{
				"colName": "website_no",
				// "ruleType": "eq",
				"ruleType": "[like]",
				// "value": website
				"value": website
			},{
				"colName": "is_view",
				"ruleType": "eq",
				"value": '是'
			}
		],
		"page": {
			"pageNo": 1,
			"rownumber": 999
		},
    "treeData":true,
		"order": [{"colName":"seq","orderType":"asc"}]
	}
  return request({
    url: `${host}/${app}/select/srvdaq_pc_carousel_select/`,
    method: 'POST',
    data: req
  })
}

export function getWebSiteCategoryConfig () {
  // 查询栏目
  let req = {
		"serviceName": "srvdaq_pc_website_category_select",
		"colNames": [
			"*"
		],
		"condition": [
			// {
			// 	"colName": "website_no",
			// 	// "ruleType": "eq",
			// 	"ruleType": "eq",
			// 	"value": website
			// 	// "value": ''
			// },
      // {
			// 	"colName": "is_view",
			// 	"ruleType": "eq",
			// 	"value": '是'
			// }
		],
		// "page": {
		// 	"pageNo": 1,
		// 	"rownumber": 999
		// },
    // "treeData":true,
		// "order": [{"colName":"seq","orderType":"asc"}]
	}
  return request({
    url: `${host}/${app}/select/srvdaq_pc_website_category_select/`,
    method: 'POST',
    data: req
  })
}

export function getContent (data,a) {
  // 查询内容
  let srvApp = a || app 
  return request({
    url: `${host}/${srvApp}/select/${data.serviceName}`,
    method: 'POST',
    data: data
  })
}
export function getListData (data,appName,serviceName) {
  // 查询
  let srvApp = appName || app
  return request({
    url: `${host}/${srvApp}/select/${serviceName}?${serviceName}`,
    method: 'POST',
    data: data
  })
}
export function login (data) {
  // 账号登录
  return request({
    url: `${host}/sso/operate/srvuser_login`,
    method: 'POST',
    data: data
  })
}
export function friendLinks (data) {
  let srvApp = app
  // 查询友情链接
  return request({
    url: `${host}/${srvApp}/select/srvcms_friend_links_select`,
    method: 'POST',
    data: data
  })
}
export function multi (data,app) {
  // 合并查询
  return request({
    url: `${host}/${app}/multi/select/`,
    method: 'POST',
    data: data
  })
}

export function getV2 (data,app) {
  // 查询v2
  return request({
    url: `${host}/${app}/select/srvsys_service_columnex_v2_select`,
    method: 'POST',
    data: data
  })
}
export function loginOut() {
  // 退出登录
  return request({
    url: `${host}/${'sso'}/operate/srvuser_exit`,
    method: 'POST',
    data: [{ "serviceName": "srvuser_exit" }]
  })
}
export function getMemberInfo (data,app) {
  let srvApp = app || 'ws'
  //  查询会员信息
  return request({
    url: `${host}/${srvApp}/select/srvws_member_info_select`,
    method: 'POST',
    data: data
  })
}
export function deleteFile (data) {
  let srvApp = app || 'ws'
  //  查询会员信息
  return request({
    url: `${host}/file/delete`,
    method: 'POST',
    data: data
  })
}

export function checkMobile (data) {
  //  查验手机号 是否注册  || 修改密码 短信校验
  return request({
    url: `${host}/sso/operate/srvsso_check_mobile`,
    method: 'POST',
    data: data
  })
}

export function getCode (data) {
  //  修改密码短信验证获取验证码
  return request({
    url: `${host}/sso/operate/srvsso_send_node`,
    method: 'POST',
    data: data
  })
}
export function resetPwd (data) {
  //  重置密码http://139.196.209.46:4678/sso/operate/srvsso_user_pwd_reset
  return request({
    url: `${host}/sso/operate/srvsso_reset_pwd`,
    method: 'POST',
    data: data
  })
}
export function userResetPwd (data) {
  //  用户修改密码 http://139.196.209.46:4678/sso/operate/srvsso_user_pwd_reset
  return request({
    url: `${host}/sso/operate/srvsso_user_pwd_reset`,
    method: 'POST',
    data: data
  })
}


export function sendCode (data,app) {
  //  获取普通验证码
  let srvApp = app || 'ws'
  return request({
    url: `${host}/${srvApp}/operate/srvmobile_note_send`,
    method: 'POST',
    data: data
  })
}
export function sendMsg (data) {
  //  修改密码短信验证获取验证码
  return request({
    url: `${host}/${app}/operate/srvdaq_pc_ws_content_cmt_add`,
    method: 'POST',
    data: data
  })
}








export function getCaseInfo (app,type,srv) {
  // 返回一个promise对象
  // let app = app
  let req = {
		"serviceName": srv,
		"colNames": [
			"*"
		],
		"condition": [
			{
				"colName": "type",
				"ruleType": "eq",
				"value": type
			}
		],
		"page": {
			"pageNo": 1,
			"rownumber": 40
		},
		"order": [{"colName":"modify_time","orderType":"asc"}]
	}
  return request({
    url: `${host}/${app}/select/${srv}/`,
    method: 'POST',
    data: req
  })
}
export function getEventInfo () {
  // 返回一个promise对象
  // let app = app
  let req = {
		"serviceName": 'srvgive_event_mgt_user_select',
		"colNames": [
			"*"
		],
		"condition": [],
		"page": {
			"pageNo": 1,
			"rownumber": 6
		},
		"order": [{"colName":"modify_time","orderType":"asc"}]
	}
  return request({
    url: `${host}/fyzhmd/select/srvgive_event_mgt_user_select/`,
    method: 'POST',
    data: req
  })
}
export function getFileInfo (no) {
  // 返回一个promise对象
  // let app = app
  let req = {
		"serviceName": 'srvfile_attachment_select',
		"colNames": [
			"*"
		],
		"condition": [
      {
        "colName": "file_no",
        "value": no,
        "ruleType": "eq"
      },
      {
        "colName": "is_delete",
        "value": "1",
        "ruleType": "eq"
      }
    ],
		"page": {
			"pageNo": 1,
			"rownumber": 6
		},
		"order": [{"colName":"modify_time","orderType":"asc"}]
	}
  return request({
    url: `${host}/file/select/srvfile_attachment_select?srvfile_attachment_select`,
    method: 'POST',
    data: req
  })
}
export function getPayInfo (req) {
  // 返回一个promise对象
  // let app = app
  
  return request({
    url: `${host}/${app}/select/srvws_member_pay_record_select?srvws_member_pay_record_select`,
    method: 'POST',
    data: req
  })
}
export function setRequest (req,type,srv,app='sso') {
  // 返回一个promise对象
  // let app = app
  
  return request({
    url: `${host}/${app}/${type}/${srv}?${srv}`,
    method: 'POST',
    data: req
  })
}
export function getCmt (contentNo) {
  // 返回一个promise对象
  // let app = app
  let req = {
      "serviceName": "srvdaq_pc_ws_cmt_select",
      "colNames": [
          "*"
      ],
      "condition": [
          {
              "colName": "content_no",
              "ruleType": "eq",
              "value": contentNo
          }
      ]
  }
  return request({
    url: `${host}/${app}/select/srvdaq_pc_ws_cmt_select?srvdaq_pc_ws_cmt_select`,
    method: 'POST',
    data: req
  })
}





