<template>
    <div :style="style" class="p-1 home-page-menu">
        <!-- <div v-if="title" class="align-middle text-primary font-weight-bold text-left m-0" :class="title.length > 7 ? 'h6' : ' h4'" :style="`line-height:${lineHeight}px;`">
           <span class="h6"  :style="`transform: rotate(90deg);display: inline-block;width: 1rem;`"> ∟</span>  {{ title }}
        </div> -->
        <div v-if="title" class="home-page-menu-title" :class="title.length > 7 ? 'h6' : ' h5'" :style="`line-height:${lineHeight}px;`">
             
             <div class="home-page-menu-title-text">{{ title }}</div>
             <div class="home-page-menu-title-arrow-right"></div>
             <div class="home-page-menu-title-bg-style"></div>
        </div>

        
        <b-list-group class="border-0" flush>
            <b-list-group-item  class=" rounded-0 font-weight-bold" :class="item.children && item.children.length > 0 ? 'p-0' : ''"  v-for="(item,index) in items" :key="index" :active="active[0] == `${index}` && item.children.length == 0" @click.stop="item.children && item.children.length > 0 ? null : onItem(index)">
                <div class=""  :class="item.children && item.children.length > 0 ? 'home-page-menu-tree border-bottom bg-gray' : ''">
                    {{ item.text }}  
                    <b-icon-chevron-down style="position:absolute;right:8px;" v-if="item.children && item.children.length > 0"></b-icon-chevron-down>
                </div>
                
                <template v-if="item.children && item.children.length > 0">
                    <b-list-group class="border-0 p-0" flush>
                        <b-list-group-item  class=" rounded-0  font-weight-bold"  v-for="(child,cindex) in item.children" :key="cindex" :active="active[0] == `${index}` &&  active[1] == `${cindex}`" @click.stop="onItem(cindex,index)">
                            {{ child.text }}
                        </b-list-group-item>
                    </b-list-group>
                </template>
            </b-list-group-item>
           
        </b-list-group>
        
    </div>
    
        
    
  </template>
  
  <script>
  
import store from '@/store'

import banner from '@/assets/banner1.png'

  import { getXianZhuang,getJianBao } from '@/api/api.js'
  export default {
    name: 'actionlist',
    props: {
        paths:{
            type:Array,
            default(){
                return [
                    {
                        text: '首页',
                        href: '#',
                        router:'index'
                    },
                    {
                        text: '栏目',
                        href: '#',
                        router:'homepage',
                        children:[
                            {
                                text: '首页1',
                                href: '#',
                                router:'index'
                            },
                            {
                                text: '栏目2',
                                href: '#',
                                router:'homepage',
                            },
                            {
                                text: '子栏目12',
                                active: true
                            }
                        ]
                    },
                    {
                        text: '子栏目1',
                        active: true
                    }
                ] // url
            }
        },
        width:{
            type:[String,Number],
            default(){
                return 200
            }
        },
        lineHeight:{
            type:[String,Number],
            default(){
                return 50
            }
        },
        title:{
            type:String,
            default(){
                return ''
            }
        },
        initActive:{
            type:[String,Array],
            default(){
                return null
            }
        },
    },
    computed:{
        items(){
            let items = []
            let paths = this.paths
            if(Array.isArray(paths)){
                items = paths.map(item => item)
            }
            return items
        },
        style(){
            let style = {'width':'220px'}
            let width = this.width
            if(width){
                style['width'] = `${width}px`
                style['min-width'] = `${width}px`
            }
            return style
        },
        activeItem(){
            let active = this.active
            let item = null
            if(active.indexOf('-') !== -1){
                active = active.split('-')
                item = this.items.filter((item,index) => index == Number(active[0]))
                if(Array.isArray(item[0].children)){
                    item = item[0].children.filter((item,index) => index == Number(active[1]))
                }
            }else{
                item = this.items.filter((item,index) => index == active) 
            }

            return item
        },
        activeIsChildren(){
            let active = this.active
            let is = false
            let item = null
            if(active.indexOf('-') !== -1){
                active = active.split('-')
                item = this.items.filter((item,index) => index == Number(active[0]))
                let children = item[0].children
                if(Array.isArray(item[0].children)){
                    item = item[0].children.filter((item,index) => index == Number(active[1]))
                    if(Array.isArray(item) && Array.isArray(item[0].children) && item[0].children.length > 0 ){
                        is = true
                    }
                }
            }else{
                item = this.items.filter((item,index) => index == active) 
                if(Array.isArray(item) && Array.isArray(item[0].children) && item[0].children.length > 0 ){
                    is = true
                }
            }

            return is
        }
    },
    mounted(){
        if(this.initActive && this.active !== this.initActive){
            this.$set(this,'active',this.initActive)
        }
    },
    data() {
      return {
        active:['0']
      }
    },
    methods: {
        onItem(e,parent){
            let active = `${e}`
            
            if(parent !== null  && parent !== undefined){
                active = `${parent}-${e}`
            }
            this.active = active
            // this.$emit('change',this.activeItem[0])
            let nval = this.activeItem
            if(Array.isArray(nval)){
                    this.$emit('change',nval[0])
                }else{
                    this.$emit('change',nval)
                }
            console.log('---->',e, this.active,this.activeItem )
        }
    },
    watch:{
        'activeItem':{
            deep:true,
            handler:function(nval,oval){
                
                // if(Array.isArray(nval)){
                //     this.$emit('change',nval[0])
                // }else{
                //     this.$emit('change',nval)
                // }
                
            }
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.card-img{
  min-height: 8rem;
}
  </style>
  