<template>
    <div :style="style" class="d-flex scroll-layout">
        <div v-if="title" class="align-middle bg-lightprimary text-white d-flex justify-content-between text-center align-items-center p-1 pr-2 pl-2" :style="`width:8rem;line-height:${height}px`">
            <b-icon-bell-fill></b-icon-bell-fill>
            <div class="font-weight-bold">
                {{ title }}
            </div>
            <b-icon-chevron-double-right></b-icon-chevron-double-right>
        </div>
        <div class="overflow-hidden inline-block bg-lightestprimary text-dark pl-1 pr-1" style="width:calc(100% - 8rem)">
            <vue-seamless-scroll :data="list" class="warp" style="width:100%" :class-option="classOption">
                <ul class="ul-item d-flex flex-row" style="width:auto">
                    <li v-for="(item, index) in list" :key="index" class="li-item text-left no-warp  text-dark font-weight-bold" :style="`line-height:${height}px`" @click.stop="onItem(item)">
                        <span class="title" v-text="item.text"></span>
                        <!-- <span class="date" v-text="item.date"></span> -->
                    </li>
                </ul>
            </vue-seamless-scroll>
           
        </div>
        
    </div>
        
    
  </template>
  
  <script>
  
import store from '@/store'
import vueSeamlessScroll from 'vue-seamless-scroll'
  import { getContent } from '@/api/api.js'
  export default {
    name: 'scroll-layout',
    components:{vueSeamlessScroll},
    props: {
        initList:{
            type:[String,Array],
            default(){
                return []
                
                // [{
                //     text:'1我为群众办实事—同心抗癌·守护健康—第31届“科技之春”暨第29届'
                // },{
                //     text:'2我为群众办实事—同心抗癌·守护健康—第31届“科技之春”暨第29届'
                // },{
                //     text:'3我为群众办实事—同心抗癌·守护健康—第31届“科技之春”暨第29届'
                // },{
                //     text:'4我为群众办实事—同心抗癌·守护健康—第31届“科技之春”暨第29届'
                // },{
                //     text:'5我为群众办实事—同心抗癌·守护健康—第31届“科技之春”暨第29届'
                // }] // url
            }
        },
        height:{
            type:[String,Number],
            default(){
                return 60 // url
            }
        },
        type:{
            type:[String,Number],
            default(){
                return 'text' // img  text
            }
        },
        title:{
            type:[String,Number],
            default(){
                return '滚动列表' // img  text
            }
        },
        icon:{
            type:[String,Number],
            default(){
                return '滚动列表' // img  text
            }
        },
        interval:{
            type:[String,Number],
            default(){
                return 1000 // img  text
            }
        }
    },
    computed:{
        list(){
            let list = []
            if(this.initList && this.initList.length > 0){
                list = this.initList.map(item => item)
            }else if(this.loadDatas && this.loadDatas.length > 0){
                list = this.loadDatas.map(item => item)
            }
            return list
        },
        id(){
            return `${new Date().getTime()}`
        },
        items(){
            let items = []
            let paths = this.paths
            if(Array.isArray(paths)){
                items = paths.map(item => item)
            }
            return items
        },
        style(){
            let style = {}
            let height = this.height
            if(height){
                style['height'] = `${height}px`
                    style['max-height'] = `${height}px`
                    style['mix-height'] = `${height}px`
                    style['width'] = `100%`
                    style['overflow'] = `hidden`
                    style['max-width'] = `100%`
                    style['z-index'] = 19
            }
            return style
        },
    },
    mounted(){
        this.getListData()
    },
    data() {
      return {
        classOption: {
          limitMoveNum: 1,
          direction: 2
        },
          loadDatas:[]
      }
    },
      methods: {
        onItem(e){
            console.log(e)
            let paths = e['_data'].category_no_path.split('/')
            paths = paths.filter( item => item)
            let path = `homepage`
            let root = `${paths[0]}`
            let categoryNo = `${paths[paths.length - 1]}`
            let id = `${e['_data'].id}`
            this.$router.push(`/${path}/${root}/${categoryNo}/detail/${id}`)
        },
        getListData(){
            if(true){
                let req = {
                    "serviceName": "srvdaq_pc_website_content_select",
                    "colNames": ['*'],
                    "condition": [{
                        // colName:'category_no_path',
                        colName:'category_no',
                        ruleType:'like',
                        value:`LM20230713090001`
                    },{
                        // colName:'category_no_path',
                        colName:'attribute',
                        ruleType:'like',
                        value:`特推`
                    }],
                    // "condition": [],
                    "relation_condition": {},
                    "page": {
                        "pageNo": 1,
                        "rownumber": 10
                    },
                    "order": [
                        {
                            "colName": "create_time",
                            "orderType": "desc"
                        },{
                            "colName": "attribute",
                            "orderType": "desc"
                        }
                    ],
                    "draft": false,
                    "query_source": "list_page"
                }
               
                getContent(req).then((res) =>{
                     if(Array.isArray(res.data)){
                        // console.log('get data====',res)
                        // this.$set(this,'total',res.page.total)
                        this.loadDatas = res.data.map(item => {
                            let obj = {
                                text:`【${new Date(item.create_time).toLocaleDateString() }】${item.title}`,
                                '_data':item
                            }
                            return obj
                        })
                        
                     }
                })
                
            }else if(this.srvApp && this.serviceName){
                console.log('非栏目内容，使用定制service查询')
            }
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss">
.scroll-layout{
    .carousel-item{
        .carousel-caption{
            position: absolute;
            top:0!important;
            bottom:auto;
            width: 100%;
            left:0;
            text-align: left;
            padding:0;
            text-shadow:none;
        }
        img{
            max-height:60px;
            width: auto!important;
        
        }
    } 
    .warp {
        width: max-content;
        margin: 0 auto;
        overflow: hidden;
        ul {
            padding:0;
            list-style: none;
            overflow: hidden;

        &.ul-item {
            display: flex;
            width: max-content;
            .li-item {
                width: max-content;
                margin-right: 10px;
                white-space: nowrap;
                :hover {
                    cursor: pointer;
                    text-decoration:underline;
                }
            }
        }
        }
  }
}

  </style>
  