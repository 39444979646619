<template>
    <b-row class="m-0 h-100">
        <b-col cols="12">
            <div class=" text-left w-auto ">
                <span class="h5 border-bottom border-web-primary p-1 font-weight-bold" style="border-bottom-width:2px !important;">文章评价

                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right h6 m-0" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </span>

                
                
            </div>
        </b-col>
        <b-col cols="12">
            <div class=" text-left w-auto pt-2 ">
                <b-img v-bind=" {  blankColor: '#777', width: 36, height: 36, class: 'm1',src:userImgNone }" rounded="circle" alt="Circle image" :src="userImgNone"></b-img>
                <span class="h6 p-1 m-0" style="border-bottom-width:2px !important;" v-if="!info.isLogin">
                    游客
                    <b-button variant="outline-web-primary" size="sm"  @click="onLogin()">立即登录</b-button>
                </span>
                <span class="h6 p-1 m-0" style="border-bottom-width:2px !important;" v-if="info.isLogin">
                    {{info.userInfo.user_disp ? info.userInfo.user_disp : '-'}}
                    <!-- {{info.userInfo.user_disp ? toStrSecrets(info.userInfo.user_disp) : '-'}} -->
                </span>
                <span class="h6 p-1 m-0" style="border-bottom-width:2px !important;" v-if="!info.isLogin">
                    登录后才可发表评论...
                </span>
            </div>
        </b-col>
        <b-col cols="12">
            <div class="h5 text-left w-auto pt-2 pb-2">
                <b-form-textarea
                id="textarea"
                v-model="msgFormFields.value"
                placeholder="请输入评论内容..."
                :state="msgFormFields.state"
                rows="4"
                no-resize
                ></b-form-textarea>
                
                <b-button variant="web-primary" size="sm" style="position:absolute;right:1.2rem;bottom:1.2rem;" :disabled="!info.isLogin" @click="onSendMsg(msgText)">发布</b-button>
                
            </div>
        </b-col>
        <b-col cols="12" class="pt-2 bg-lightgraymax">
            <div class="text-left w-auto ">
                <span class="h5  p-1 font-weight-bold" >最近评论
                </span>
            </div>
        </b-col>
        <b-col cols="12" class="pb-3 bg-lightgraymax">
            <template v-if="loadContentCmts && loadContentCmts.length > 0" v-for="(item,index) in loadContentCmts">
                <div class=" text-left w-auto pt-2  d-flex justify-content-between ">
                    <div>
                        <b-img v-bind=" {  blankColor: '#777', width: 36, height: 36, class: 'm1',src:userImgNone }" rounded="circle" alt="Circle image"></b-img>
                    
                        <span class="h6 p-1 m-0" style="border-bottom-width:2px !important;">
                            {{toStrSecrets(item.create_user_disp)}}
                        </span>
                    </div>
                    
                    <div>
                        {{item.create_time}}
                    </div>
                </div>
                <div class=" text-left w-auto pt-2 pb-3  border-bottom ">
                    {{item.cmt_content}}
                </div>
            </template>
            <template v-if="loadContentCmts && loadContentCmts.length == 0" >
                <div class=" text-center w-auto pt-2 pb-3 border-bottom">
                    暂无评论
                
                </div>
            </template>
            
        </b-col>
        
        <b-col cols="12" class="pb-3 bg-lightgraymax">
            
            
        </b-col>
        
        <b-modal id="member-login" hide-footer centered>
            <template v-slot:modal-title>
             手机验证码登录
            </template>
            <div class="d-block text-center">
                <!-- ${webCfg.protocol}://${webCfg.host}:${webCfg.port}/file/download?fileNo=${mediaDetails['thn_img']} -->
                <!-- <b-card-img :src="``" class="rounded bg-clight" alt="暂无图片" ></b-card-img> -->
                <b-form  @reset="onReset" novalidate>
                                <template v-for="(field,index) in formFields" >
                                    <b-form-group :id="`${field.column}-${field.type}`" 
                                    :label="field.label" 
                                    :label-for="`${field.column}`" 
                                    v-if="field.show && field.type !== 'radio'" 
                                    label-size="md"  
                                    label-align="right" 
                                    label-cols-lg="2"
                                    label-cols="3">
                                            <b-form-input
                                            :id="`${field.column}`"
                                            v-model="field.value"
                                            v-if="field.type== 'password'"
                                            :type="field.type"
                                            required
                                            autocomplete="off"
                                            :placeholder="field.placeholder"
                                            :readonly="readonlyInput || field.updateed == 0"
                                            @change="change(field)"
                                            :state="field.state"
                                            ></b-form-input>
                                        <b-input-group v-if="field.type== 'code'">
                                            <b-form-input
                                            :id="`${field.column}`"
                                            v-model="field.value"
                                            required
                                            autocomplete="off"
                                            :placeholder="field.placeholder"
                                            :readonly="readonlyInput"
                                            @change="change(field)"
                                            :state="field.state"
                                            placeholder="验证码"
                                            description="验证码"
                                            ></b-form-input>
                                            <b-button  variant="outline-primary" :disabled="codeState" @click="getCode">{{codeState ? `剩余${codeTime}s` : `获取验证码`}}</b-button>
                                        </b-input-group>
                                        <b-form-input
                                            :id="`${field.column}`"
                                            v-model="field.value"
                                            v-if="field.type== 'text'"
                                            :type="field.type"
                                            required
                                            autocomplete="off"
                                            :placeholder="field.placeholder"
                                            :readonly="readonlyInput || field.updateed == 0"
                                            @change="change(field)"
                                            :state="field.state"
                                            ></b-form-input>
                                        <b-form-invalid-feedback :state="field.state">
                                            {{field.msg}}
                                        </b-form-invalid-feedback>
                                        
                                        
                                    </b-form-group>
                                   
                                </template>
                               
                                <b-form-group>
                                    <b-button block variant="web-primary"  class="mt-3" size="lg" @click="onCodeLogin">登录</b-button>
                                    
                                </b-form-group>
                            </b-form>
            </div>
            <!-- <b-button class="mt-3" block @click="submitPayForm">确认提交</b-button> -->
        </b-modal>
    </b-row>
  </template>
  
  <script>
import store from '@/store'
import upload from '@/components/upload.vue'
  import { getV2,setRequest,getCmt,getCode,sendMsg } from '@/api/api.js'
//   import formMixin from '@/utils/form.js'
import userNone from '@/assets/index/user.png'
var codeTime = null
  export default {
    name: 'user-inter-action',
    // mixins:[formMixin],
    props: {
        msg: String,
        formType:{
            type:String,
            default:'add'   // add  update detail
        },
        initType:{
            type:String,
            default:''   // person  group
        },
        initTitle:{
            type:String,
            default:''
        },
        initServiceName:{
            type:String,
            default:'srvgive_leave_word_add'
        },
        initApp:{
            type:String,
            default:'fyzhmd'
        },
        defaultValue:{
            type:[Object,Array],
            default(){
                return null
             
            }
        },
        initContentNo:{
            type:String,
            default:''
        }

    },
    components:{upload},
    mounted(){
        // this.getFriendLinks() 
        if(this.useType && this.app && this.serviceName){
            // this.getV2()
        }
        // setTimeout(() => {
        //             this.$set(this,'readonlyInput',false)

        //         }, 300);
        if(this.info && !this.info.isLogin){
            store.dispatch('closelogin')
        }
        this.getContentCmts()
        setTimeout(() => {
                    this.$set(this,'readonlyInput',false)

                }, 300);

        
    },
    computed:{
        info(){
            let state = {
                isLogin:store.getters.getLoginState,
                info:store.getters.getLoginInfo,
                memberInfo:store.getters.getMemberInfo,
                baseInfo:store.getters.getWebConfig,
             }

             return state
        },
        info(){
            let state = {
                isLogin:store.getters.getLoginState,
                info:store.getters.getLoginInfo,
                memberInfo:store.getters.getMemberInfo,
                userInfo:store.getters.getLoginInfo,
                baseInfo:store.getters.getWebConfig,
             }

             return state
        },
        useType(){
            let type = ''
             if(this.formType){
                type = this.formType
             }

            return type
        },
        serviceName(){
            let srv = ''
            if(this.initServiceName){
                srv = this.initServiceName
            }
            return srv
        },
        app(){
            let app = ''
            
            if(this.initApp){
                app = this.initApp
            }
            return app
        }
    },
    data() {
      return {
        userImgNone:userNone,
            codeTime:0,
            codeState:false,
            regPhone: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            regPwd:/^(?=.*\d)(?=.*[a-z])(?!.*\s).{8,}$/,
            
            fields:[],
            msgFormFields:{
                    "label": "评论",
                    "column": "resume",
                    "type": "textarea",
                    "value": null,
                    "placeholder": "请输入评论内容...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "评论内容不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                },
            formFields:[{
                    "label": "手机号",
                    "column": "phone_no",
                    "type": "text",
                    "value": null,
                    "placeholder": "请输入联系电话...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "请填写有效的手机号"
                        }
                    ],
                    "state": null,
                    "update":0,
                    "msg": "",
                    "show": true
                },{
                    "label": "验证码",
                    "column": "code",
                    "type": "code",
                    "value": null,
                    "placeholder": "验证码...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "请填写有效的验证码"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                }],
            msgText:'',
            loadContentCmts:[],
            show:false,
            v2:null,
            fieldModel:{},
            submit:false,
            readonlyInput:true,
            fieldTemps:[
                {
                    "label": "头像",
                    "column": "head_image",
                    "type": "image",
                    "value": null,
                    "placeholder": "请输入头像...",
                    "rules": [
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "名称",
                    "column": "name",
                    "type": "text",
                    "value": null,
                    "placeholder": "请输入名称...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "名称不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "身份证",
                    "column": "card_no",
                    "type": "image",
                    "value": '900',
                    "placeholder": "请输入省份证...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "省份证不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                
                {
                    "label": "学历",
                    "column": "edu",
                    "type": "radio",
                    "value": null,
                    "placeholder": "请输入学历...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "学历不能为空"
                        }
                    ],
                    "options":[{"text":"小学","value":"小学"},{"text":"初中","value":"初中"},{"text":"高中","value":"高中"},{"text":"专科","value":"专科"},{"text":"本科","value":"本科"},{"text":"研究生以上","value":"研究生以上"}],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "工作单位",
                    "column": "work_unit",
                    "type": "text",
                    "value": null,
                    "placeholder": "请输入工作单位...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "工作单位不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "邮箱",
                    "column": "email",
                    "type": "text",
                    "value": null,
                    "placeholder": "请输入邮箱...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "邮箱不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "证书",
                    "column": "certificate",
                    "type": "image",
                    "value": null,
                    "placeholder": "请输入证书...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "证书不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "简历",
                    "column": "resume",
                    "type": "textarea",
                    "value": null,
                    "placeholder": "请输入简历...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "简历不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "会员类型",
                    "column": "member_type",
                    "type": "radio",
                    "value": this.memberType,
                    "placeholder": "请输入会员类型...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "会员类型不能为空"
                        }
                    ],
                    
                    "state": null,
                    "msg": "",
                    "show": false
                },
                {
                    "label": "性别",
                    "column": "sex",
                    "type": "radio",
                    "value": null,
                    "placeholder": "请输入性别...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "性别不能为空"
                        }
                    ],
                    "options":[{"text":"男","value":"男"},{"text":"女","value":"女"}],
                    "state": null,
                    "msg": "",
                    "show": true
                },
                {
                    "label": "网站编码",
                    "column": "website_no",
                    "type": "text",
                    "value": store.getters.getWebConfig.website_no,
                    "placeholder": "请输入网站编码...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "网站编码不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": false
                },
                {
                    "label": "联系电话",
                    "column": "phone_no",
                    "type": "text",
                    "value": null,
                    "placeholder": "请输入联系电话...",
                    "rules": [
                        {
                            "type": "required",
                            "trigger": "change",
                            "msg": "联系电话不能为空"
                        }
                    ],
                    "state": null,
                    "msg": "",
                    "show": true
                }
            ],
            codeState:false,
      }
    },
      methods: {
        
        validation(field) {
            if(field && this.fieldModel[field.columns]){
                return true
            }else{
                return false
            }
        },
        getV2(){
            let req = {
                    "serviceName":'srvsys_service_columnex_v2_select',
                    "colNames":["*"],
                    "condition":[
                            {"colName":"service_name",
                            "value":this.serviceName,
                            "ruleType":"eq"
                        },{
                            "colName":"use_type",
                            "value":this.useType,
                            "ruleType":"eq"
                        }
                    ],
                    "order":[{"colName":"seq","orderType":"asc"}]
            }
            getV2(req,this.app).then((res) =>{
                // console.log('----',res)
                this.v2 = this.bxDeepClone(res.data) 
                this.buildField()
                this.show = true
            })
        },
        onReset(){
            console.log('form on reset')
        // Reset our form values
        
        
            for(let field of this.fields){
                if(field.column !== 'message_root'){
                     field.value = null
                     this.$set(this.fieldModel,field.column,'')
                     this.$set(field,'value','')
                }
            }
            this.show = false
            this.$nextTick(() => {
            this.show = true
            })
            
        },
        onCodeLogin(evt){
             // 短信验证码登录
             let self = this
            console.log(evt.preventDefault())
            
             let values = this.fieldModel
             let models = this.formFields
             let model = {}
             let valids = []
             
             for(let field of models){
                model[field.column] = field.value
                if(field.state === false){
                    valids.push(field)
                }else if((model.hasOwnProperty(field.column) && !model[field.column] && field.value  && field.state) || (!model.hasOwnProperty(field.column) &&  field.value && field.state)){
                    model[field.column] = field.value
                }
                
             }
             
             if(this.initServiceName == 'srvdaq_pc_ws_content_cmt_select' && 
             valids.length == 0 &&
             model.phone_no &&
             model.code){
                let req = [{
                    "serviceName":'srvsso_mobile_note_login',
                    data:[{
                        mobile:model.phone_no,
                        code:model.code
                    }]

                }]
                   setRequest(req,'operate','srvsso_mobile_note_login').then(res=>{
                        // console.log(res.data)
                        if(res.state == 'SUCCESS'){
                            // this.submit = true
                            // self.$emit('submit',true)
                            let loginInfo = res.response[0].response
                            console.log(loginInfo)
                            this.$store.commit('setLoginInfo',loginInfo)
                            let validTime = store.getters.getLoginValidTo
                            let token = store.getters.getToken
                            window.sessionStorage.setItem('isLogin',true)
                            window.sessionStorage.setItem('loginInfo',JSON.stringify(loginInfo) )

                            window.sessionStorage.setItem('bx_auth_ticket',token)
                            window.sessionStorage.setItem('validTimeTo',validTime)

                            this.$bvModal.hide('member-login')
                        }
                    })  
             }else {
                console.log('信息不完整')
             }
            
             console.log('on submit',model)
        },
        onSendMsg(evt){
            // 
            let self = this
           
            
             
             
             if(this.initServiceName == 'srvdaq_pc_ws_content_cmt_select' && 
             this.msgFormFields.value){
                let req = [{
                    "serviceName":'srvdaq_pc_ws_content_cmt_add',
                    data:[{
                        "content_no": this.initContentNo,
                        "cmt_content": this.msgFormFields.value
                    }]

                }]
                sendMsg(req).then(res=>{
                        console.log(res.data)
                        if(res.state == 'SUCCESS'){
                            this.$bvModal.msgBoxOk('评论已提交,审核通过后将会自动展示...', {
                                title: '提交成功',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'primary',
                                okTitle: '确认',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true
                            })
                            .then(value => {
                                // this.onReset()
                                this.$set(this.msgFormFields,'value','')
                            })
                            .catch(err => {
                            // An error occurred
                            })
                        }else{
                            this.$bvModal.msgBoxOk('请登录后再发表评论...', {
                                title: '提交成功',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'primary',
                                okTitle: '确认',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true
                            })
                            .then(value => {
                                // this.onReset()
                                // this.$set(this.msgFormFields,'value','')
                            })
                            .catch(err => {
                            // An error occurred
                            })
                        }
                        this.getContentCmts()
                    })  
             }else {
                this.$set(this.msgFormFields,'state',false)
                console.log('信息不完整')
                this.$bvModal.msgBoxOk('评论内容不能为空...', {
                    title: '提示',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'primary',
                    okTitle: '确认',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    // this.onReset()
                })
                .catch(err => {
                // An error occurred
                })
             }
            
            //  console.log('on submit',model)
        },
        getContentCmts(){
            
            getCmt(this.initContentNo).then(res=>{
                        console.log(res.data)
                        if(res.state == 'SUCCESS'){
                            // this.submit = true
                            // self.$emit('submit',true)
                            this.loadContentCmts = res.data.map(item => item)
                            
                        }
                    }) 
        },
        onLogin(){
            this.$bvModal.show('member-login')
        },
        getCode(){
            console.log('获取验证码')
            let phone = ''

            let self = this
            let values = {}
            let valids = []
            let isValid = true
            for(let field of this.formFields){
                if(field.column == 'phone_no'){
                    phone = field.value
                    this.change(field)
                }else if(field.column !== 'code'){
                    if(field.value && field.value.trim()){
                        console.log('--:',field.value,field.value.trim())
                        
                        self.$set(field,'state',null)
                        self.$set(field,'msg','')
                    }else{
                        self.$set(field,'state',false)
                        self.$set(field,'msg',`请填写有效的${field.label}`)
                        valids.push(false)
                    }
                }
            }
            if(!phone || !this.regPhone.test(phone) || valids.length > 0){
                return
            }

            let req = [{
                "serviceName": "srvsso_send_node",
                "data": [{
                    "mobile": phone,
                    "type":'login'
                }]
            }]
            
            getCode(req).then(res => {
                if(res.state=="SUCCESS"){
                    console.log('获取成功')
                    let countdown = res.response[0].response.validity_time
                    if(countdown){
                        this.codeState = true
                        this.countdown(countdown)
                    }
                    
                }else{
                    this.$bvModal.msgBoxOk(res.resultMessage, {
                        title: '请确认',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'primary',
                        okTitle: '确认',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                    // this.boxOne = value
                    })
                    .catch(err => {
                    // An error occurred
                    })
                }
            })
        },
        countdown(countdown){
            localStorage.setItem('codeTime',countdown)
            this.codeTime = countdown
            codeTime = setInterval(()=>{
                let time = localStorage.getItem('codeTime')
                this.codeTime = time
                if(time && time >= 1){
                    time = (time - 1)
                    localStorage.setItem('codeTime',time)
                }else{
                    time = 0
                    localStorage.setItem('codeTime',0)
                    clearInterval(codeTime);
                    this.codeState = false
                }
            }, 1000);
            console.log('倒计时',countdown)
        },
        change(e,msg,valid = true){
            let self = this
            console.log(e.value)
            let valids = {}
            for(let field of this.formFields){
                if(e.column == field.column){
                    let rules = field.rules
                    if(valid && !msg && Array.isArray(rules)){
                        for(let rule of rules){
                            let type = rule.type
                            switch (type) {
                                case 'required':
                                    if(e.value == '' || e.value == null || e.value == undefined){
                                        self.$set(e,'state',false)
                                        self.$set(e,'msg',rule.msg)
                                        valids[e.column] = false
                                    }else if(e.value.trim()){

                                        if(field.column == 'phone_no' ){
                                            
                                            if(this.regPhone.test(e.value)){
                                                self.$set(e,'state',null)
                                                self.$set(e,'msg','')
                                            }else{
                                                self.$set(e,'state',false)
                                                self.$set(e,'msg',rule.msg)
                                                valids[e.column] = false
                                            }
                                        }else if(field.type == 'password' && field.column !== 'pwd' && this.regPwd.test(e.value)){
                                            self.$set(e,'state',null)
                                            self.$set(e,'msg','')
                                        }else if(field.column == 'pwd' && e.value.trim()){
                                            self.$set(e,'state',null)
                                            self.$set(e,'msg','')
                                        }else{
                                            let msg = rule.msg
                                            if(field.type == 'password' && field.column !== 'pwd' && !this.regPwd.test(e.value)){
                                                msg = '请输入8位以上,数字+字母组合密码'
                                                
                                                self.$set(e,'state',false)
                                                self.$set(e,'msg',msg)
                                                valids[e.column] = false
                                            }else{
                                                self.$set(e,'state',null)
                                                self.$set(e,'msg','')
                                            }
                                        }
                                        
                                    }else{
                                        self.$set(e,'state',false)
                                            self.$set(e,'msg',msg)
                                            valids[e.column] = false
                                        
                                    }
                                    
                                    break;
                            
                                default:
                                    break;
                            }
                        }
                    }else if(!valid && msg){
                        self.$set(field,'state',valid)
                        self.$set(field,'msg',msg)
                        valids[field.column] = false
                    }
                }
            }
            console.log('change field',valids)
        },
       
    },
    watch:{
        "contentText":{
            deep:true,
            handler:function(nval,oval){
                // console.log(nval,oval)
                if(this.type == `mediaDetails` && this.contentText){
                    this.getDetail(this.contentText)
                } 
            }
        },
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  </style>
  