<template>
    <div class="list-layout w-100" :style="style">
        <div v-if="showTitle" class="list-layout-title p-2 text-left  d-flex justify-content-between">
            <div class="h5 font-weight-bold m-0">
                <b-img v-if="title.icon" v-bind="{ width: 25, height: 25 }" :src="title.icon" rounded alt="Rounded image"></b-img>
                {{title.cn}}
                <div style="position:absolute; top:1rem; left:2rem;opacity: 0.2;" class="text-gray">{{title.en}}</div>
            </div>
           <div class="text-secondary" @click="toPage">
                更多<b-icon-chevron-right></b-icon-chevron-right>
           </div>
        </div>
        <div class="list-layout-content pt-2">
            <div class="list-layout-content-banner" v-if="Array.isArray(bannerList) && bannerList.length > 0 && showBanner">
                <banner v-if="bannerList.length == 1" :layout="'img'" :url="bannerList[0].url"  :height="'auto'"></banner>
                <!-- <banner v-if="bannerList.length > 1" :layout="'img'" :url="bannerList[0].url"></banner> -->
                <carousel v-if="bannerList.length > 1" :initUrls="bannerList" :height="160"></carousel>
            </div>
            <div class="list-layout-content-list">
                <list :initLayout="listLayout" :ruleType="ruleType" :layoutColumn="layoutColumn" :layoutNo="layoutNo" :showDetail="showDetail" :cols="cols" :rowspan="rowspan" :colsMaps="colsMaps" :pagination="false"></list>
            </div>
        </div>
        <div class="list-layout-footer" v-if="footer"  >
            <banner v-if="footer && !_baseinfo.isLogin" :layout="'img'" :url="footer.img"  :height="120" @click.prevent="onFooter(footer)"></banner>
            <vipcard v-if="_baseinfo.isLogin" :init-mode="'simple'"></vipcard>
        </div>
    </div>
        
    
  </template>
  
  <script>
  
import store from '@/store'

import list from '@/components/list.vue'
import carousel from '@/components/carousel.vue'
  import banner from '@/components/banner.vue'
  import vipcard from '@/components/vipcard.vue'
import { getContent } from '@/api/api.js'
import _baseInfo from '@/utils/info.js'
  export default {
    name: 'list-layout',
    mixins:[_baseInfo],
    components: {
        carousel,list,banner,vipcard
    },
    props: {
        layoutNo:{
            type:String,
            default: ''
        },
        initList:{
            type:Array,
            default(){
                return []
            }
        },
        srvApp:{
            type:String,
            default: ''
        },
        serviceName:{
            type:String,
            default: ''
        },
        initTitle:{
            type:Boolean,
            default: true
        },
        more:{
            type:Boolean,
            default: true
        },
        banner:{
            type:Object,
            default(){
                return {
                    img:'',  // 图片字段
                    type:'list',   //layout  栏目轮播 list 文章封面图内容第一条封面，如无封面则不显示。
                }
            }
        },
        footer:{
            type:[Object,String],
            default(){
                return ''
            }
        },
        noneLogin:{
            type:Boolean,
            default: true
        },
        cols:{
            type:Array,
            default(){
                return ['*']
            }
        },
        listLayout:{
            type:String,
            default: 'imgMixText'  // imgMixText 左图右文  textMixImg  左文右图  textNoWarp 单行文字 textLayout    纯文字  // imgCard textCard
        },
        rowspan:{
            type:[String,Number],
            default: 4  // 跨行
        },
        layoutColumn:{
            type:[String,Number],
            default: 12  // 栅格
        },
        colsMaps:{
            type:Object,
            default(){
                return {
                title:'title',
                date:'date',
                subTitle:'subTitle',
                hot:true,
                img:''
                }
            }
        },
        height:{
            type:[String,Number],
            default: 'auto'  
        },
        showTitle:{
            type:Boolean,
            default: true 
        },
        showBanner:{
            type:Boolean,
            default: false 
        },
        showDetail:{
            type:Boolean,
            default: false 
        },
        ruleType:{
            type:Object,
            default(){
                return {
                    colName:'category_no',
                    ruleType:'eq'
                }
            }
        }
        
        
    },
    computed:{
        items(){
            let items = []
            let paths = this.paths
            if(Array.isArray(paths)){
                items = paths.map(item => item)
            }
            return items
        },
        title(){
            let text = {
                cn:this.initTitle ||  '',
                en:''
            } 
            let no = this.layoutNo
            let navs = store.getters.getNavs
            let list = []
            if(no){
                navs = navs.filter(item => item.key == no)
                if(Array.isArray(navs) && navs.length == 1){
                    text['cn'] = navs[0].text
                    text['en'] = navs[0].en
                    text['icon'] = navs[0].icon
                }
            }
            // console.log('title',list)
            return  text
        },
        style(){
            let style = {}
            let height = this.height
            if(height){
                style['height'] = `${height}px`
                    style['max-height'] = `${height}px`
                    style['mix-height'] = `${height}px`
                    style['overflow'] = `hidden`
            }
            return style
        },

    },
    mounted(){
        
         if(this.banner.img && this.banner.type == 'list'){
            this.getListData()
         }else if(this.banner.type == 'layout'){
            this.getBanner()
         }
    },
    data() {
      return {
          bannerList:[]
      }
    },
      methods: {
        onPathItem(e){
            console.log(e)
        },
        getBanner(){
            if(this.layoutNo){
                // store.dispatch('getActiveNavAction',this.activeLayout.key)
                let url = store.getters.getCarousel
                url = url.filter(item => item.no == this.layoutNo)
                if(Array.isArray(url) && url.length > 0){
                    let img = url[0]
                    url=`${store.getters.hostPath}/file/download?fileNo=${img.carousel_img}`
                }

                this.bannerList.push({url:url})
            }
        },
        onFooter(e){
            if(e && e.href){
                this.$router.push({path:`/${e.href}`})
            }
        },
        toPage(){
            let navs = store.getters.getNavs
            navs = navs.filter( item => item.key == this.layoutNo)
            // 获取当前栏目数据
            // navs = this.getTree(navs,'key','parent_no',this.layoutNo)
            // if(navs.length == 1){
            //     navs = navs[0]
            // }
            // store.commit('setActiveNav',navs) 
            // let active = navs.children.length > 0 ? navs.children[0] : navs
            
            if(navs.length == 1){
                 navs = navs[0]

            }
            store.commit('setActiveNav',navs)   // 设置当前栏目
            this.toHomePage(navs,navs.key)  // 公共跳转处理栏目 路由 逻辑

            // this.toHomePage(active,active.key)
            // let path = `/homepage/${this.layoutNo}?timer=${new Date().getTime()}`
            // path = `/${navs.href}/${navs.key}/${active.key}/list/${active.key}`
            // this.$router.push(path,(sce => {
            //         console.log('跳转异常：',sce)
            //     }),(err => {
            //         console.log('跳转异常：',err)
            //     }))
        },
        getListData(){
            if(this.layoutNo){
                let cols = this.cols.concat([this.banner.img])
                let req = {
                    "serviceName": "srvdaq_pc_website_content_select",
                    "colNames": cols,
                    "condition": [{
                        colName:'category_no',
                        ruleType:'like]',
                        value:this.layoutNo
                    }],
                    "relation_condition": {},
                    "page": {
                        "pageNo": 1,
                        "rownumber":1
                    },
                    "order": [],
                    "draft": false,
                    "query_source": "list_page"
                }
                getContent(req).then((res) =>{
                     if(Array.isArray(res.data) && res.data.length > 0){
                        // console.log(res.data)
                        // this.bannerList = res.data.map(item => item)
                        this.getFileUrls(res.data[0][this.banner.img]).then((urlres) =>{
                            let config = this.webConfig
                            for(let img of urlres){
                                let obj = {
                                    url:`${config.protocol}://${config.host}:${config.port}/file/download?filePath=${img.fileurl}`,
                                    '_data':res.data[0]
                                }
                                this.bannerList.push(obj)
                            }
                            
                        })
                        // console.log(urls)
                     }
                })
                
            }else if(this.srvApp && this.serviceName){
                console.log('非栏目内容，使用定制service查询')
            }
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.card-img{
  min-height: 8rem;
}
  </style>
  