


const config = {
    protocol:"https",
    // host: "47.104.18.23",
    // port: "8104",
    host: "api.laodongcloud.com",  // 0102 发布版本
    port:"",
    // protocol:"http",
    // host: "192.168.0.157",
    // port:"8104",
    // htttp://47.104.18.23:8104
    domain:"https://xixiang.laodongcloud.com",   // 用户端域名
    title:"西乡劳动教育平台",
    webNo:"0001", 
    isAuth:false,
    devInfo:'技术支持：西安中科朗仟信息技术有限公司',
    app:'ws',
    webLayout:['hander','navs','footer'],  // 布局部件  hander 顶部  ， navs  一级导航   footer 底部栏  // 页面内容区域默认根据路由加载
    navs:{
        navbarShowChildren:false
    },
    hander:{
        height:'160px',
        showBgImg:true,
        login:{
            inputText:'注册登录',
            layoutTitle:"个人会员登录",
            layoutMsg:"还没有账号，点击下方【立即注册】进行在线注册",
            show:true,
            loginPath:"/login",
            toHome:true
        },
        register:'',
        // {
        //     text:'注册',
        //     show:true,
        //     layoutTitle:"个人会员注册",
        //     layoutMsg:"",
        //     loginPath:"/register",
        //     toHome:true,
        //     toLogin:true
        // },
        search:{
            text:'搜索',
            icon:"",
            show:true
        },
        links:[
            {
                text:'劳动教育综合管理平台',
                href:' https://xxm.laodongcloud.com/main/index.html',
                icon:'',
                target:'_blank'
            },
            // {
            //     text:'机构注册',
            //     href:'/register',
            //     icon:'',
            //     target:'_blank'
            // }    
        ]
        // ,
        // {
        //     text:'活动申报入口',
        //     icon:'',
        //     href:'https://xxld.100xsys.cn:1443/main/index.html',
        //     target:'_blank'
        // }
    },
    footer:{
        navs:false,
        copyright:true,
        name:true,
        address:false,
        qrcode:false,
        devInfo:true
    }
}
module.exports = { ...config }
