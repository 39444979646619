<template>
  <div :style="style">
    <b-carousel
    ref="myCarousel"
      v-if="urls && urls.length > 1"
      id="carousel-1"
      v-model="slide"
      
      :controls="!config.fade"
      :img-height="height" 
      :interval="interval" :indicators="config.indicators"
      :background="config.background"
      style="text-shadow: 1px 1px 2px #333;"
      :style="style"
      :fade="config.fade"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      
    <!--:interval="interval" :indicators="config.indicators" :controls="!config.fade" -->
    <!-- 
      :img-width="'200px'" -->
      <!-- <b-carousel-slide v-for="(item,index) in urls">
        <template v-slot:img>
          <img
            class="d-block img-fluid w-auto"
            :src="item.url"
            alt="image slot"
          >
        </template>
      </b-carousel-slide> -->
      <template v-for="(item,index) in urls">
        <b-carousel-slide  class="" >
          <template v-slot:img>
            <b-img
              class="d-block img-fluid "
              @click="onClick(item)"
              :style="`width:${config.width ? config.width : '100%'};height:${height == 'auto' ? height : height + 'px'};margin:0 auto`"
              :src="item.url"
              alt="image slot"
            ></b-img>
            
          </template>
        </b-carousel-slide>
        <div class="carousel-caption" @click="onClick(item)" v-show="index == slide" :style="`min-width:100%;width:${config.width ? config.width : '100%'};left:0;font-size:1rem;;position:absolute;bottom:0;margin:0;padding:0;background-color:rgb(0 0 0 / 38%);`"><h6 class="m-0 p-1 text-left">{{item.text}}</h6><!----><!----></div>
      </template>
     
      <button v-if="config.fade" class="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev"  @click="prev"  style="color:#333;">
        <!-- <span class="carousel-control-prev-icon  text-dark" aria-hidden="true"></span> -->
        <b-icon
        icon="chevron-left"
        scale="2"
        rotate="0"
        shift-h="-8"
        shift-v="8"
        class="text-dark"
      ></b-icon>
        <span class="sr-only"></span>
      </button>
      <button v-if="config.fade" class="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next"  @click="next">
        <!-- <span class="carousel-control-next-icon  text-dark" aria-hidden="true"  style="color:#333;"></span> -->
        <b-icon
        icon="chevron-right"
        scale="2"
        rotate="0"
        shift-h="-8"
        shift-v="8"
        class="text-dark"
        aria-hidden="true"
      ></b-icon>
      </button>
    </b-carousel>
    <banner v-if="urls.length == 1" :layout="'img'" :url="urls[0].url"  :height="'auto'" :action="action" @on-button="$emit('on-button',$enevt)"></banner>
  </div>
    
        
    
  </template>
  
  <script>
  
import store from '@/store'

import banner from '@/components/banner.vue'
// import banner from '@/assets/banner1.png'

  export default {
    name: 'carousel',
    components:{banner},
    props: {
        initUrls:{
            type:[String,Array],
            default(){
                return [] // url
            }
        },
        height:{
            type:[String,Number],
            default(){
                return 300 // url
            }
        },
        interval:{
            type:[String,Number],
            default(){
                return 3000 // url
            }
        },
        
        activeNavbar:{
          type:[String],
            default(){
                return '' // url
            }
        },
        action:{
          type:[Object],
            default(){
                return null
                // {
                //   text:'',
                //   type:'',
                //   params:null,

                // }
            }
        },
        config:{
          type:[Object],
          default(){
            return {
              indicators:true

            }
          }
        },
        showTitle:{
          type:[Boolean],
            default(){
                return false
            }
        },
        imageStyle:{
          type:[String],
            default(){
                return ``
            }
        },
        initColNo:{
          type:[String],
            default(){
                return '' // url
            }
        }
    },
    computed:{
        style(){
            let style = {}
            let height = this.height
            if(height){
                style['height'] = `${height}px`
                    style['max-height'] = `${height}px`
                    style['mix-height'] = `${height}px`
                    style['overflow'] = `hidden`
                    
            }
            if(this.config){
              style['background'] =  this.config.background || 'transparent'
              if(this.config.fade){
                style['color'] =  '#ababab' 
              }
            }
            return style
        },
        urls(){
          let imgs = store.getters.getActiveCarousel || []
          let urls = []
          if(this.activeNavbar){
            for(let img of imgs){
              let url = this.getFileUrls(img.carousel_img)
              let config = this.webConfig
              let obj = {
                url:`${config.protocol}://${config.host}:${config.port}/file/download?fileNo=${img.carousel_img}`,
                href:'',
                '_data':img,
                text:img.link_content_title || ''
              }
              urls.push(obj)
            }
          }else if(this.initUrls.length > 0){
            urls = this.initUrls.map(item => item)
          }
          
          return urls
        }
    },
    mounted(){
        if(this.activeNavbar){
          this.getOnCarousel(this.activeNavbar)  
        }
        
    },
    data() {
      return {
        slide: 0,
        sliding: null
      }
    },
    methods: {
      prev() {
        this.$refs.myCarousel.prev()
      },
      next() {
        this.$refs.myCarousel.next()
      },
        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        },
        getOnCarousel(no){
           store.dispatch('getActiveNavAction',no)
        },
        onClick(e){
          // 点击轮播图 回传数据
          this.$emit('on-button',e)
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
.card-img{
  min-height: 8rem;
}
#carousel-1 .carousel-item .carousel-caption{
  min-width:100%;
  left:0;
  right:0!important;
}
  </style>
  