<template>
    <div class="container-fluid text-white bg-web-primary">
        <div class="container">
            <div class="row d-flex justify-content-center" v-if="showNavs">
                    <a :href="item.temp_file_name" class="p-4 h5 text-lightestprimary" v-for="(item,index) in loadNavs" :key="index">{{ item.name }}</a>
            </div>
            <div class="row" v-if="config.qrcode && config.name && config.qrcode">
                <div class="col-md-6 col-12">
                    <div class="p-3">
                        
                        <p class="text-left h4  p-1 text-blod" >{{info.corporate_name}}</p>
                        <p class="text-left h6  p-1 text-blod" >公司： {{info.corporate_name}}</p>
                        <p class="text-left h6  p-1" > 地址：{{info.address}}</p>
                        <p class="text-left h6 p-1" v-if="info.phone"><span class="pr-2">电话：{{info.phone}}</span></p>
                        <p class="text-left h6 p-1" v-if="info.telephone"><span class="pr-2">座机：{{info.telephone}}</span></p>
                    </div>
                </div>
                <div class="col-md-6 col-12 d-flex justify-content-center justify-content-sm-end align-items-center" v-if="config.qrcode">
                    <b-img :src="code.url" fluid-grow v-for="(code,index) in qrCodes" class="rounded float-right" style="max-width: 120px;margin-right:10px;" alt="..." ></b-img>
                    <!-- <img :src="gongzhonghaoImg" class="rounded float-right ml-4" style="width: 120px;height: 120px;" alt="..."> -->
                </div>
            </div>
            <div class="row p-5" v-if="!config.qrcode && config.name && config.devInfo">
                <div class="col-12">
                    <p class="text-center  p-1 text-blod m-0" style="font-size:14px;"><span>主管单位：{{info.corporate_name}}</span><span class="pl-3 pr-3 text-clight" style="color:rgb(237 241 247 / 38%) !important;"> | </span><span v-if="config.devInfo">{{devInfo}}</span></p>
                </div>
                
                <div class="col-12 p-0" v-if="config.copyright">
                        <copyright :textColor="'text-light'"></copyright>
                    </div>
            </div>
            
        </div>
        <!-- <div class="row" v-if="config.copyright">
            <div class="col p-0">
                <copyright></copyright>
            </div>
        </div> -->
    </div>
  </template>
  
  <script>
  
import store from '@/store'
import copyright from '@/components/copyright.vue'
  import { friendLinks } from '@/api/api.js'
  export default {
    name: 'footerLayout',
    components:{copyright},
    props: {
        address:{
            type:[Object,Array],
            default(){
                return {
                    corporate_name:'陕西营养学会2323432',
                    name:"西安易企跑数字科技有限公司",
                    address:"公司地址：陕西省西安市高新区锦业路旺都国际D座1205室",
                    telePhone:" 座机：029-8116 8482",
                    mobilePhone:" 电话：133-7922-0232",

                }
             
            }
        },
        footerNavs:{
            type:[Object,Array],
            default(){
                return null
             
            }
        }
    },
    mounted(){
        // this.getFriendLinks() 
        this.buildQrcode() 
    },
    computed:{
        devInfo(){
            let info = this.webConfig.devInfo
            return info
        },
        config(){
            let config  = this.webConfig.footer || {}
            return config
        },
        info:function(){
            let info = null
            info = this.$store.getters.getWebConfig
            return info

        },
        showNavs(){
            let show = this.webConfig.footer.navs || false
            return show 
        },
        qrCodes(){
            let qrcodes = []
            qrcodes = this.$store.getters.getQrcodes
            return qrcodes
        },
        loadNavs(){
            let navs = [{
                name:'关于我们',
                temp_file_name:''
            },{
                name:'版权声明',
                temp_file_name:''
            },{
                name:'隐私保护',
                temp_file_name:''
            }]
            return navs
        }
    },
    data() {
      return {
        provinceName:"",
      }
    },
      methods: {
        buildQrcode(){
            let config = store.getters.getWebConfig
            if(config.qr_code){
                    let qrcodes = []
                    this.getFileUrls(config.qr_code).then(res=>{
                        // console.log('get logos ',res)
                        if(Array.isArray(res) && res.length > 0){
                            qrcodes = res.map(item => {
                                let obj ={
                                    url:`${store.getters.hostPath}/file/download?filePath=${item.fileurl}`,
                                    '_data':item
                                }
                                return obj

                            })
                            this.$store.commit('setQrcodes',qrcodes)
                        }
                        
                    })
                    // console.log('logo',logos)
              }
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  </style>
  