
import Vue from "vue";
// var loadingInstance = null

import store from '@/store'

import { getFileInfo } from '@/api/api.js'
function utils() {
    Vue.prototype.rootScrollY = 0
    Vue.prototype.rootScrollToTop = function(){
        window.scrollTo(0,0);
      }
    Vue.prototype.bxDeepClone=function(obj) {
        if (obj == null) return null;
        let newObj = obj instanceof Array ? [] : {};
        for (var i in obj) {
            newObj[i] = typeof obj[i] === "object" ? this.bxDeepClone(obj[i]) : obj[i];
        }
        return newObj;
    }
    Vue.prototype.getFileUrls = async function(fileNo){
        let urls = []
        await getFileInfo(fileNo).then(res =>{ 
            // console.log('getFileInfo',res)
            if(Array.isArray(res.data)){
                urls = res.data.map(item => item)
            }
        })

        return urls
        
    }
    Vue.prototype.getNavBarData = function(no){
        let navs = store.getters.getNavs
        navs = Vue.prototype.getTree(navs,'key','parent_no',no)
        if(navs.length == 1){
            navs = navs[0]
        }
        return navs
    }
    Vue.prototype.getTree = function(arr,key,pkey,keyValue){
        let list = []
        function getChildren(item,arr,ckey,cpkey){
            if(item){
                let children = arr.filter(child => child[cpkey] == item[ckey])
                if(children.length > 0){
                    for(let c of children){
                        c = getChildren(c,arr,ckey,cpkey)
                    }
                    
                }else{

                }
                item['children'] = children.map(item => item)
            }
            return item
        }
        if(Array.isArray(arr) && key && pkey && keyValue){
             list = arr.filter(item => item[key] == keyValue)

             for(let item of list){
                item = getChildren(item,arr,key,pkey)
             }
        }

        return list
        
    }
    Vue.prototype.onSearch = function(e){
        let url = `/policy`
        // ?${e.name}=${encodeURIComponent(e.value)}
        if(e && e.length > 0){
            url = url + '?'
            for(let search of e){
                console.log(url.indexOf('?'),url.length)
                if(url.indexOf('?') == url.length - 1){
                    url = `${url}&${search.name}=${encodeURIComponent(search.value)}`
                }
                
            }
            this.$router.push({
                path:`${url}`
            })
        }else{

        }
        
        console.log(e)
    }
    Vue.prototype.reHtmlImgSrc = function (str){
        var data = [];
            str.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, function (match, capture) {
                  data.push(capture);
            });
            // console.log(data)
        return data
    }
    Vue.prototype.getArrayCol = function (arr,rowAllLen){
        var col = null;
        let len = arr.length
        
        if(Array.isArray(arr)){
            col = rowAllLen % len == 0 ? rowAllLen/len :rowAllLen/ (len - 1)
        }else{
            col = 1
        }
        // console.log('当前data row colspan:',arr,col)
        return col
    }
    Vue.prototype.toHome = function (){
        console.log('to home')
        this.$router.replace({path:'/' })
    }
    // user request
    Vue.prototype.initLoginState = function (req){
        let validTo = window.sessionStorage.getItem('validTimeTo')
        let isLogin = window.sessionStorage.getItem('isLogin')
        let loginInfo = window.sessionStorage.getItem('loginInfo')
        let token = window.sessionStorage.getItem('bx_auth_ticket')
        if(isLogin && loginInfo){
            store.commit('setLoginInfo',JSON.parse(loginInfo))
        }
    }
    Vue.prototype.urlParams = function(url){
        let urlParams = null
        if(url.indexOf('?') !== -1){
            let params = url.split('?')
            urlParams = {}
            params = params.length > 1 ? params[1] : params[0]
            if(params.indexOf('&') !== -1){
                params = params.split('&')
                console.log('params',params)

                for(let item of params){
                    
                    if(item.indexOf('=') !== -1 && item.split('=').length > 1){
                        urlParams[item.split('=')[0]] = item.split('=')[1]
                    }
                }
            }
        }
        return urlParams
            
    }
    Vue.prototype.toHomePage = function(bar,rootId){
        let self = this
        let rootPath = bar.href == '/' ? '/' : 'homePage'
        let rootKey = rootId ? rootId : bar.parent_no ? bar.parent_no : bar.key
        let rootBar = store.getters.getActiveNav
        let rootLayoutType = rootBar.href ? rootBar.href : 'imgMixText'
        if(rootLayoutType.indexOf('?') !== -1){
            rootLayoutType = rootLayoutType.split('?')[1]
            rootLayoutType = rootLayoutType.split('=')[1]

        }
        let activeKey = bar.key
        let activeLayout = 'list'
        if(bar.href && bar.href.indexOf('[') !== -1 && bar.href.indexOf(']') !== -1){
            activeLayout = bar.href.split(']')[0]
            activeLayout = activeLayout.replaceAll('[', '')
        }
        if(bar.href && bar.href.indexOf('?') !== -1){
            let params =  Vue.prototype.urlParams(bar.href)
                console.log('params',params)
        }
        let path = ''
        let activeLayoutType = rootLayoutType
        if(rootPath == '/'){
            self.$router.push({path:'/' })
            return 
        }else if(rootPath == 'homePage'){
            if(activeLayout == 'list'){
                if(bar.href && bar.href.indexOf(':') !== -1){
                    activeLayoutType = bar.href.split(':')[1]
                }
                path =  `/${rootPath}/${rootKey}/${activeKey}/${activeLayout}/${activeKey}/${activeLayoutType}`
                if(bar.params){
                    let paramsStr = `?`
                    let keys = Object.keys(bar.params)
                    console.log(keys)
                    for(let i=0;i<keys.length;i++){
                        if(i < keys.length){
                            paramsStr = `${paramsStr}${keys[i]}=${bar.params[keys[i]]}&`
                        }else{
                            paramsStr = `${paramsStr}${keys[i]}=${bar.params[keys[i]]}`
                        }
                    }
                    path = `${path}?${paramsStr}`  
                }
            }else{
                // 不是 list 默认 子路由时 进行 自定义 适配
                path = `/${rootPath}/${rootKey}/${activeKey}/${activeLayout}`
                activeLayoutType = ''
                if(bar.href && bar.href.indexOf(':') !== -1){
                    activeLayoutType = bar.href.split(':')[1]
                    if(activeLayoutType){
                        path = `/${rootPath}/${rootKey}/${activeKey}/${activeLayout}/${activeLayoutType}`
                    }
                    
                }
            }
        }
        
        // console.log('utils to :',path,'原始路由',bar.href)
        self.$router.push(path,(sce => {
            // self.$router.push({name:path,params:{ "no":nav.key }},(sce => {
            console.log('跳转异常：',sce)
        }),(err => {
            console.log('跳转异常：',err)
        }))
    }
    
    Vue.prototype.toStrSecrets = function(str){
        // str = '解士大sfsdf'
        let textStr = ''
        let strArr = []
        strArr = str.split('')
        let start = 0
        let end = str.length - 1
        if(strArr.length > 4){
            start = 0
            end = str.length - 2
        }else if(strArr.length !== 1 && strArr.length > 3){
            start = 0
            end = str.length - 1
        }else if(strArr.length !== 1 && strArr.length <= 3){
            start = 0
            end = str.length
        }else{
            start = -1
            end = str.length
        }
        for(let textIndex in strArr){

            if(textIndex > start && textIndex < end){
                strArr[textIndex] = "*"
            }
        }
        textStr = strArr.join('')

        return textStr
    }
    Vue.prototype.toMemberCenter = function(){
        // let self = this
        let root = store.getters.getNavs
        root = this.getTree(root,'key','parent_no','LM20230713180007')
            if(root.length == 1){
                root = root[0]
            }
            store.commit('setActiveNav',root) 
        let path = '/homePage/LM20230713180007/LM20230718110002/user/个人'
        this.$router.push(path,(sce => {
            // self.$router.push({name:path,params:{ "no":nav.key }},(sce => {
            console.log('跳转异常：',sce)
        }),(err => {
            console.log('跳转异常：',err)
        }))
    }
    
}

export default utils;
