<template>
    <div class="" :style="style">
        <template v-if="layout == 'img'">
            <div class="bg-white text-dark p-0 m-0 border-0 w-100"  body-class="">
                <b-img fluid-grow  v-if="url" :src="url" class="p-0 m-0" alt="..."></b-img>
                <!-- <div class="card-img-overlay p-4 align-self-center d-flex justify-content-center"> -->
                    <!-- <h2 class="card-title  align-self-center d-flex justify-content-center border border-cdark p-2">{{config.title}}</h2> -->
                <!-- </div> -->
                <!-- <b-container v-if="action" style="position: relative; bottom:3rem;" class="text-left">
                    <b-button-group class="text-white" v-if="action.type == 'login'">
                        <b-button variant="orange" class="">{{ action.text }}</b-button>
                        <b-button>Button 2</b-button>
                    </b-button-group>
                </b-container> -->
            </div>
            
        </template>
        <template v-if="layout == 'text'">
            <div class=" m-0 pt-3 block align-middle p-0">
                
                <div style="display: flex; justify-content: center;">
                    <div  class=" border-orange mr-3" style="width:2rem;height:2rem;border-bottom:0.6rem;border-style: solid;border-width:0 0 0.6rem 0;"></div>
                    <div class="h2 font-weight-bold pl-3 pr-3">{{text.cn}}</div>
                    <div class=" border-orange ml-3" style="width:2rem;height:2rem;border-bottom: 0.6rem;border-style: solid;border-width:0 0 0.6rem 0;"></div>
                </div>
                <div style="position:relative; top:-1.51rem;opacity: 0.12;" class="text-gray h1  font-weight-bold ">{{text.en}}</div>
                <div v-if="text.subTitle" class="h5" style=" top:-1rem;opacity: 1;text-align:left;font-weight:600;position: relative;">{{text.subTitle}}</div>
            </div>
            
        </template>
        
    </div>
        
    
  </template>
  
  <script>
  
import store from '@/store'

import banner from '@/assets/banner1.png'

  import { getXianZhuang,getJianBao } from '@/api/api.js'
  export default {
    name: 'banner',
    props: {
        url:{
            type:String,
            default(){
                return banner // url
            }
        },
        action:{
            type:Object,
            default(){
                return null 
            }
        },
        layout:{
            type:String,
            default(){
                return 'img'   // img   text 
            }
        },
        text:{
            type:Object,
            default(){
                return {
                    cn:'',
                    en:''
                } 
            } 
        },
        height:{
            type:[String,Number],
            default(){
                return 160   // img   text 
            } 
        }
    },
    computed:{
        style(){
            let style = {}
            let height = this.height
            if(height){
                style['height'] = `${height}px`
                style['max-height'] = `${height}px`
                style['max-height'] = `${height}px`
                style['overflow'] = `hidden`
            }
            return style
        }
    },
    mounted(){
        // this.getData()  
    },
    data() {
      return {
        
      }
    },
      methods: {
        
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.card-img{
  min-height: 8rem;
}
  </style>
  